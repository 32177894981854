.header-responsive {
  display: flex;
}

.header-button {
  margin-left: 20px;
}

.header-filters {
  margin-right: 0px;
}

@media (max-width: $md) {
  .header-responsive {
    display: flex;
    flex-direction: column;
    align-items: flex-end !important;
    width: 100%;
  }

  .header-button {
    width: 100%;
    margin-top: 30px;
  }

  .header-filters {
    margin-right: 0px;
  }
}
