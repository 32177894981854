// Default Font Family

@font-face {
  font-family: 'Baloo Bhai 2';
  src: url('app/assets/fonts/static/BalooBhai2-ExtraBold.ttf')
    format('truetype');
  font-weight: 800;
}

@font-face {
  font-family: 'Baloo Bhai 2';
  src: url('app/assets/fonts/static/BalooBhai2-Bold.ttf') format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: 'Baloo Bhai 2';
  src: url('app/assets/fonts/static/BalooBhai2-SemiBold.ttf') format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: 'Baloo Bhai 2';
  src: url('app/assets/fonts/static/BalooBhai2-Medium.ttf') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'Baloo Bhai 2';
  src: url('app/assets/fonts/static/BalooBhai2-Regular.ttf') format('truetype');
  font-weight: 400;
}

:root {
  --project-font-size: 1.05rem;
  --project-font-family: 'Baloo Bhai 2', 'Trebuchet MS';
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold;
  margin: 0;
}

h1 {
  font-size: calc(var(--project-font-size) * 3);
  letter-spacing: calc(var(--project-font-size) * 3 * 0.02);
  line-height: calc(var(--project-font-size) * 3 * 1.35);
}
h2 {
  font-size: calc(var(--project-font-size) * 2.5);
  letter-spacing: calc(var(--project-font-size) * 2.5 * 0.01);
  line-height: calc(var(--project-font-size) * 2.5 * 1.3);
}
h3 {
  font-size: calc(var(--project-font-size) * 2);
  letter-spacing: calc(var(--project-font-size) * 2 * 0.03);
  line-height: calc(var(--project-font-size) * 2 * 1.3);
}
h4 {
  font-size: calc(var(--project-font-size) * 1.8);
  letter-spacing: calc(var(--project-font-size) * 1.8 * 0.02);
  line-height: calc(var(--project-font-size) * 1.8 * 1.35);
}
h5 {
  font-size: calc(var(--project-font-size) * 1.4);
  letter-spacing: calc(var(--project-font-size) * 1.5 * 0.02);
  line-height: calc(var(--project-font-size) * 1.5 * 1.35);
}
h6 {
  font-size: calc(var(--project-font-size) * 1.2);
  letter-spacing: calc(var(--project-font-size) * 1.2 * 0.02);
  line-height: calc(var(--project-font-size) * 1.2 * 1.35);
}

p {
  font-size: calc(var(--project-font-size) * 0.8125);
  letter-spacing: calc(var(--project-font-size) * 0.8125 * 0.07);
  line-height: calc(var(--project-font-size) * 0.8125 * 1.5);
  margin: 0;
}
