.payment-confirmation {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: linear-gradient(
    175deg,
    rgba(255, 94, 99, 1) 37%,
    rgba(255, 153, 102, 1) 100%
  );

  .payment-card {
    width: 600px;
    background: white;
    border-radius: 20px;
    overflow: hidden;
  }

  .payment-header {
    height: 100px;
    display: flex;
    justify-content: space-between;
    background: $nice-blue;
    padding: 20px;
  }
}
