// Common mixins

@mixin box-shadow {
  box-shadow: 3px 3px 10px 3px rgba(34, 34, 34, 0.15);
}

@mixin scrollbar(
  $main-color: rgba(68, 68, 68, 0.336),
  $scrollbar-color: rgba(53, 53, 53, 0.219)
) {
  &::-webkit-scrollbar {
    width: 0.6rem;
    height: 0.4rem;
  }
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: $scrollbar-color;
    cursor: pointer;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $main-color;
    border-radius: 5px;
  }
}

// Firefox scrollbar
@mixin firefox-scrollbar-styles($scrollColor, $backgroundScrollColor) {
  scrollbar-color: $scrollColor $backgroundScrollColor;
  scrollbar-width: thin;
  scrollbar-gutter: revert-layer;
}

// Media Query Mixins
@mixin custom-media-query($min, $max) {
  @media screen and (min-width: $min) and (max-width: $max) {
    @content;
  }
}

@mixin media-query-max($max) {
  @media screen and (max-width: $max) {
    @content;
  }
}

@mixin media-query-min($min) {
  @media screen and (min-width: $min) {
    @content;
  }
}

@mixin mobile {
  @media screen and (max-width: $md) {
    @content;
  }
}
@mixin mid-screens {
  @media screen and (min-width: $md) and (max-width: $xl) {
    @content;
  }
}

@mixin large-screens {
  @media screen and (min-width: $xl) and (max-width: $xxl) {
    @content;
  }
}

@mixin xl-screens {
  @media screen and (min-width: $xxl) {
    @content;
  }
}

@mixin desktop {
  @media screen and (min-width: $lg) {
    @content;
  }
}

@mixin btn-transparent($hoverColor) {
  &:hover,
  &:focus,
  &:active {
    background-color: transparent !important;
    color: $hoverColor !important;
    border: none !important;
    box-shadow: none !important;
  }
}

@mixin scrollbar-styles($background) {
  /* width */
  ::-webkit-scrollbar {
    height: 7px !important;
    width: 7px !important;
    max-width: 200px !important;
    margin: 0 10px !important;
    scroll-margin: 10px !important;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: $background;
    width: 7px !important;
    border-radius: 10px;
    max-width: 200px !important;
  }
  ::-webkit-scrollbar-track:hover {
    background: $hover-one;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: $orange-two;
    width: 7px !important;
    border-radius: 5px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: $orange-one;
  }
}
