.card-profile {
  background: #ffffff;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.08);
  border-radius: 12px;

  img {
    height: 250px;
    object-fit: cover;
    width: 100%;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }

  .info {
    padding: 10px 20px 25px 20px;

    h5 {
      margin-top: 0.7rem;
      font-size: 1.5rem;
    }

    .email {
      margin-top: 0.7rem;
      font-size: 1.1rem;
      font-weight: 600;

      span {
        font-weight: 400;
      }
    }

    .use-time {
      margin-top: 0.3rem;
      font-size: 0.9rem;
    }

    .complete-profile {
      margin-top: 1rem;

      .bg-bar {
        background-color: $secondary-two;
        margin-top: 3px;
        .bar {
          height: 5px;
          border-radius: 13px;
          width: 60%;
          background-color: $secondary;
        }
      }
    }
  }
}

.box-star {
  display: inline-flex;
  align-items: center;
  height: 30px;
  background-color: $state-variation-green;
  border-radius: 10px;
  padding: 0 10px 0 0;

  &.driver {
    background-color: $state-variation-green;
  }
  span {
    margin-top: 4px;
    font-size: 1.2rem;
  }
  .icomoon {
    width: 18px !important;
  }
}

.bg-description {
  margin-left: 20px;
  background-color: $state-variation-yellow;
  padding: 30px;
  border-radius: 12px;

  @include media-query-max(767px) {
    margin-left: 0;
    margin-top: 2.8rem;
  }
  @include media-query-max(415px) {
    padding: 20px;
  }

  .box-description {
    background-color: #ffffff;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.08);
    padding: 20px;
    border-radius: 12px;

    .text-description {
      font-size: 1rem;
      margin-top: 0.5rem;
    }
  }
}

.box-shipping-sent {
  background: #ffffff;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.08);
  border-radius: 12px;
  margin-top: 2rem;
  padding: 20px;

  .shipping {
    display: flex;
    align-items: center;
    .bg-icon-car {
      border-radius: 50%;
      padding: 5px;
      background-color: $orange-two;
      margin-right: 10px;

      &.driver {
        background-color: $orange-one;
      }
    }
    h5 {
      font-size: 1.5rem;
    }
  }
}

.bg-session {
  margin-top: 3rem;
  padding: 0 20px;

  .box-session {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    color: $neutral-black;

    &:hover {
      text-decoration: none;
      color: $secondary;
      cursor: pointer;
    }
    &.driver {
      &:hover {
        text-decoration: none;
        color: $primary;
        cursor: pointer;
      }
    }
    p {
      font-size: 1.1rem;
      margin-top: 4px;
      font-weight: 600;
      margin-left: 0.5rem;
    }
  }
}

.text-area-profile {
  height: 100px !important;
}
