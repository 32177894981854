.react-datepicker {
  border: none !important;
  box-shadow: 0px 0px 4px 1px rgba(99, 99, 99, 0.263);

  .react-datepicker__triangle {
    border-bottom-color: $orange-one !important;
  }
  .react-datepicker__year-read-view--down-arrow {
    border-top-color: $black;
  }

  .react-datepicker__header {
    background-color: $orange-one !important;
    border: none !important;

    .react-datepicker__current-month {
      color: $white !important;
    }
  }
  .react-datepicker__day--selected,
  .react-datepicker__day--keyboard-selected {
    background-color: $orange-one !important;
  }
  .react-datepicker__navigation--previous {
    border-right-color: $neutral-black;
    &:hover {
      border-right-color: $neutral-black;
    }
  }
  .react-datepicker__navigation--next {
    border-left-color: $neutral-black;
    &:hover {
      border-left-color: $neutral-black;
    }
  }
  .react-datepicker__day--in-range {
    background-color: black;
  }
  .react-datepicker__day--in-selecting-range {
    background-color: rgba(99, 99, 99, 0.646);

    &:hover {
      background-color: black;
    }
  }
}

.react-datepicker-year-header {
  padding: 5px;
}

.react-datepicker-popper {
  z-index: 2 !important;
}
