.navbar {
  &.driver {
    background: linear-gradient(
      175deg,
      rgba(255, 94, 99, 1) 37%,
      rgba(255, 153, 102, 1) 100%
    ) !important;
  }
  &.client {
    background-color: $secondary !important;
  }

  &.Landing {
    background: rgba(255, 255, 255, 0.9) !important;
    backdrop-filter: blur(2px) !important;
  }

  @include desktop {
    height: 63px;
  }

  .nav-link {
    color: $gray-900 !important;

    &:hover {
      .icomoon {
        opacity: 0.8;
      }
    }
  }

  .navbar-brand {
    height: 50px;
    width: 100px;
    img {
      object-fit: contain;
      height: 100%;
      width: 100%;
    }
  }
}

.dropdown-menu {
  .dropdown-item {
    &.driver {
      &:active {
        background-color: $orange-one !important;
      }
    }
    &.client {
      &:active {
        background-color: $orange-two !important;
      }
    }
  }
}

.nav-button {
  border-bottom: 2px solid transparent !important;

  &:hover {
    border-bottom: 2px solid $orange-three !important;
  }
  &:focus {
    font-weight: 500 !important;
    border-bottom: 2px solid $orange-three !important;
  }
}
