// Media queries
$sm: 570px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1600px;

// All colors
$blue: #007bff !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #e83e8c !default;
$red: #dc3545 !default;
$orange: #fd7e14 !default;
$yellow: #ffc107 !default;
$green: #28a745 !default;
$teal: #20c997 !default;
$cyan: #17a2b8 !default;

// Custom Colors
$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000 !default;

// Project Colors
$bg-special-offer: #ebfff2 !default;

// Orange Colors
$orange-one: #ff5e62 !default;
$orange-two: #ff9966 !default;
$orange-three: #ffd88f !default;
$orange-gradient: gradient(
  315deg,
  #ff9966 8.87%,
  #ff9866 9.2%,
  #ff7964 29.44%,
  #ff6562 46.47%,
  #ff5e62 58.13%
) !default;
$secondary-one: #ffcfd0 !default; //rosa
$secondary-two: #ffe0d1 !default; //naranja
$secondary-three: #fff3dd !default; //amarillo
// Hover Fondos
$hover-one: #ffefef !default; //rosa
$hover-two: #fff5f0 !default; //naranja
$hover-three: #fffbf4 !default; //amarillo
// neutrals
$neutral-black: #000000;
$neutral-gray-one: #6b6a69;
$neutral-gray-two: #c4c4c4;
$neutral-gray-three: #f9f9f9;
$neutral-white: #ffffff;
//neautral custom
$gray-1: #6b6a69;
$gray-2: #c4c4c4;
$gray-3: #ececec;
// states
$state-red: #cc0005;
$state-red-light: #ffe7e7;
$state-green: #20d55e;
$state-yellow: #f8be35;
// states variations
$state-variation-red: #f0b3b4;
$state-variation-pink: #ffcfd0;
$state-variation-green: #bcf3cf;
$state-variation-yellow: #fdebc2;

// Default Colors
$primary: $orange-one !default;
$secondary: $orange-two !default;
$success: $state-green !default;
$info: #c4c4c4 !default;
$warning: #ffd88f !default;
$danger: $red !default;
$light: $gray-100 !default;
$dark: $gray-800 !default;

$dark-blue: #182c33;
$nice-blue: #46759a;
