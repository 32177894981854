.modal-content {
  border-radius: 12px;
  border: none;
  padding: 15px 10px;
  box-shadow: 0px 4px 19px rgba(0, 0, 0, 0.25);

  .modal-body {
    padding: 0;
  }

  .modal-header {
    border-bottom: none;
    padding: 0 1rem;

    .close {
      opacity: 1;
      span {
        color: $neutral-black !important;
      }
    }
  }
  .modal-footer {
    border-top: none;
    justify-content: center;
    flex-wrap: nowrap;

    button {
      width: 100%;
    }
  }
}
