// NEXT SHIPPING
.dt-driver-next-shipping {
  .rdt_TableCell:nth-child(4) {
    display: flex;
    justify-content: flex-end;
    margin-right: 15px;
  }
  .rdt_TableCell:nth-child(5) {
    position: absolute;
    right: 20px;
  }
  .rdt_TableCell:first-child {
    border-right: 1px solid $neutral-gray-two;
    margin-right: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
    min-width: 340px;
  }

  @media (max-width: $md) {
    .rdt_TableRow {
      display: flex;
      align-items: flex-start;
    }
    .rdt_TableCell:first-child {
      justify-content: flex-start;
      align-items: flex-start;
      border-right: none;
      margin: 0;
    }
    .rdt_TableCell:last-child {
      justify-content: center;
      align-items: center;
      width: 100%;
      margin-bottom: 0;
      padding: 0px;
    }
    .icon-box {
      display: flex !important;
      align-content: flex-start !important;
      justify-content: flex-start !important;
      padding-left: 20px;
    }
    .rdt_TableCell:nth-child(2) {
      margin-top: 20px;
      margin-bottom: 10px !important;
      padding-left: 15px;
      margin-bottom: 10px;
      width: 100% !important;
    }
    .rdt_TableCell:nth-child(3) {
      padding-left: 7px;
      margin-bottom: -15px;
    }
    .rdt_TableCell:nth-child(4) {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 5px;
    }
    .rdt_TableCell:nth-child(5) {
      position: relative;
      top: -180px;
      right: -10px !important;
    }
  }
}
