// CLIENT SHIPPMENTS CARD
.dt-client-shippments {
  .dotted-line {
    position: absolute;
    margin-top: 2px;
    height: 20px;

    &.bg-green {
      border-left: 3px dotted $state-green !important;
    }

    &.bg-orange {
      border-left: 3px dotted $secondary !important;
    }

    &.bg-disabled {
      border-left: 3px dotted $gray-500 !important;
    }

    &.bg-expired {
      border-left: 3px dotted $state-variation-red !important;
    }
  }

  .rdt_TableRow {
    &.bg-oportunity {
      background: $bg-special-offer !important;
    }

    &:hover {
      &.hover-green {
        border: 2px solid $state-variation-green !important;
        background: $bg-special-offer !important;
      }

      &.hover-yellow {
        border: 2px solid $state-yellow !important;
      }

      &.hover-disabled {
        border: 2px solid $gray-1 !important;
        background: $gray-3 !important;
      }

      &.bg-green {
        border: 2px solid $state-green !important;
        background: $bg-special-offer !important;
      }

      &.bg-orange {
        border: 2px solid $orange-two !important;
        background: $hover-two !important;
      }

      &.bg-disabled {
        border: 2px solid $gray-1 !important;
        background: $gray-3 !important;
      }

      &.bg-expired {
        border: 2px solid $danger !important;
        background: $state-red-light !important;
      }

      cursor: pointer;

      .icon-box {
        &.bg-disabled {
          background: $gray-2 !important;
        }
      }
    }
  }

  .icon-primary {
    &.bg-green {
      color: $state-green;
    }

    &.bg-orange {
      color: $secondary;
    }

    &.bg-disabled {
      color: $gray-600;
    }

    &.bg-expired {
      color: $state-variation-red;
    }
  }

  .rdt_TableCell:nth-child(5) {
    padding-right: 15px;
    justify-content: flex-end;
    max-width: 120px;
  }
}

@media (max-width: $md) {
  .dt-client-shippments {
    .icon-box {
      display: flex !important;
      align-content: flex-start !important;
      justify-content: flex-start !important;
      padding-left: 10px;
      width: 100% !important;
      flex-direction: row !important;
    }

    .rdt_TableRow {
      display: flex;
      align-items: flex-start;
    }

    .rdt_TableCell:first-child {
      margin-top: 15px;
    }

    .rdt_TableCell:nth-child(2) {
      margin-top: 18px;
      padding-left: 0px;
    }

    .rdt_TableCell:nth-child(3) {
      padding-left: 12px;
      margin-top: 13px;
    }

    .rdt_TableCell:nth-child(4) {
      justify-content: center;
    }

    .rdt_TableCell:nth-child(5) {
      margin-top: -10px;
      padding-bottom: 6px;
      width: 100%;
      max-width: 100%;
      justify-content: center;
    }
  }
  .quotations {
    .rdt_TableCell:first-child {
      margin-top: 18px !important;
    }

    .rdt_TableCell:nth-child(2) {
      margin-top: 10px !important;
      padding-left: 12px !important;
    }

    .rdt_TableCell:nth-child(3) {
      justify-content: center;
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
}

.shippments-done {
  .rdt_TableRow {
    padding-left: 15px;
  }
}
