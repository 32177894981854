.btn {
  border-radius: 15px;
  font-size: 1.1rem;
  font-weight: 600;
  border-radius: 15px;
  color: $neutral-black !important;
  @include transition(
    opacity 0.3s ease,
    background-color 0.3s ease,
    color 0.3s ease,
    border-color 0.3s ease
  );

  &:disabled {
    background-color: #b9b9b9;
    border-color: #a2a2a2;
    pointer-events: none;
  }

  &-primary {
    &:hover,
    &:focus {
      background-color: darken($primary, 5) !important;
    }

    &.outline {
      background-color: $white;
      border: 1px solid $primary;
      color: $neutral-black;

      &:hover,
      &:focus {
        background-color: lighten($primary, 10) !important;
        color: $neutral-black !important;
        border: 1px solid $primary;
      }
    }
  }

  &-secondary {
    &:hover,
    &:focus {
      background-color: darken($secondary, 5) !important;
      color: $neutral-black !important;
    }

    &.outline {
      background-color: $white;
      border: 1px solid $secondary;

      &:hover,
      &:focus {
        background-color: lighten($secondary, 10) !important;
        color: $neutral-black !important;
        border: 1px solid $secondary;
      }
    }
  }

  &-success {
    color: $neutral-black !important;

    &:hover,
    &:focus {
      background-color: darken($success, 5) !important;
    }
  }

  &-primary-outline {
    background-color: transparent;
    border: 1px solid $orange-one;
    color: $primary;

    &.black-text {
      color: $neutral-black;
    }

    &:hover,
    &:focus {
      background-color: $primary !important;
      color: $neutral-black;
    }
  }

  &-secondary-outline {
    background-color: transparent;
    border: 1px solid $orange-two;

    &:hover {
      background-color: $secondary-two !important;
      color: black !important;
    }

    &:focus {
      background-color: darken($secondary, 5) !important;
      color: black !important;
    }
  }

  &-primary-outline-text {
    background-color: transparent;
    color: $danger !important;

    &.black-text {
      color: $neutral-black;
    }

    &:hover,
    &:focus {
      border: 1px solid $orange-one;
      color: $neutral-black;
    }
  }

  &.btn-active {
    &.primary-outline {
      background-color: $orange-one;
    }
    &.secondary-outline {
      background-color: $orange-two;
    }
  }

  &.text-black {
    color: $neutral-black;
  }

  &.btn-logout {
    background-color: transparent !important;
    color: $neutral-black;
    border: none;
    font-size: 1.1rem;
    font-weight: 600;
    margin: 12px 0 0 15px;

    &.driver {
      &:hover,
      &:focus,
      &:active {
        background-color: transparent !important;
        color: $orange-one !important;
        border: none !important;
        box-shadow: none !important;
      }
    }

    &.client {
      &:hover,
      &:focus,
      &:active {
        background-color: transparent !important;
        color: $orange-two !important;
        border: none !important;
        box-shadow: none !important;
      }
    }
  }

  &-icon {
    padding: 0px 15px 0 0;

    .icomoon {
      height: 23px !important;
      width: 23px !important;
    }
  }

  &-transparent {
    border-radius: 50%;
    width: 37px;
  }

  &-selected_quotation {
    background-color: $orange-one !important;
  }

  &-picked {
    background-color: $orange-two !important;
  }

  &-to_be_delivered {
    background-color: $orange-three !important;
  }
}

.btn-go-back {
  margin-bottom: 1.7rem;
  margin-right: -0.4rem;
  width: 30px !important;

  &:hover {
    cursor: pointer;
  }

  @include media-query-max(768px) {
    width: 20px !important;
    margin-right: 0 !important;
  }
}
