.question-and-answers {
  form {
    padding: 10px;
    margin: 0 !important;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .form-group {
    width: 100%;
  }

  textarea {
    font-size: 16px;
    padding: 15px;
    border-radius: 15px !important;
    border: 1px solid gray;
    width: 100% !important;
  }

  .person-box {
    border-radius: 20px;
    background: $orange-two;
    height: 40px;
    width: 40px;
  }

  .question {
    // background: green;
    border-radius: 15px;
    padding: 5px 15px 5px 0;
  }

  .answer {
    border-radius: 15px;
    padding: 5px 0px 5px 15px;
  }

  .max-width {
    width: 85%;
  }

  .btn {
    font-size: 16px;
  }

  .small-font-driver {
    font-size: 11px;
    color: $primary;
  }
  .small-font-client {
    font-size: 11px;
    color: $secondary;
  }

  .line-space {
    white-space: pre-line;
  }
}
