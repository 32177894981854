.dt-driver-trips {
  .driver-route-box {
    padding: 0 10px;
    border-radius: 24px;
    background: $secondary-two;
    height: 35px;
    min-width: 215px;
    justify-content: center !important;
  }
  .rdt_TableRow {
    padding: 0 20px 0 10px;
  }
  .rdt_TableRow:hover {
    border: 2px solid $orange-two !important;
    background: $hover-two !important;
    transition: all 0.3s linear;
  }
  .rdt_TableCell:nth-child(1) {
    min-width: 225px;
  }
  .rdt_TableCell:nth-child(4) {
    justify-content: center;
  }
  .rdt_TableCell:nth-child(5) {
    justify-content: end;
  }

  @media (max-width: $md) {
    .rdt_TableCell:nth-child(2) {
      justify-content: center;
      margin-top: 10px;
    }
    .rdt_TableCell:nth-child(3) {
      justify-content: center;
      margin-top: 10px;
    }
    .rdt_TableCell:nth-child(4) {
      margin-top: 10px;
      p {
        background: $orange-three;
        padding: 5px 20px;
        border-radius: 15px;
      }
    }
    .rdt_TableCell:nth-child(5) {
      justify-content: center;
      margin-bottom: 10px;
    }
    .driver-route-box {
      padding: 0 10px;
      border-radius: 24px;
      background: $secondary-two;
      height: 35px;
      width: 100%;
    }
  }
}
