.dates-filter--container {
  .dates-filter--pickers {
    display: flex;
  }
}

.sidebar-filter--container {
  position: fixed;
  padding: 20px 15px 0px 15px;
  right: 0;
  bottom: 0;
  z-index: 10000;
  height: 100vh;
  width: 400px;
  max-width: 90%;
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(15px);
  transition: all 0.4s ease-in-out;
  opacity: 1;

  @media (max-width: 480px) {
    max-width: 74%;
    .css-1wa3eu0-placeholder {
      font-size: 12px;
    }
  }

  .form-group {
    width: 100%;
  }

  &.collapsed {
    transform: translateX(550px);
    opacity: 0;
  }
}

.dates-filter--icon {
  border: 1px solid;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px !important;
  min-width: 40px !important;

  &:hover {
    background-color: $secondary;
  }
}

.location-filter {
  .form-group {
    width: 200px;
    margin-left: 10px;
  }
  .form-label {
    display: none !important;
    font-size: 14px !important;
  }
  @media (max-width: $md) {
    .location-filter {
      background: green !important;
    }
    .form-group {
      width: 145px;
      margin-left: 5px;
    }
    .css-1wa3eu0-placeholder {
      font-size: 13px;
    }
  }
}

.paid-filter {
  display: flex;
  align-items: center;
  .form-group {
    width: 250px;
    .css-2b097c-container {
      margin-right: 15px;
      top: 8px;
    }
  }
}

.css-26l3qy-menu {
  z-index: 2 !important;
}
