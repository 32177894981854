// SPECCIAL OFFERS CARD
.dt-driver-special-offers {
  margin-bottom: 40px;
  padding: 15px 20px 0px 20px !important;

  .rdt_TableRow {
    background: $bg-special-offer !important;
    box-shadow: none !important;
  }

  .icon-primary {
    color: $state-green;
  }

  .dotted-line {
    margin-top: 2px;
    height: 20px;
    border-left: 3px dotted $state-green !important;
  }

  .rdt_TableRow:hover {
    border: 2px solid $state-green !important;
    background: $state-variation-green;
    transition: all 0.3s linear;
  }

  .icon-box {
    background: $state-green;
  }

  .quotations-box {
    background: $state-green;
    padding: 5 8px;
    border-radius: 24px;
  }

  .rdt_TableCell:last-child {
    position: absolute;
    right: 20px;
  }

  @media (max-width: $md) {
    .icon-box {
      display: flex !important;
      align-content: flex-start !important;
      justify-content: flex-start !important;
      padding-left: 10px;
      flex-direction: row !important;
    }

    .rdt_TableRow {
      display: flex;
      align-items: flex-start;
    }

    .rdt_TableCell:first-child {
      background-color: $state-green;
    }

    .rdt_TableCell:nth-child(2) {
      margin-top: 15px;
      margin-bottom: 10px;
      padding-left: 12px;
      width: 100%;
    }

    .rdt_TableCell:nth-child(3) {
      padding-left: 11px;
      margin-bottom: -10px;
    }

    .rdt_TableCell:nth-child(4) {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 5px;
    }

    .rdt_TableCell:nth-child(5) {
      width: 50px !important;
      height: 50px !important;
      display: flex;
      justify-content: flex-end;
      position: relative;
      top: -225px !important;
      left: 210px !important;
      padding: 0 !important;
    }
  }
}
