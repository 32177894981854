.quotation-screen {
  position: fixed;
  z-index: 10000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.25) !important;
  display: flex;
  justify-content: center;
  align-items: center;

  .items-container {
    max-height: 350px;
    max-width: 370px;
    overflow-y: scroll;
    @include firefox-scrollbar-styles($secondary, white);
  }

  .quotation-card {
    width: 450px;
    padding: 20px 30px;
    background: white;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;

    .quotation-screen-driver-description {
      width: 100%;
    }
  }
}

@media (max-width: $md) {
  .quotation-screen {
    align-items: start;
    padding-top: 15%;

    .dotted-line {
      display: none;
    }

    .items-container {
      max-height: 350px;
      max-width: 82vw;
      overflow-y: scroll;
      @include firefox-scrollbar-styles($secondary, white);
    }

    .quotation-screen-driver-description {
      min-height: 350px;
      min-width: 100%;
    }

    .quotation-card {
      width: 92%;
      height: 90vh;
      overflow-y: scroll;
      overflow-x: hidden;

      .quotation-screen-driver-description {
        width: 98%;
      }
    }
  }
}

.form-extra--info {
  background: $state-variation-yellow;
  border-radius: 15px;
  padding: 10px 5px 1px 15px;

  h6 {
    font-size: 1.05rem;
  }

  ul {
    margin-left: -1rem;
    li {
      font-weight: 300;
      font-size: 14px;
    }
  }
}

.solicitude-preview--container {
  border-radius: 12px !important;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 8px;
  padding: 15px 10px;
  position: sticky;
  border: none;
  top: 5rem;

  .images-scrollable {
    overflow-x: auto;
    width: 100%;
    @include scrollbar(rgba(179, 177, 177, 0.31), rgb(255, 255, 255));
  }

  .card-img {
    max-width: 100px;
    width: 80px;
    height: 60px;
  }

  .title-preview {
    font-size: 1.1rem;
  }

  .item-name {
    font-size: 1rem;
  }

  .min-w {
    min-width: 60px;
  }
}

.quotation-card-modal {
  .images-scrollable {
    overflow-x: auto;
    width: 100% !important;
    @include scrollbar(rgba(179, 177, 177, 0.45), rgb(255, 255, 255));
    @include firefox-scrollbar-styles($secondary, white);
  }

  .empty_img-container {
    background-size: contain;
    background-repeat: no-repeat;
    width: 90px;
    height: 90px !important;
    background-image: url(app/assets/images/image-no-available.svg);
    border-radius: 5px;
    margin-top: 10px;
    overflow: hidden;
  }
}

.car-img-modal {
  width: 130px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin-left: 10px;
  border-radius: 5px;

  &:first-child {
    margin-left: 0px;
  }
}
