@charset "UTF-8";
/*!
 * Bootstrap v4.6.2 (https://getbootstrap.com/)
 * Copyright 2011-2022 The Bootstrap Authors
 * Copyright 2011-2022 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
@import '~react-toastify/dist/ReactToastify.css';
@import '~react-datepicker/dist/react-datepicker.css';
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #ff5e62;
  --secondary: #ff9966;
  --success: #20d55e;
  --info: #c4c4c4;
  --warning: #ffd88f;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #ff5e62;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: #ff1217;
  text-decoration: underline;
}

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

label {
  display: inline-block;
  margin-bottom: 0.1rem;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: 2.5rem;
}

h2, .h2 {
  font-size: 2rem;
}

h3, .h3 {
  font-size: 1.75rem;
}

h4, .h4 {
  font-size: 1.5rem;
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 80%;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #6c757d;
}

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}
kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container,
.container-fluid,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col-xl,
.col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg,
.col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md,
.col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm,
.col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col,
.col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.33333333%;
  max-width: 8.33333333%;
}

.col-2 {
  flex: 0 0 16.66666667%;
  max-width: 16.66666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.33333333%;
  max-width: 33.33333333%;
}

.col-5 {
  flex: 0 0 41.66666667%;
  max-width: 41.66666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.33333333%;
  max-width: 58.33333333%;
}

.col-8 {
  flex: 0 0 66.66666667%;
  max-width: 66.66666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.33333333%;
  max-width: 83.33333333%;
}

.col-11 {
  flex: 0 0 91.66666667%;
  max-width: 91.66666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
}
.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
}
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}
.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #dee2e6;
}
.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6;
}
.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #ffd2d3;
}
.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #ffabad;
}

.table-hover .table-primary:hover {
  background-color: #ffb9ba;
}
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #ffb9ba;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #ffe2d4;
}
.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #ffcaaf;
}

.table-hover .table-secondary:hover {
  background-color: #ffd1bb;
}
.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #ffd1bb;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c1f3d2;
}
.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #8be9ab;
}

.table-hover .table-success:hover {
  background-color: #acefc2;
}
.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #acefc2;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #eeeeee;
}
.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #e0e0e0;
}

.table-hover .table-info:hover {
  background-color: #e1e1e1;
}
.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #e1e1e1;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #fff4e0;
}
.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffebc5;
}

.table-hover .table-warning:hover {
  background-color: #ffebc7;
}
.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #ffebc7;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb;
}
.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ed969e;
}

.table-hover .table-danger:hover {
  background-color: #f1b0b7;
}
.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f1b0b7;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe;
}
.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc;
}

.table-hover .table-light:hover {
  background-color: #ececf6;
}
.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #ececf6;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca;
}
.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c;
}

.table-hover .table-dark:hover {
  background-color: #b9bbbe;
}
.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b9bbbe;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}
.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55;
}
.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.table-dark {
  color: #fff;
  background-color: #343a40;
}
.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #454d55;
}
.table-dark.table-bordered {
  border: 0;
}
.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}
.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(
  1.5em + 0.75rem + 2px
);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #ffdede;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 94, 98, 0.25);
}
.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

input[type=date].form-control,
input[type=time].form-control,
input[type=datetime-local].form-control,
input[type=month].form-control {
  appearance: none;
}

select.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}
select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(
  1.5em + 0.5rem + 2px
);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.form-control-lg {
  height: calc(
  1.5em + 1rem + 2px
);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form-row > .col,
.form-row > [class*=col-] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}
.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #20d55e;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(32, 213, 94, 0.9);
  border-radius: 0.25rem;
}
.form-row > .col > .valid-tooltip, .form-row > [class*=col-] > .valid-tooltip {
  left: 5px;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #20d55e;
  padding-right: calc(
  1.5em + 0.75rem
) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2320d55e' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(
  0.375em + 0.1875rem
) center;
  background-size: calc(
  0.75em + 0.375rem
) calc(
  0.75em + 0.375rem
);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #20d55e;
  box-shadow: 0 0 0 0.2rem rgba(32, 213, 94, 0.25);
}

.was-validated select.form-control:valid, select.form-control.is-valid {
  padding-right: 3rem !important;
  background-position: right 1.5rem center;
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(
  1.5em + 0.75rem
);
  background-position: top calc(
  0.375em + 0.1875rem
) right calc(
  0.375em + 0.1875rem
);
}

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #20d55e;
  padding-right: calc(
  (1em + 0.75rem) * 3 / 4 + 1.75rem
) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2320d55e' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 1.75rem/calc( 0.75em + 0.375rem ) calc( 0.75em + 0.375rem ) no-repeat;
}
.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
  border-color: #20d55e;
  box-shadow: 0 0 0 0.2rem rgba(32, 213, 94, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #20d55e;
}
.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #20d55e;
}
.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #20d55e;
}
.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #45e37b;
  background-color: #45e37b;
}
.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(32, 213, 94, 0.25);
}
.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #20d55e;
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #20d55e;
}
.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #20d55e;
  box-shadow: 0 0 0 0.2rem rgba(32, 213, 94, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem;
}
.form-row > .col > .invalid-tooltip, .form-row > [class*=col-] > .invalid-tooltip {
  left: 5px;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(
  1.5em + 0.75rem
) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
  background-repeat: no-repeat;
  background-position: right calc(
  0.375em + 0.1875rem
) center;
  background-size: calc(
  0.75em + 0.375rem
) calc(
  0.75em + 0.375rem
);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated select.form-control:invalid, select.form-control.is-invalid {
  padding-right: 3rem !important;
  background-position: right 1.5rem center;
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(
  1.5em + 0.75rem
);
  background-position: top calc(
  0.375em + 0.1875rem
) right calc(
  0.375em + 0.1875rem
);
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc(
  (1em + 0.75rem) * 3 / 4 + 1.75rem
) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E") center right 1.75rem/calc( 0.75em + 0.375rem ) calc( 0.75em + 0.375rem ) no-repeat;
}
.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545;
}
.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #dc3545;
}
.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d;
}
.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545;
}
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.form-inline .form-check {
  width: 100%;
}
@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
  .form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: #212529;
  text-decoration: none;
}
.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 94, 98, 0.25);
}
.btn.disabled, .btn:disabled {
  opacity: 0.65;
}
.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}
a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #ff5e62;
  border-color: #ff5e62;
}
.btn-primary:hover {
  color: #fff;
  background-color: #ff383d;
  border-color: #ff2b30;
}
.btn-primary:focus, .btn-primary.focus {
  color: #fff;
  background-color: #ff383d;
  border-color: #ff2b30;
  box-shadow: 0 0 0 0.2rem rgba(255, 118, 122, 0.5);
}
.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #ff5e62;
  border-color: #ff5e62;
}
.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #ff2b30;
  border-color: #ff1e24;
}
.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 118, 122, 0.5);
}

.btn-secondary {
  color: #212529;
  background-color: #ff9966;
  border-color: #ff9966;
}
.btn-secondary:hover {
  color: #212529;
  background-color: #ff8040;
  border-color: #ff7733;
}
.btn-secondary:focus, .btn-secondary.focus {
  color: #212529;
  background-color: #ff8040;
  border-color: #ff7733;
  box-shadow: 0 0 0 0.2rem rgba(222, 136, 93, 0.5);
}
.btn-secondary.disabled, .btn-secondary:disabled {
  color: #212529;
  background-color: #ff9966;
  border-color: #ff9966;
}
.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
  color: #212529;
  background-color: #ff7733;
  border-color: #ff6f26;
}
.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 136, 93, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #20d55e;
  border-color: #20d55e;
}
.btn-success:hover {
  color: #fff;
  background-color: #1bb44f;
  border-color: #19a94a;
}
.btn-success:focus, .btn-success.focus {
  color: #fff;
  background-color: #1bb44f;
  border-color: #19a94a;
  box-shadow: 0 0 0 0.2rem rgba(65, 219, 118, 0.5);
}
.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #20d55e;
  border-color: #20d55e;
}
.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active, .show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #19a94a;
  border-color: #189e46;
}
.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(65, 219, 118, 0.5);
}

.btn-info {
  color: #212529;
  background-color: #c4c4c4;
  border-color: #c4c4c4;
}
.btn-info:hover {
  color: #212529;
  background-color: #b1b1b1;
  border-color: #ababab;
}
.btn-info:focus, .btn-info.focus {
  color: #212529;
  background-color: #b1b1b1;
  border-color: #ababab;
  box-shadow: 0 0 0 0.2rem rgba(172, 172, 173, 0.5);
}
.btn-info.disabled, .btn-info:disabled {
  color: #212529;
  background-color: #c4c4c4;
  border-color: #c4c4c4;
}
.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active, .show > .btn-info.dropdown-toggle {
  color: #212529;
  background-color: #ababab;
  border-color: #a4a4a4;
}
.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(172, 172, 173, 0.5);
}

.btn-warning {
  color: #212529;
  background-color: #ffd88f;
  border-color: #ffd88f;
}
.btn-warning:hover {
  color: #212529;
  background-color: #ffcb69;
  border-color: #ffc65c;
}
.btn-warning:focus, .btn-warning.focus {
  color: #212529;
  background-color: #ffcb69;
  border-color: #ffc65c;
  box-shadow: 0 0 0 0.2rem rgba(222, 189, 128, 0.5);
}
.btn-warning.disabled, .btn-warning:disabled {
  color: #212529;
  background-color: #ffd88f;
  border-color: #ffd88f;
}
.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active, .show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffc65c;
  border-color: #ffc24f;
}
.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 189, 128, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
}
.btn-danger:focus, .btn-danger.focus {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}
.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active, .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #bd2130;
  border-color: #b21f2d;
}
.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}
.btn-light:focus, .btn-light.focus {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}
.btn-light.disabled, .btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active, .show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}
.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}
.btn-dark:focus, .btn-dark.focus {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}
.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active, .show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}
.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}

.btn-outline-primary {
  color: #ff5e62;
  border-color: #ff5e62;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #ff5e62;
  border-color: #ff5e62;
}
.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 94, 98, 0.5);
}
.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #ff5e62;
  background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #ff5e62;
  border-color: #ff5e62;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 94, 98, 0.5);
}

.btn-outline-secondary {
  color: #ff9966;
  border-color: #ff9966;
}
.btn-outline-secondary:hover {
  color: #212529;
  background-color: #ff9966;
  border-color: #ff9966;
}
.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 153, 102, 0.5);
}
.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #ff9966;
  background-color: transparent;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active, .show > .btn-outline-secondary.dropdown-toggle {
  color: #212529;
  background-color: #ff9966;
  border-color: #ff9966;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 153, 102, 0.5);
}

.btn-outline-success {
  color: #20d55e;
  border-color: #20d55e;
}
.btn-outline-success:hover {
  color: #fff;
  background-color: #20d55e;
  border-color: #20d55e;
}
.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(32, 213, 94, 0.5);
}
.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #20d55e;
  background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active, .show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #20d55e;
  border-color: #20d55e;
}
.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(32, 213, 94, 0.5);
}

.btn-outline-info {
  color: #c4c4c4;
  border-color: #c4c4c4;
}
.btn-outline-info:hover {
  color: #212529;
  background-color: #c4c4c4;
  border-color: #c4c4c4;
}
.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(196, 196, 196, 0.5);
}
.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #c4c4c4;
  background-color: transparent;
}
.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active, .show > .btn-outline-info.dropdown-toggle {
  color: #212529;
  background-color: #c4c4c4;
  border-color: #c4c4c4;
}
.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(196, 196, 196, 0.5);
}

.btn-outline-warning {
  color: #ffd88f;
  border-color: #ffd88f;
}
.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffd88f;
  border-color: #ffd88f;
}
.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 216, 143, 0.5);
}
.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #ffd88f;
  background-color: transparent;
}
.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active, .show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffd88f;
  border-color: #ffd88f;
}
.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 216, 143, 0.5);
}

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}
.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #dc3545;
  background-color: transparent;
}
.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active, .show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}
.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active, .show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}
.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active, .show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #ff5e62;
  text-decoration: none;
}
.btn-link:hover {
  color: #ff1217;
  text-decoration: underline;
}
.btn-link:focus, .btn-link.focus {
  text-decoration: underline;
}
.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.btn-block {
  display: block;
  width: 100%;
}
.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type=submit].btn-block,
input[type=reset].btn-block,
input[type=button].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.width {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.width {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropleft .dropdown-toggle::after {
  display: none;
}
.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^=top], .dropdown-menu[x-placement^=right], .dropdown-menu[x-placement^=bottom], .dropdown-menu[x-placement^=left] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #f8f9fa;
}
.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #ff5e62;
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}
.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropright .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}
.btn-group-toggle > .btn input[type=radio],
.btn-group-toggle > .btn input[type=checkbox],
.btn-group-toggle > .btn-group > .btn input[type=radio],
.btn-group-toggle > .btn-group > .btn input[type=checkbox] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}
.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}
.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}
.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}
.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .custom-file {
  display: flex;
  align-items: center;
}
.input-group > .custom-file:not(:last-child) .custom-file-label, .input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group:not(.has-validation) > .form-control:not(:last-child),
.input-group:not(.has-validation) > .custom-select:not(:last-child),
.input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label,
.input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > .form-control:nth-last-child(n+3),
.input-group.has-validation > .custom-select:nth-last-child(n+3),
.input-group.has-validation > .custom-file:nth-last-child(n+3) .custom-file-label,
.input-group.has-validation > .custom-file:nth-last-child(n+3) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}
.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}
.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
  z-index: 3;
}
.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.input-group-text input[type=radio],
.input-group-text input[type=checkbox] {
  margin-top: 0;
}

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(
  1.5em + 1rem + 2px
);
}

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(
  1.5em + 0.5rem + 2px
);
}

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .btn,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .input-group-text,
.input-group.has-validation > .input-group-append:nth-last-child(n+3) > .btn,
.input-group.has-validation > .input-group-append:nth-last-child(n+3) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
  print-color-adjust: exact;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #ff5e62;
  background-color: #ff5e62;
}
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(255, 94, 98, 0.25);
}
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #ffdede;
}
.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: white;
  border-color: white;
}
.custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}
.custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}
.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: 1px solid #adb5bd;
}
.custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: 50%/50% 50% no-repeat;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #ff5e62;
  background-color: #ff5e62;
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(255, 94, 98, 0.5);
}
.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(255, 94, 98, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(255, 94, 98, 0.5);
}

.custom-switch {
  padding-left: 2.25rem;
}
.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}
.custom-switch .custom-control-label::after {
  top: calc(0.25rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(
  1rem - 4px
);
  height: calc(
  1rem - 4px
);
  background-color: #adb5bd;
  border-radius: 0.5rem;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  transform: translateX(0.75rem);
}
.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(255, 94, 98, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(
  1.5em + 0.75rem + 2px
);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none;
}
.custom-select:focus {
  border-color: #ffdede;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 94, 98, 0.25);
}
.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}
.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}
.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}
.custom-select::-ms-expand {
  display: none;
}
.custom-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}

.custom-select-sm {
  height: calc(
  1.5em + 0.5rem + 2px
);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
}

.custom-select-lg {
  height: calc(
  1.5em + 1rem + 2px
);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(
  1.5em + 0.75rem + 2px
);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(
  1.5em + 0.75rem + 2px
);
  margin: 0;
  overflow: hidden;
  opacity: 0;
}
.custom-file-input:focus ~ .custom-file-label {
  border-color: #ffdede;
  box-shadow: 0 0 0 0.2rem rgba(255, 94, 98, 0.25);
}
.custom-file-input[disabled] ~ .custom-file-label, .custom-file-input:disabled ~ .custom-file-label {
  background-color: #e9ecef;
}
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}
.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(
  1.5em + 0.75rem + 2px
);
  padding: 0.375rem 0.75rem;
  overflow: hidden;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(
  1.5em + 0.75rem
);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: inherit;
  border-radius: 0 0.25rem 0.25rem 0;
}

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.custom-range:focus {
  outline: 0;
}
.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(255, 94, 98, 0.25);
}
.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(255, 94, 98, 0.25);
}
.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(255, 94, 98, 0.25);
}
.custom-range::-moz-focus-outer {
  border: 0;
}
.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #ff5e62;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    transition: none;
  }
}
.custom-range::-webkit-slider-thumb:active {
  background-color: white;
}
.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #ff5e62;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    transition: none;
  }
}
.custom-range::-moz-range-thumb:active {
  background-color: white;
}
.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #ff5e62;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    transition: none;
  }
}
.custom-range::-ms-thumb:active {
  background-color: white;
}
.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}
.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}
.custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-moz-range-track {
  cursor: default;
}
.custom-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
  .custom-file-label,
  .custom-select {
    transition: none;
  }
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}
.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}
.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}
.nav-tabs .nav-link {
  margin-bottom: -1px;
  background-color: transparent;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: #e9ecef #e9ecef #dee2e6;
}
.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0.25rem;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #ff5e62;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}
.navbar .container,
.navbar .container-fluid,
.navbar .container-sm,
.navbar .container-md,
.navbar .container-lg,
.navbar .container-xl {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.navbar-toggler:hover, .navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: 50%/100% 100% no-repeat;
}

.navbar-nav-scroll {
  max-height: 75vh;
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid,
  .navbar-expand-sm > .container-sm,
  .navbar-expand-sm > .container-md,
  .navbar-expand-sm > .container-lg,
  .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid,
  .navbar-expand-sm > .container-sm,
  .navbar-expand-sm > .container-md,
  .navbar-expand-sm > .container-lg,
  .navbar-expand-sm > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}
@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid,
  .navbar-expand-md > .container-sm,
  .navbar-expand-md > .container-md,
  .navbar-expand-md > .container-lg,
  .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid,
  .navbar-expand-md > .container-sm,
  .navbar-expand-md > .container-md,
  .navbar-expand-md > .container-lg,
  .navbar-expand-md > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid,
  .navbar-expand-lg > .container-sm,
  .navbar-expand-lg > .container-md,
  .navbar-expand-lg > .container-lg,
  .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid,
  .navbar-expand-lg > .container-sm,
  .navbar-expand-lg > .container-md,
  .navbar-expand-lg > .container-lg,
  .navbar-expand-lg > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}
@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid,
  .navbar-expand-xl > .container-sm,
  .navbar-expand-xl > .container-md,
  .navbar-expand-xl > .container-lg,
  .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid,
  .navbar-expand-xl > .container-sm,
  .navbar-expand-xl > .container-md,
  .navbar-expand-xl > .container-lg,
  .navbar-expand-xl > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}
.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
  padding-right: 0;
  padding-left: 0;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
  flex-wrap: nowrap;
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}
.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}
.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-text a {
  color: #fff;
}
.navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(
  0.25rem - 1px
);
  border-top-right-radius: calc(
  0.25rem - 1px
);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(
  0.25rem - 1px
);
  border-bottom-left-radius: calc(
  0.25rem - 1px
);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}
.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.card-header:first-child {
  border-radius: calc(
  0.25rem - 1px
) calc(
  0.25rem - 1px
) 0 0;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}
.card-footer:last-child {
  border-radius: 0 0 calc(
  0.25rem - 1px
) calc(
  0.25rem - 1px
);
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: calc(
  0.25rem - 1px
);
}

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(
  0.25rem - 1px
);
  border-top-right-radius: calc(
  0.25rem - 1px
);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(
  0.25rem - 1px
);
  border-bottom-left-radius: calc(
  0.25rem - 1px
);
}

.card-deck .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    flex: 1 0 0%;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.card-group > .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion {
  overflow-anchor: none;
}
.accordion > .card {
  overflow: hidden;
}
.accordion > .card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.accordion > .card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: -1px;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}
.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #ff5e62;
  background-color: #fff;
  border: 1px solid #dee2e6;
}
.page-link:hover {
  z-index: 2;
  color: #ff1217;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
.page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 94, 98, 0.25);
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #ff5e62;
  border-color: #ff5e62;
}
.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}
a.badge:hover, a.badge:focus {
  text-decoration: none;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #fff;
  background-color: #ff5e62;
}
a.badge-primary:hover, a.badge-primary:focus {
  color: #fff;
  background-color: #ff2b30;
}
a.badge-primary:focus, a.badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 94, 98, 0.5);
}

.badge-secondary {
  color: #212529;
  background-color: #ff9966;
}
a.badge-secondary:hover, a.badge-secondary:focus {
  color: #212529;
  background-color: #ff7733;
}
a.badge-secondary:focus, a.badge-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 153, 102, 0.5);
}

.badge-success {
  color: #fff;
  background-color: #20d55e;
}
a.badge-success:hover, a.badge-success:focus {
  color: #fff;
  background-color: #19a94a;
}
a.badge-success:focus, a.badge-success.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(32, 213, 94, 0.5);
}

.badge-info {
  color: #212529;
  background-color: #c4c4c4;
}
a.badge-info:hover, a.badge-info:focus {
  color: #212529;
  background-color: #ababab;
}
a.badge-info:focus, a.badge-info.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(196, 196, 196, 0.5);
}

.badge-warning {
  color: #212529;
  background-color: #ffd88f;
}
a.badge-warning:hover, a.badge-warning:focus {
  color: #212529;
  background-color: #ffc65c;
}
a.badge-warning:focus, a.badge-warning.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 216, 143, 0.5);
}

.badge-danger {
  color: #fff;
  background-color: #dc3545;
}
a.badge-danger:hover, a.badge-danger:focus {
  color: #fff;
  background-color: #bd2130;
}
a.badge-danger:focus, a.badge-danger.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.badge-light {
  color: #212529;
  background-color: #f8f9fa;
}
a.badge-light:hover, a.badge-light:focus {
  color: #212529;
  background-color: #dae0e5;
}
a.badge-light:focus, a.badge-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.badge-dark {
  color: #fff;
  background-color: #343a40;
}
a.badge-dark:hover, a.badge-dark:focus {
  color: #fff;
  background-color: #1d2124;
}
a.badge-dark:focus, a.badge-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem;
}
@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 4rem;
}
.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #853133;
  background-color: #ffdfe0;
  border-color: #ffd2d3;
}
.alert-primary hr {
  border-top-color: #ffb9ba;
}
.alert-primary .alert-link {
  color: #602325;
}

.alert-secondary {
  color: #855035;
  background-color: #ffebe0;
  border-color: #ffe2d4;
}
.alert-secondary hr {
  border-top-color: #ffd1bb;
}
.alert-secondary .alert-link {
  color: #613a26;
}

.alert-success {
  color: #116f31;
  background-color: #d2f7df;
  border-color: #c1f3d2;
}
.alert-success hr {
  border-top-color: #acefc2;
}
.alert-success .alert-link {
  color: #0a431d;
}

.alert-info {
  color: #666666;
  background-color: #f3f3f3;
  border-color: #eeeeee;
}
.alert-info hr {
  border-top-color: #e1e1e1;
}
.alert-info .alert-link {
  color: #4d4d4d;
}

.alert-warning {
  color: #85704a;
  background-color: #fff7e9;
  border-color: #fff4e0;
}
.alert-warning hr {
  border-top-color: #ffebc7;
}
.alert-warning .alert-link {
  color: #645438;
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
.alert-danger hr {
  border-top-color: #f1b0b7;
}
.alert-danger .alert-link {
  color: #491217;
}

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe;
}
.alert-light hr {
  border-top-color: #ececf6;
}
.alert-light .alert-link {
  color: #686868;
}

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}
.alert-dark hr {
  border-top-color: #b9bbbe;
}
.alert-dark .alert-link {
  color: #040505;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}
.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #ff5e62;
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}
.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}
.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #ff5e62;
  border-color: #ff5e62;
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #853133;
  background-color: #ffd2d3;
}
.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #853133;
  background-color: #ffb9ba;
}
.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #853133;
  border-color: #853133;
}

.list-group-item-secondary {
  color: #855035;
  background-color: #ffe2d4;
}
.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #855035;
  background-color: #ffd1bb;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #855035;
  border-color: #855035;
}

.list-group-item-success {
  color: #116f31;
  background-color: #c1f3d2;
}
.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #116f31;
  background-color: #acefc2;
}
.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #116f31;
  border-color: #116f31;
}

.list-group-item-info {
  color: #666666;
  background-color: #eeeeee;
}
.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #666666;
  background-color: #e1e1e1;
}
.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #666666;
  border-color: #666666;
}

.list-group-item-warning {
  color: #85704a;
  background-color: #fff4e0;
}
.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #85704a;
  background-color: #ffebc7;
}
.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #85704a;
  border-color: #85704a;
}

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb;
}
.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #721c24;
  background-color: #f1b0b7;
}
.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #721c24;
  border-color: #721c24;
}

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe;
}
.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #818182;
  background-color: #ececf6;
}
.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #818182;
  border-color: #818182;
}

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca;
}
.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #1b1e21;
  background-color: #b9bbbe;
}
.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}
.close:hover {
  color: #000;
  text-decoration: none;
}
.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  opacity: 0.75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}

a.close.disabled {
  pointer-events: none;
}

.toast {
  flex-basis: 350px;
  max-width: 350px;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  opacity: 0;
  border-radius: 0.25rem;
}
.toast:not(:last-child) {
  margin-bottom: 0.75rem;
}
.toast.showing {
  opacity: 1;
}
.toast.show {
  display: block;
  opacity: 1;
}
.toast.hide {
  display: none;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.toast-body {
  padding: 0.75rem;
}

.modal-open {
  overflow: hidden;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}
.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}
.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  height: min-content;
  content: "";
}
.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}
.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}
.modal-footer > * {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
    height: min-content;
  }
  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^=top] {
  padding: 0.4rem 0;
}
.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^=top] .arrow {
  bottom: 0;
}
.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^=top] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^=right] {
  padding: 0 0.4rem;
}
.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^=right] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^=right] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^=bottom] {
  padding: 0.4rem 0;
}
.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^=bottom] .arrow {
  top: 0;
}
.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^=left] {
  padding: 0 0.4rem;
}
.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^=left] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^=left] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}
.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}
.popover .arrow::before, .popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top, .bs-popover-auto[x-placement^=top] {
  margin-bottom: 0.5rem;
}
.bs-popover-top > .arrow, .bs-popover-auto[x-placement^=top] > .arrow {
  bottom: calc(-0.5rem - 1px);
}
.bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^=top] > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^=top] > .arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-right, .bs-popover-auto[x-placement^=right] {
  margin-left: 0.5rem;
}
.bs-popover-right > .arrow, .bs-popover-auto[x-placement^=right] > .arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^=right] > .arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^=right] > .arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom, .bs-popover-auto[x-placement^=bottom] {
  margin-top: 0.5rem;
}
.bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^=bottom] > .arrow {
  top: calc(-0.5rem - 1px);
}
.bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^=bottom] > .arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^=bottom] > .arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left, .bs-popover-auto[x-placement^=left] {
  margin-right: 0.5rem;
}
.bs-popover-left > .arrow, .bs-popover-auto[x-placement^=left] > .arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^=left] > .arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^=left] > .arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-right {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: 50%/100% 100% no-repeat;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentcolor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: 0.75s linear infinite spinner-border;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  background-color: currentcolor;
  border-radius: 50%;
  opacity: 0;
  animation: 0.75s linear infinite spinner-grow;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    animation-duration: 1.5s;
  }
}
.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #ff5e62 !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #ff2b30 !important;
}

.bg-secondary {
  background-color: #ff9966 !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #ff7733 !important;
}

.bg-success {
  background-color: #20d55e !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #19a94a !important;
}

.bg-info {
  background-color: #c4c4c4 !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #ababab !important;
}

.bg-warning {
  background-color: #ffd88f !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #ffc65c !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #ff5e62 !important;
}

.border-secondary {
  border-color: #ff9966 !important;
}

.border-success {
  border-color: #20d55e !important;
}

.border-info {
  border-color: #c4c4c4 !important;
}

.border-warning {
  border-color: #ffd88f !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: 0.2rem !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-lg {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714286%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714286%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}
@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}
.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important;
  }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important;
  }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -3rem !important;
  }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important;
  }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important;
  }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important;
  }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important;
  }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -3rem !important;
  }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important;
  }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important;
  }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important;
  }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important;
  }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -3rem !important;
  }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important;
  }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important;
  }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -3rem !important;
  }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important;
  }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}
.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #ff5e62 !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #ff1217 !important;
}

.text-secondary {
  color: #ff9966 !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #ff661a !important;
}

.text-success {
  color: #20d55e !important;
}

a.text-success:hover, a.text-success:focus {
  color: #169241 !important;
}

.text-info {
  color: #c4c4c4 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #9e9e9e !important;
}

.text-warning {
  color: #ffd88f !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #ffbd43 !important;
}

.text-danger {
  color: #dc3545 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important;
}

.text-body {
  color: #212529 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }
  .container {
    min-width: 992px !important;
  }
  .navbar {
    display: none;
  }
  .badge {
    border: 1px solid #000;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
  .table th {
    background-color: #fff !important;
  }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important;
  }
  .table-dark {
    color: inherit;
  }
  .table-dark th,
  .table-dark td,
  .table-dark thead th,
  .table-dark tbody + tbody {
    border-color: #dee2e6;
  }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6;
  }
}
@font-face {
  font-family: "Baloo Bhai 2";
  src: url("app/assets/fonts/static/BalooBhai2-ExtraBold.ttf") format("truetype");
  font-weight: 800;
}
@font-face {
  font-family: "Baloo Bhai 2";
  src: url("app/assets/fonts/static/BalooBhai2-Bold.ttf") format("truetype");
  font-weight: 700;
}
@font-face {
  font-family: "Baloo Bhai 2";
  src: url("app/assets/fonts/static/BalooBhai2-SemiBold.ttf") format("truetype");
  font-weight: 600;
}
@font-face {
  font-family: "Baloo Bhai 2";
  src: url("app/assets/fonts/static/BalooBhai2-Medium.ttf") format("truetype");
  font-weight: 500;
}
@font-face {
  font-family: "Baloo Bhai 2";
  src: url("app/assets/fonts/static/BalooBhai2-Regular.ttf") format("truetype");
  font-weight: 400;
}
:root {
  --project-font-size: 1.05rem;
  --project-font-family: "Baloo Bhai 2", "Trebuchet MS";
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold;
  margin: 0;
}

h1 {
  font-size: calc(var(--project-font-size) * 3);
  letter-spacing: calc(var(--project-font-size) * 3 * 0.02);
  line-height: calc(var(--project-font-size) * 3 * 1.35);
}

h2 {
  font-size: calc(var(--project-font-size) * 2.5);
  letter-spacing: calc(var(--project-font-size) * 2.5 * 0.01);
  line-height: calc(var(--project-font-size) * 2.5 * 1.3);
}

h3 {
  font-size: calc(var(--project-font-size) * 2);
  letter-spacing: calc(var(--project-font-size) * 2 * 0.03);
  line-height: calc(var(--project-font-size) * 2 * 1.3);
}

h4 {
  font-size: calc(var(--project-font-size) * 1.8);
  letter-spacing: calc(var(--project-font-size) * 1.8 * 0.02);
  line-height: calc(var(--project-font-size) * 1.8 * 1.35);
}

h5 {
  font-size: calc(var(--project-font-size) * 1.4);
  letter-spacing: calc(var(--project-font-size) * 1.5 * 0.02);
  line-height: calc(var(--project-font-size) * 1.5 * 1.35);
}

h6 {
  font-size: calc(var(--project-font-size) * 1.2);
  letter-spacing: calc(var(--project-font-size) * 1.2 * 0.02);
  line-height: calc(var(--project-font-size) * 1.2 * 1.35);
}

p {
  font-size: calc(var(--project-font-size) * 0.8125);
  letter-spacing: calc(var(--project-font-size) * 0.8125 * 0.07);
  line-height: calc(var(--project-font-size) * 0.8125 * 1.5);
  margin: 0;
}

.navbar.driver {
  background: linear-gradient(175deg, rgb(255, 94, 99) 37%, rgb(255, 153, 102) 100%) !important;
}
.navbar.client {
  background-color: #ff9966 !important;
}
.navbar.Landing {
  background: rgba(255, 255, 255, 0.9) !important;
  backdrop-filter: blur(2px) !important;
}
@media screen and (min-width: 992px) {
  .navbar {
    height: 63px;
  }
}
.navbar .nav-link {
  color: #212529 !important;
}
.navbar .nav-link:hover .icomoon {
  opacity: 0.8;
}
.navbar .navbar-brand {
  height: 50px;
  width: 100px;
}
.navbar .navbar-brand img {
  object-fit: contain;
  height: 100%;
  width: 100%;
}

.dropdown-menu .dropdown-item.driver:active {
  background-color: #ff5e62 !important;
}
.dropdown-menu .dropdown-item.client:active {
  background-color: #ff9966 !important;
}

.nav-button {
  border-bottom: 2px solid transparent !important;
}
.nav-button:hover {
  border-bottom: 2px solid #ffd88f !important;
}
.nav-button:focus {
  font-weight: 500 !important;
  border-bottom: 2px solid #ffd88f !important;
}

.sidebar {
  background-color: #ffffff;
  padding-top: 60px;
  height: 100vh;
  width: 230px;
  position: fixed;
  transform: translateX(0px);
  transition: 0.4s transform ease-in-out;
  z-index: 1020;
}
.sidebar.hidden-sidebar {
  transform: translateX(-410px);
  transition: 0.4s transform ease-in-out;
}
@media screen and (min-width: 992px) {
  .sidebar.hidden-sidebar {
    transform: translateX(0px);
    padding-left: 8px;
  }
}
.sidebar .container-links {
  margin: 20px 0 0 5px;
  font-weight: 600;
}
.sidebar .container-links .nav-link {
  color: #000000;
  display: flex;
  font-size: 1.1rem;
  margin-bottom: 10px;
}
.sidebar .container-links .nav-link.driver.active, .sidebar .container-links .nav-link.driver:hover {
  color: #ff5e62 !important;
}
.sidebar .container-links .nav-link.client.active, .sidebar .container-links .nav-link.client:hover {
  color: #ff9966 !important;
}
.sidebar .container-links .nav-link .icomoon {
  width: 20px !important;
  height: 20px !important;
  margin-right: -5px;
}

.hamburger-menu {
  border-color: #ff5e62;
  background-color: transparent;
}
@media screen and (min-width: 992px) {
  .hamburger-menu {
    display: none !important;
  }
}
.hamburger-menu .icomoon {
  color: #fff;
}
.hamburger-menu:hover,
.hamburger-menu :hover {
  border-color: #ff5e62 !important;
  background-color: #ff5e62;
  color: white;
  transition: 0.1s all;
}
.hamburger-menu:focus {
  box-shadow: 0px 0px 10px -2px #fff;
}
.hamburger-menu :active {
  background-color: #ff5e62;
  color: #fff !important;
}
.hamburger-menu:active {
  box-shadow: 0px 0px 10px 2px #fff !important;
  background-color: #ff5e62 !important;
  border-color: transparent !important;
}

.overlay {
  position: fixed;
  top: 56px;
  left: 0;
  z-index: 1019;
  background: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 100%;
  opacity: 1;
  transition: all 0.4s;
}
@media screen and (min-width: 992px) {
  .overlay {
    display: none;
  }
}

.overlay-hidden {
  opacity: 0;
}

.sidebar-mobile {
  font-weight: 600;
}
.sidebar-mobile .nav-link {
  color: #000000;
  display: flex;
  font-size: 1.1rem;
  margin-left: 5px;
  margin-bottom: 5px;
}
.sidebar-mobile .nav-link.driver.active, .sidebar-mobile .nav-link.driver:hover {
  color: #ff5e62 !important;
}
.sidebar-mobile .nav-link.client.active, .sidebar-mobile .nav-link.client:hover {
  color: #ff9966 !important;
}
.sidebar-mobile .nav-link .icomoon {
  width: 20px !important;
  height: 20px !important;
  margin-right: -5px;
}

.footer {
  position: absolute !important;
  width: 100%;
  height: auto;
  z-index: 1018;
  background: linear-gradient(175deg, rgb(255, 94, 99) 37%, rgb(255, 153, 102) 100%) !important;
}
.footer a {
  color: black !important;
}
@media screen and (min-width: 992px) {
  .footer {
    z-index: 1030;
  }
}

.icon-footer :hover {
  -webkit-transform: translateY(-2px);
  transform: translateY(-2px);
  transition: all 0.4s ease-out 0.2s;
}

.footer-content {
  display: block;
}

.footer-content-info {
  display: flex;
  flex-basis: auto;
}
@media screen and (max-width: 768px) {
  .footer-content-info {
    display: block;
  }
}

.footer-content-info-description {
  display: flex;
  align-items: center;
}

.rrss-content {
  align-items: center;
}
@media screen and (max-width: 768px) {
  .rrss-content {
    display: block;
    padding-left: 5px !important;
  }
}

@media screen and (max-width: 768px) {
  .rrss-content-text {
    display: contents;
  }
}

.sign {
  text-align: end;
}
@media screen and (max-width: 768px) {
  .sign {
    text-align: left;
  }
}

.carousel-home {
  margin: 0 -15px;
  width: auto;
}

.carousel-show {
  position: relative;
}
.carousel-show img {
  position: absolute;
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.carousel-show:after {
  content: "";
  padding-bottom: 40%;
  display: block;
}

.btn {
  border-radius: 15px;
  font-size: 1.1rem;
  font-weight: 600;
  border-radius: 15px;
  color: #000000 !important;
  transition: opacity 0.3s ease, background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:disabled {
  background-color: #b9b9b9;
  border-color: #a2a2a2;
  pointer-events: none;
}
.btn-primary:hover, .btn-primary:focus {
  background-color: #ff4549 !important;
}
.btn-primary.outline {
  background-color: #fff;
  border: 1px solid #ff5e62;
  color: #000000;
}
.btn-primary.outline:hover, .btn-primary.outline:focus {
  background-color: #ff9194 !important;
  color: #000000 !important;
  border: 1px solid #ff5e62;
}
.btn-secondary:hover, .btn-secondary:focus {
  background-color: #ff884d !important;
  color: #000000 !important;
}
.btn-secondary.outline {
  background-color: #fff;
  border: 1px solid #ff9966;
}
.btn-secondary.outline:hover, .btn-secondary.outline:focus {
  background-color: #ffbb99 !important;
  color: #000000 !important;
  border: 1px solid #ff9966;
}
.btn-success {
  color: #000000 !important;
}
.btn-success:hover, .btn-success:focus {
  background-color: #1dbf54 !important;
}
.btn-primary-outline {
  background-color: transparent;
  border: 1px solid #ff5e62;
  color: #ff5e62;
}
.btn-primary-outline.black-text {
  color: #000000;
}
.btn-primary-outline:hover, .btn-primary-outline:focus {
  background-color: #ff5e62 !important;
  color: #000000;
}
.btn-secondary-outline {
  background-color: transparent;
  border: 1px solid #ff9966;
}
.btn-secondary-outline:hover {
  background-color: #ffe0d1 !important;
  color: black !important;
}
.btn-secondary-outline:focus {
  background-color: #ff884d !important;
  color: black !important;
}
.btn-primary-outline-text {
  background-color: transparent;
  color: #dc3545 !important;
}
.btn-primary-outline-text.black-text {
  color: #000000;
}
.btn-primary-outline-text:hover, .btn-primary-outline-text:focus {
  border: 1px solid #ff5e62;
  color: #000000;
}
.btn.btn-active.primary-outline {
  background-color: #ff5e62;
}
.btn.btn-active.secondary-outline {
  background-color: #ff9966;
}
.btn.text-black {
  color: #000000;
}
.btn.btn-logout {
  background-color: transparent !important;
  color: #000000;
  border: none;
  font-size: 1.1rem;
  font-weight: 600;
  margin: 12px 0 0 15px;
}
.btn.btn-logout.driver:hover, .btn.btn-logout.driver:focus, .btn.btn-logout.driver:active {
  background-color: transparent !important;
  color: #ff5e62 !important;
  border: none !important;
  box-shadow: none !important;
}
.btn.btn-logout.client:hover, .btn.btn-logout.client:focus, .btn.btn-logout.client:active {
  background-color: transparent !important;
  color: #ff9966 !important;
  border: none !important;
  box-shadow: none !important;
}
.btn-icon {
  padding: 0px 15px 0 0;
}
.btn-icon .icomoon {
  height: 23px !important;
  width: 23px !important;
}
.btn-transparent {
  border-radius: 50%;
  width: 37px;
}
.btn-selected_quotation {
  background-color: #ff5e62 !important;
}
.btn-picked {
  background-color: #ff9966 !important;
}
.btn-to_be_delivered {
  background-color: #ffd88f !important;
}

.btn-go-back {
  margin-bottom: 1.7rem;
  margin-right: -0.4rem;
  width: 30px !important;
}
.btn-go-back:hover {
  cursor: pointer;
}
@media screen and (max-width: 768px) {
  .btn-go-back {
    width: 20px !important;
    margin-right: 0 !important;
  }
}

.dates-filter--container .dates-filter--pickers {
  display: flex;
}

.sidebar-filter--container {
  position: fixed;
  padding: 20px 15px 0px 15px;
  right: 0;
  bottom: 0;
  z-index: 10000;
  height: 100vh;
  width: 400px;
  max-width: 90%;
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(15px);
  transition: all 0.4s ease-in-out;
  opacity: 1;
}
@media (max-width: 480px) {
  .sidebar-filter--container {
    max-width: 74%;
  }
  .sidebar-filter--container .css-1wa3eu0-placeholder {
    font-size: 12px;
  }
}
.sidebar-filter--container .form-group {
  width: 100%;
}
.sidebar-filter--container.collapsed {
  transform: translateX(550px);
  opacity: 0;
}

.dates-filter--icon {
  border: 1px solid;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px !important;
  min-width: 40px !important;
}
.dates-filter--icon:hover {
  background-color: #ff9966;
}

.location-filter .form-group {
  width: 200px;
  margin-left: 10px;
}
.location-filter .form-label {
  display: none !important;
  font-size: 14px !important;
}
@media (max-width: 768px) {
  .location-filter .location-filter {
    background: green !important;
  }
  .location-filter .form-group {
    width: 145px;
    margin-left: 5px;
  }
  .location-filter .css-1wa3eu0-placeholder {
    font-size: 13px;
  }
}

.paid-filter {
  display: flex;
  align-items: center;
}
.paid-filter .form-group {
  width: 250px;
}
.paid-filter .form-group .css-2b097c-container {
  margin-right: 15px;
  top: 8px;
}

.css-26l3qy-menu {
  z-index: 2 !important;
}

input {
  font-size: 16px;
  border-radius: 15px !important;
}
input:focus {
  border: 1px solid #212529 !important;
  box-shadow: none !important;
}

.form-label {
  font-weight: 600 !important;
  font-size: 14px !important;
}

input:invalid,
.is-invalid {
  animation: shake 300ms;
}
input:invalid:focus,
.is-invalid:focus {
  border-color: rgba(220, 53, 70, 0.3450980392) !important;
  box-shadow: 0px 0px 3px 2px rgba(220, 53, 70, 0.3450980392) !important;
}
input:invalid .css-12xdsrl-control,
input:invalid .css-52rfuw-control,
.is-invalid .css-12xdsrl-control,
.is-invalid .css-52rfuw-control {
  border-color: #dc3545;
}
input:invalid .css-12xdsrl-control:hover,
input:invalid .css-52rfuw-control:hover,
.is-invalid .css-12xdsrl-control:hover,
.is-invalid .css-52rfuw-control:hover {
  border-color: #dc3545 !important;
}
input:invalid .css-8fm3v1-control,
.is-invalid .css-8fm3v1-control {
  box-shadow: 0px 0px 3px 2px rgba(220, 53, 70, 0.3450980392) !important;
  border-color: rgba(220, 53, 70, 0.3450980392) !important;
}

.css-8fm3v1-control {
  box-shadow: none !important;
  border: 1px solid #212529 !important;
}

.css-1g6gooi input,
.css-b8ldur-Input input {
  box-shadow: none !important;
  border-radius: 0 !important;
  border: none !important;
}

@keyframes shake {
  25% {
    transform: translateX(4px);
  }
  50% {
    transform: translateX(-4px);
  }
  75% {
    transform: translateX(4px);
  }
}
textarea {
  border-radius: 15px !important;
  height: 100px !important;
}
textarea:focus {
  border: 1px solid #212529 !important;
  box-shadow: none !important;
}

.form-checkbox a,
.form-checkbox label {
  color: #212529;
  font-size: 16px !important;
}

.custom-breadcrumb .breadcrumb {
  background-color: transparent !important;
  padding: 0;
}
.custom-breadcrumb .breadcrumb-item {
  font-weight: 600;
  margin-bottom: 10px !important;
}
.custom-breadcrumb .breadcrumb-item a {
  color: #6c757d !important;
}
.custom-breadcrumb .breadcrumb-item.active {
  color: #000000 !important;
}
.custom-breadcrumb .breadcrumb-item::before {
  color: #000000 !important;
}

.react-datepicker {
  border: none !important;
  box-shadow: 0px 0px 4px 1px rgba(99, 99, 99, 0.263);
}
.react-datepicker .react-datepicker__triangle {
  border-bottom-color: #ff5e62 !important;
}
.react-datepicker .react-datepicker__year-read-view--down-arrow {
  border-top-color: #000;
}
.react-datepicker .react-datepicker__header {
  background-color: #ff5e62 !important;
  border: none !important;
}
.react-datepicker .react-datepicker__header .react-datepicker__current-month {
  color: #fff !important;
}
.react-datepicker .react-datepicker__day--selected,
.react-datepicker .react-datepicker__day--keyboard-selected {
  background-color: #ff5e62 !important;
}
.react-datepicker .react-datepicker__navigation--previous {
  border-right-color: #000000;
}
.react-datepicker .react-datepicker__navigation--previous:hover {
  border-right-color: #000000;
}
.react-datepicker .react-datepicker__navigation--next {
  border-left-color: #000000;
}
.react-datepicker .react-datepicker__navigation--next:hover {
  border-left-color: #000000;
}
.react-datepicker .react-datepicker__day--in-range {
  background-color: black;
}
.react-datepicker .react-datepicker__day--in-selecting-range {
  background-color: rgba(99, 99, 99, 0.646);
}
.react-datepicker .react-datepicker__day--in-selecting-range:hover {
  background-color: black;
}

.react-datepicker-year-header {
  padding: 5px;
}

.react-datepicker-popper {
  z-index: 2 !important;
}

.dropdown-menu {
  border: none !important;
  box-shadow: 0px 2px 4px 1px rgba(99, 99, 99, 0.263);
}

.rating {
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}
.rating .bg-rating-modal {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.35);
  width: 100vw;
  height: 100vh;
  z-index: 100000;
}
.rating .rating-detail {
  background: white;
  padding: 25px;
  border-radius: 15px;
  min-width: 350px;
}
.rating .rating-detail-info {
  padding: 10px;
  margin: 15px 0 15px 0;
  border: 1px solid #ff9966;
  border-radius: 15px;
}
.rating .icon-rounded {
  height: 60px;
  width: 60px;
  border-radius: 30px;
}
.rating .bg-orange {
  background: #ff9966;
}
.rating .bg-green {
  background: #bcf3cf;
}
.rating .bg-green:hover {
  background: #ff9966;
}
.rating .bg-gray {
  background: #c4c4c4;
}
.rating .bg-gray:hover {
  background: #ff9966;
}
.rating .selectable-smile {
  padding: 5px 10px;
  border: 1px solid #ff9966;
  border-radius: 10px;
}
.rating .selectable-smile:hover {
  background: #ff9966;
}
.rating .rating-read-only {
  padding: 5px 10px;
  border-radius: 10px;
  height: 80px;
  width: 170px;
  transition: all 0.2s linear;
}
.rating .rating-read-only p {
  font-size: 10px;
}
.rating .rating-read-only .show-on-hover {
  display: none !important;
  height: 80px;
  width: 170px;
  padding: 5px 10px;
  overflow: auto;
  transition: all 0.2s linear;
}
.rating .rating-read-only .rating-btn {
  display: none !important;
  height: 40px;
  width: 100%;
  transition: all 0.2s linear;
}
.rating .rating-read-only:hover {
  transition: all 0.2s linear;
}
.rating .rating-read-only:hover .hide-on-hover {
  display: none !important;
  transition: all 0.2s linear;
}
.rating .rating-read-only:hover .show-on-hover {
  display: block !important;
  transition: all 0.2s linear;
}
.rating .rating-read-only:hover .rating-btn {
  display: block !important;
  transition: all 0.2s linear;
}
.rating .rating-bg-hover:hover {
  background: #fff5f0;
}
.rating ::-webkit-scrollbar {
  height: 7px !important;
  width: 7px !important;
  max-width: 200px !important;
  margin: 0 10px !important;
  scroll-margin: 10px !important;
}
.rating ::-webkit-scrollbar-track {
  background: white;
  width: 7px !important;
  border-radius: 10px;
  max-width: 200px !important;
}
.rating ::-webkit-scrollbar-track:hover {
  background: #ffefef;
}
.rating ::-webkit-scrollbar-thumb {
  background: #ff9966;
  width: 7px !important;
  border-radius: 5px;
}
.rating ::-webkit-scrollbar-thumb:hover {
  background: #ff5e62;
}

.google-map {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  border-radius: 20px;
  overflow: hidden;
}
.google-map .map-controls {
  position: absolute;
  width: 300px;
  bottom: 50px;
  padding: 5px;
  border-radius: 10px;
  z-index: 1;
  background-color: white;
}
.google-map .map-controls input {
  border: 1px solid #ff9966;
}
.google-map .map-controls .btn {
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.view-map-button {
  background: #c4c4c4;
  min-height: 40px;
  min-width: 40px;
  margin-top: 25px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 767px) {
  .view-map-button {
    margin-top: -5px;
    margin-bottom: 10px;
  }
}

.view-map-button-active {
  background-color: #ff5e62;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.driver-dashboard .icon-box {
  width: 68px !important;
}
.driver-dashboard .dotted-line {
  position: absolute;
  margin-top: 2px;
  height: 20px;
  border-left: 3px dotted #ff9966 !important;
}
.driver-dashboard .rdt_TableRow:hover {
  border: 2px solid #ff9966 !important;
  background: #fff5f0;
  transition: all 0.3s linear;
}
.driver-dashboard .icon-primary {
  color: #ff9966;
}
.driver-dashboard .driver-route-box {
  padding: 0 10px;
  border-radius: 24px;
  background: #ffe0d1;
  min-width: 215px;
}
.driver-dashboard .driver-route-cell {
  display: flex !important;
  align-items: flex-start;
  padding-left: 15px;
}
@media (max-width: 768px) {
  .driver-dashboard .rdt_TableCell:nth-child(5) {
    position: absolute;
    left: 120px !important;
    top: 10px;
  }
}

.dt-client .rdt_TableCell {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0;
  padding: 0;
  min-width: 68px;
}
@media (max-width: 768px) {
  .dt-client .rdt_TableCell:first-child {
    margin-bottom: -10px !important;
  }
  .dt-client .rdt_TableCell:nth-child(2) {
    padding-top: 10px;
    padding-left: 0px !important;
    margin-bottom: -5px !important;
  }
  .dt-client .rdt_TableCell:nth-child(3) {
    padding-left: 15px;
    padding-bottom: 0px;
  }
  .dt-client .rdt_TableCell:nth-child(4) {
    padding-bottom: 10px;
  }
}
.dt-client .car-box {
  width: 69px;
  background: #bcf3cf;
}
.dt-client .car-box p {
  color: black;
}
.dt-client .icon-box {
  width: 70px !important;
}
.dt-client .icon-box.bg-green {
  background: #bcf3cf !important;
}
.dt-client .icon-box.bg-orange {
  background: #ffe0d1 !important;
}
.dt-client .icon-box.bg-yellow {
  background: #fdebc2 !important;
}
.dt-client .icon-box.bg-disabled {
  background: #c4c4c4 !important;
}
.dt-client .icon-box.bg-expired {
  background: #f0b3b4 !important;
}
.dt-client .quotations-box {
  border-radius: 15px;
}
.dt-client .quotations-box.bg-disabled {
  background: #c4c4c4 !important;
}
.dt-client .quotations-box.bg-orange {
  background: #ffe0d1 !important;
}
.dt-client .quotations-box.bg-green {
  background: #bcf3cf !important;
}
.dt-client .quotations-box.bg-yellow {
  background: #fdebc2 !important;
}
.dt-client .quotations-box.bg-expired {
  background: #f0b3b4 !important;
}

.days-used {
  font-size: 1.1rem;
}
@media screen and (max-width: 600px) {
  .days-used {
    font-size: 1rem;
  }
}

.title-name {
  font-size: 2.1rem;
}
@media screen and (max-width: 600px) {
  .title-name {
    font-size: 1.8rem;
  }
}

.content-info-boxs {
  display: flex;
  gap: 20px;
}

.dotted-line {
  position: absolute;
  margin-top: 2px;
  height: 20px;
  border-left: 3px dotted #ff9966 !important;
}
.dotted-line.color-primary {
  border-color: #ff5e62;
}

.table-card-tittle {
  font-weight: 700 !important;
  font-size: 18px;
  margin-bottom: 2px !important;
}

.opportunity-tag {
  font-size: 0.55rem;
  text-align: center;
  letter-spacing: 0.1px;
  line-height: 1.2;
}
@media (max-width: 768px) {
  .opportunity-tag {
    font-size: 1.3rem;
    margin-left: 10px;
  }
}

.hover-box {
  border-radius: 20px;
}
.hover-box:hover {
  background-color: #ffcfd0;
  transition: all 0.5s;
  cursor: pointer;
}

.data-table .text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 170px;
}
.data-table .is-today {
  position: absolute !important;
  padding: 5px 5px;
  background: #f8be35;
  border-radius: 20px;
  font-weight: bold;
  width: 70px;
}
.data-table .rdt_Table {
  padding: 10px 10px 20px 10px;
}
@media screen and (max-width: 768px) {
  .data-table.index-view .rdt_Table {
    padding-top: 0 !important;
  }
}
.data-table .rdt_TableRow {
  margin: 10px 0;
  min-height: 95px;
  min-width: 900px;
  overflow: hidden;
  border-radius: 15px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  justify-content: flex-start;
  border: 0.1px solid rgba(0, 0, 0, 0) !important;
  transition: all 0.2s linear;
  cursor: pointer;
}
.data-table .rdt_TableRow .img-container {
  width: 60px !important;
  height: 60px !important;
  background-image: url(app/assets/images/image-no-available.svg);
  background-size: contain;
  border-radius: 12px;
  overflow: hidden;
}
.data-table .rdt_TableRow .card-img {
  min-width: 130%;
  min-height: 100%;
}
.data-table .rdt_TableRow .heart-box {
  border-radius: 30px;
  background: #ffd88f;
}
.data-table .rdt_TableCell {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0;
  padding: 0;
  min-width: 75px;
}
@media screen and (max-width: 768px) {
  .data-table .rdt_TableCell {
    width: 100%;
  }
}
.data-table .rdt_TableHeadRow {
  display: none;
}
.data-table .default-styles-dt .eAmuWF:not(:last-of-type) {
  border-bottom-width: 0;
}
.data-table .score-box {
  height: 28px;
  background: #bcf3cf;
  padding: 5px 5px 7px 1px;
  min-width: 55px;
  border-radius: 14px;
  font-weight: bold;
}
.data-table .info-card {
  min-height: 100px;
  border: 1px solid #ffe0d1;
  border-radius: 20px;
  transition: all 0.5s;
}
.data-table .info-card.driver {
  border: 1px solid #ffcfd0;
}
.data-table .info-card .icon-background-circle {
  padding: 5px;
  border-radius: 50%;
  background: #ffe0d1;
  margin: auto 20px;
}
.data-table .info-card .icon-background-circle.driver {
  background: #ffcfd0;
}
@media (max-width: 768px) {
  .data-table .text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 300px;
  }
  .data-table .icon-box {
    width: 100% !important;
    height: 50px !important;
  }
  .data-table .client-item-cell {
    flex-direction: row;
  }
  .data-table .rdt_TableRow {
    display: flex;
    min-width: 300px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0;
  }
  .data-table .rdt_TableRow .driver-route-cell {
    border-bottom: 1.5px solid #dee2e6;
    width: 100%;
    margin: 0 !important;
    padding: 10px 15px;
  }
  .data-table .rdt_TableRow .heart-box {
    margin: 0 !important;
  }
  .data-table .rdt_TableRow .name-driver-item-cell {
    flex-direction: row;
  }
  .data-table .rdt_TableRow .rdt_TableHeadRow {
    display: none;
  }
  .data-table .rdt_TableRow .rdt_TableCell:first-child {
    margin: 0;
    justify-content: flex-start;
  }
  .data-table .rdt_TableCell:first-child {
    width: 100%;
    justify-content: center;
  }
  .data-table .rdt_Table {
    overflow: hidden;
  }
}

.bg-special-offer {
  background: #ebfff2 !important;
}

.jmVaaR {
  margin-right: 20px;
  color: black;
  font-weight: 500;
}

.iuhpgH {
  margin-left: auto !important;
  transform: translateX(107px);
  color: black !important;
  font-weight: 600 !important;
}

@media (max-width: 599px) {
  .jygXwu {
    margin: 0px;
    color: black;
  }
}
@media (min-width: 599px) {
  .jygXwu {
    color: black;
  }
  .rdt_Pagination {
    margin-top: 5px;
  }
  #pagination-previous-page,
  #pagination-first-page {
    right: 80px;
    margin: 0 5px;
  }
  #pagination-next-page,
  #pagination-last-page {
    margin: 0 5px;
  }
}
.dt-client-shippments .dotted-line {
  position: absolute;
  margin-top: 2px;
  height: 20px;
}
.dt-client-shippments .dotted-line.bg-green {
  border-left: 3px dotted #20d55e !important;
}
.dt-client-shippments .dotted-line.bg-orange {
  border-left: 3px dotted #ff9966 !important;
}
.dt-client-shippments .dotted-line.bg-disabled {
  border-left: 3px dotted #adb5bd !important;
}
.dt-client-shippments .dotted-line.bg-expired {
  border-left: 3px dotted #f0b3b4 !important;
}
.dt-client-shippments .rdt_TableRow.bg-oportunity {
  background: #ebfff2 !important;
}
.dt-client-shippments .rdt_TableRow:hover {
  cursor: pointer;
}
.dt-client-shippments .rdt_TableRow:hover.hover-green {
  border: 2px solid #bcf3cf !important;
  background: #ebfff2 !important;
}
.dt-client-shippments .rdt_TableRow:hover.hover-yellow {
  border: 2px solid #f8be35 !important;
}
.dt-client-shippments .rdt_TableRow:hover.hover-disabled {
  border: 2px solid #6b6a69 !important;
  background: #ececec !important;
}
.dt-client-shippments .rdt_TableRow:hover.bg-green {
  border: 2px solid #20d55e !important;
  background: #ebfff2 !important;
}
.dt-client-shippments .rdt_TableRow:hover.bg-orange {
  border: 2px solid #ff9966 !important;
  background: #fff5f0 !important;
}
.dt-client-shippments .rdt_TableRow:hover.bg-disabled {
  border: 2px solid #6b6a69 !important;
  background: #ececec !important;
}
.dt-client-shippments .rdt_TableRow:hover.bg-expired {
  border: 2px solid #dc3545 !important;
  background: #ffe7e7 !important;
}
.dt-client-shippments .rdt_TableRow:hover .icon-box.bg-disabled {
  background: #c4c4c4 !important;
}
.dt-client-shippments .icon-primary.bg-green {
  color: #20d55e;
}
.dt-client-shippments .icon-primary.bg-orange {
  color: #ff9966;
}
.dt-client-shippments .icon-primary.bg-disabled {
  color: #6c757d;
}
.dt-client-shippments .icon-primary.bg-expired {
  color: #f0b3b4;
}
.dt-client-shippments .rdt_TableCell:nth-child(5) {
  padding-right: 15px;
  justify-content: flex-end;
  max-width: 120px;
}

@media (max-width: 768px) {
  .dt-client-shippments .icon-box {
    display: flex !important;
    align-content: flex-start !important;
    justify-content: flex-start !important;
    padding-left: 10px;
    width: 100% !important;
    flex-direction: row !important;
  }
  .dt-client-shippments .rdt_TableRow {
    display: flex;
    align-items: flex-start;
  }
  .dt-client-shippments .rdt_TableCell:first-child {
    margin-top: 15px;
  }
  .dt-client-shippments .rdt_TableCell:nth-child(2) {
    margin-top: 18px;
    padding-left: 0px;
  }
  .dt-client-shippments .rdt_TableCell:nth-child(3) {
    padding-left: 12px;
    margin-top: 13px;
  }
  .dt-client-shippments .rdt_TableCell:nth-child(4) {
    justify-content: center;
  }
  .dt-client-shippments .rdt_TableCell:nth-child(5) {
    margin-top: -10px;
    padding-bottom: 6px;
    width: 100%;
    max-width: 100%;
    justify-content: center;
  }
  .quotations .rdt_TableCell:first-child {
    margin-top: 18px !important;
  }
  .quotations .rdt_TableCell:nth-child(2) {
    margin-top: 10px !important;
    padding-left: 12px !important;
  }
  .quotations .rdt_TableCell:nth-child(3) {
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}
.shippments-done .rdt_TableRow {
  padding-left: 15px;
}

.dt-driver-next-shipping .rdt_TableCell:nth-child(4) {
  display: flex;
  justify-content: flex-end;
  margin-right: 15px;
}
.dt-driver-next-shipping .rdt_TableCell:nth-child(5) {
  position: absolute;
  right: 20px;
}
.dt-driver-next-shipping .rdt_TableCell:first-child {
  border-right: 1px solid #c4c4c4;
  margin-right: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
  min-width: 340px;
}
@media (max-width: 768px) {
  .dt-driver-next-shipping .rdt_TableRow {
    display: flex;
    align-items: flex-start;
  }
  .dt-driver-next-shipping .rdt_TableCell:first-child {
    justify-content: flex-start;
    align-items: flex-start;
    border-right: none;
    margin: 0;
  }
  .dt-driver-next-shipping .rdt_TableCell:last-child {
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 0;
    padding: 0px;
  }
  .dt-driver-next-shipping .icon-box {
    display: flex !important;
    align-content: flex-start !important;
    justify-content: flex-start !important;
    padding-left: 20px;
  }
  .dt-driver-next-shipping .rdt_TableCell:nth-child(2) {
    margin-top: 20px;
    margin-bottom: 10px !important;
    padding-left: 15px;
    margin-bottom: 10px;
    width: 100% !important;
  }
  .dt-driver-next-shipping .rdt_TableCell:nth-child(3) {
    padding-left: 7px;
    margin-bottom: -15px;
  }
  .dt-driver-next-shipping .rdt_TableCell:nth-child(4) {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
  }
  .dt-driver-next-shipping .rdt_TableCell:nth-child(5) {
    position: relative;
    top: -180px;
    right: -10px !important;
  }
}

.dt-driver-offers .driver-item-cell {
  margin-bottom: 0 !important;
}
.dt-driver-offers .rdt_TableCell:nth-child(2) {
  min-width: 400px;
}
.dt-driver-offers .rdt_TableCell:nth-child(5) {
  position: absolute;
  right: 20px;
}
.dt-driver-offers .view-offer {
  display: none;
}
.dt-driver-offers .view-offer .btn {
  color: black;
  width: 150px;
}
.dt-driver-offers .rdt_TableRow:hover .view-offer {
  background: linear-gradient(-90deg, white, white, transparent);
  position: absolute;
  top: 0;
  right: 0;
  width: 550px !important;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 15px;
  height: 100%;
}
@media (max-width: 768px) {
  .dt-driver-offers .rdt_TableRow:hover .view-offer {
    display: none;
  }
}
@media (max-width: 768px) {
  .dt-driver-offers .rdt_TableCell:nth-child(2) {
    min-width: 300px;
  }
  .dt-driver-offers .rdt_TableCell:nth-child(2) {
    margin: 10px 15px;
  }
  .dt-driver-offers .driver-item-cell {
    margin-bottom: 20px !important;
  }
  .dt-driver-offers .rdt_TableCell:nth-child(5) {
    height: 35px;
    width: 70px;
    right: 20px;
    top: 20px;
  }
}

.dt-driver-offers-dashboard .driver-item-cell {
  margin-bottom: 0 !important;
}
.dt-driver-offers-dashboard .dotted-line.bg-disabled {
  border-left: 3px dotted #20d55e !important;
}
.dt-driver-offers-dashboard .quotations-box {
  background: #20d55e !important;
}
.dt-driver-offers-dashboard .quotations-box.bg-disabled {
  background: #bcf3cf !important;
}
.dt-driver-offers-dashboard .bg-green .quotations-box {
  background: #20d55e !important;
}
.dt-driver-offers-dashboard .icon-primary.bg-disabled {
  color: #20d55e !important;
}
.dt-driver-offers-dashboard .rdt_TableCell:nth-child(5) {
  position: absolute;
  right: 20px;
}
.dt-driver-offers-dashboard .view-offer {
  display: none;
}
.dt-driver-offers-dashboard .view-offer .btn {
  color: black;
  width: 150px;
}
.dt-driver-offers-dashboard .rdt_TableRow:hover .view-offer {
  background: linear-gradient(-90deg, white, white, transparent);
  position: absolute;
  top: 0;
  right: 0;
  width: 550px !important;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 15px;
  height: 100%;
}
@media (max-width: 768px) {
  .dt-driver-offers-dashboard .rdt_TableRow:hover .view-offer {
    display: none;
  }
}
@media (max-width: 768px) {
  .dt-driver-offers-dashboard .rdt_TableCell:nth-child(2) {
    margin: 10px 15px;
  }
  .dt-driver-offers-dashboard .driver-item-cell {
    margin-bottom: 20px !important;
  }
  .dt-driver-offers-dashboard .rdt_TableCell:nth-child(5) {
    height: 35px;
    width: 70px;
    right: 20px;
    top: 20px;
  }
}

.dt-driver-special-offers {
  margin-bottom: 40px;
  padding: 15px 20px 0px 20px !important;
}
.dt-driver-special-offers .rdt_TableRow {
  background: #ebfff2 !important;
  box-shadow: none !important;
}
.dt-driver-special-offers .icon-primary {
  color: #20d55e;
}
.dt-driver-special-offers .dotted-line {
  margin-top: 2px;
  height: 20px;
  border-left: 3px dotted #20d55e !important;
}
.dt-driver-special-offers .rdt_TableRow:hover {
  border: 2px solid #20d55e !important;
  background: #bcf3cf;
  transition: all 0.3s linear;
}
.dt-driver-special-offers .icon-box {
  background: #20d55e;
}
.dt-driver-special-offers .quotations-box {
  background: #20d55e;
  padding: 5 8px;
  border-radius: 24px;
}
.dt-driver-special-offers .rdt_TableCell:last-child {
  position: absolute;
  right: 20px;
}
@media (max-width: 768px) {
  .dt-driver-special-offers .icon-box {
    display: flex !important;
    align-content: flex-start !important;
    justify-content: flex-start !important;
    padding-left: 10px;
    flex-direction: row !important;
  }
  .dt-driver-special-offers .rdt_TableRow {
    display: flex;
    align-items: flex-start;
  }
  .dt-driver-special-offers .rdt_TableCell:first-child {
    background-color: #20d55e;
  }
  .dt-driver-special-offers .rdt_TableCell:nth-child(2) {
    margin-top: 15px;
    margin-bottom: 10px;
    padding-left: 12px;
    width: 100%;
  }
  .dt-driver-special-offers .rdt_TableCell:nth-child(3) {
    padding-left: 11px;
    margin-bottom: -10px;
  }
  .dt-driver-special-offers .rdt_TableCell:nth-child(4) {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
  }
  .dt-driver-special-offers .rdt_TableCell:nth-child(5) {
    width: 50px !important;
    height: 50px !important;
    display: flex;
    justify-content: flex-end;
    position: relative;
    top: -225px !important;
    left: 210px !important;
    padding: 0 !important;
  }
}

.dt-driver-trips .driver-route-box {
  padding: 0 10px;
  border-radius: 24px;
  background: #ffe0d1;
  height: 35px;
  min-width: 215px;
  justify-content: center !important;
}
.dt-driver-trips .rdt_TableRow {
  padding: 0 20px 0 10px;
}
.dt-driver-trips .rdt_TableRow:hover {
  border: 2px solid #ff9966 !important;
  background: #fff5f0 !important;
  transition: all 0.3s linear;
}
.dt-driver-trips .rdt_TableCell:nth-child(1) {
  min-width: 225px;
}
.dt-driver-trips .rdt_TableCell:nth-child(4) {
  justify-content: center;
}
.dt-driver-trips .rdt_TableCell:nth-child(5) {
  justify-content: end;
}
@media (max-width: 768px) {
  .dt-driver-trips .rdt_TableCell:nth-child(2) {
    justify-content: center;
    margin-top: 10px;
  }
  .dt-driver-trips .rdt_TableCell:nth-child(3) {
    justify-content: center;
    margin-top: 10px;
  }
  .dt-driver-trips .rdt_TableCell:nth-child(4) {
    margin-top: 10px;
  }
  .dt-driver-trips .rdt_TableCell:nth-child(4) p {
    background: #ffd88f;
    padding: 5px 20px;
    border-radius: 15px;
  }
  .dt-driver-trips .rdt_TableCell:nth-child(5) {
    justify-content: center;
    margin-bottom: 10px;
  }
  .dt-driver-trips .driver-route-box {
    padding: 0 10px;
    border-radius: 24px;
    background: #ffe0d1;
    height: 35px;
    width: 100%;
  }
}

.quotation-table .driver-comment {
  display: block;
}
.quotation-table .select-offer {
  position: absolute;
  display: none;
}
.quotation-table .dotted-line {
  position: absolute;
  margin-top: 2px;
  height: 20px;
  border-left: 3px dotted #20d55e !important;
}
.quotation-table .score-box {
  height: 28px;
  background: #ffd88f !important;
  padding: 5px;
  margin-left: 10px;
  border-radius: 14px;
  font-weight: bold;
}
.quotation-table .rdt_TableRow {
  padding: 0 10px;
  min-height: 95px;
  min-width: 900px;
  height: 60px !important;
}
.quotation-table .rdt_TableRow:hover {
  border: 2px solid #20d55e !important;
  background: linear-gradient(-90deg, #bcf3cf, white, white);
  cursor: pointer;
}
.quotation-table .rdt_TableRow:hover .icon-box {
  background: #20d55e !important;
}
.quotation-table .rdt_TableRow:hover .select-offer {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 1600px;
  height: 100%;
}
.quotation-table .rdt_TableRow:hover .driver-comment {
  display: none;
  transition: all 0.3s linear !important;
}
.quotation-table .icon-primary {
  color: #20d55e;
}
.quotation-table .rdt_TableCell:nth-child(1) .score-box {
  margin-left: auto;
}
.quotation-table .rdt_TableCell:nth-child(3) {
  text-align: end;
}
.quotation-table .rdt_TableCell:nth-child(3) {
  display: flex;
  justify-content: flex-end;
  text-align: end;
}
.quotation-table .rdt_TableCell:nth-child(4) {
  position: absolute;
  right: 20px;
}

@media (max-width: 768px) {
  .quotation-table .rdt_TableRow {
    flex-direction: row;
  }
  .quotation-table .rdt_TableCell:nth-child(4) {
    position: absolute;
    left: 90%;
    top: 20px;
  }
}
.solicitude-quotation-table .driver-comment {
  display: block;
}
.solicitude-quotation-table .select-offer {
  position: absolute;
  display: none;
}
.solicitude-quotation-table .dotted-line {
  position: absolute;
  margin-top: 2px;
  height: 20px;
  border-left: 3px dotted #20d55e !important;
}
.solicitude-quotation-table .score-box {
  height: 28px;
  background: #ffd88f !important;
  padding: 5px;
  margin-left: 10px;
  border-radius: 14px;
  font-weight: bold;
}
.solicitude-quotation-table .rdt_TableRow {
  padding: 0 10px;
  min-height: 95px;
  min-width: 900px;
  height: 60px !important;
  flex-direction: row;
}
.solicitude-quotation-table .rdt_TableRow:hover {
  border: 2px solid #20d55e !important;
  background: linear-gradient(-90deg, #bcf3cf, white, white);
  cursor: pointer;
}
.solicitude-quotation-table .rdt_TableRow:hover .icon-box {
  background: #20d55e !important;
}
.solicitude-quotation-table .rdt_TableRow:hover .select-offer {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 1600px;
  height: 100%;
}
.solicitude-quotation-table .rdt_TableRow:hover .driver-comment {
  display: none;
  transition: all 0.3s linear !important;
}
.solicitude-quotation-table .icon-primary {
  color: #20d55e;
}
.solicitude-quotation-table .rdt_TableCell:nth-child(1) .score-box {
  margin-left: auto;
}
.solicitude-quotation-table .rdt_TableCell:nth-child(2) {
  justify-content: center;
}
.solicitude-quotation-table .rdt_TableCell:nth-child(3) {
  display: flex;
  justify-content: flex-end;
}
.solicitude-quotation-table .rdt_TableCell:nth-child(4) {
  display: flex;
  justify-content: center;
}
.solicitude-quotation-table .rdt_TableCell:nth-child(5) {
  display: flex;
  justify-content: flex-end;
}

@media (max-width: 768px) {
  .quotation-table .rdt_TableRow {
    display: flex;
    flex-direction: row;
  }
  .quotation-table .rdt_TableCell:nth-child(4) {
    position: absolute;
    left: 90%;
    top: 20px;
  }
}
.question-and-answers form {
  padding: 10px;
  margin: 0 !important;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.question-and-answers .form-group {
  width: 100%;
}
.question-and-answers textarea {
  font-size: 16px;
  padding: 15px;
  border-radius: 15px !important;
  border: 1px solid gray;
  width: 100% !important;
}
.question-and-answers .person-box {
  border-radius: 20px;
  background: #ff9966;
  height: 40px;
  width: 40px;
}
.question-and-answers .question {
  border-radius: 15px;
  padding: 5px 15px 5px 0;
}
.question-and-answers .answer {
  border-radius: 15px;
  padding: 5px 0px 5px 15px;
}
.question-and-answers .max-width {
  width: 85%;
}
.question-and-answers .btn {
  font-size: 16px;
}
.question-and-answers .small-font-driver {
  font-size: 11px;
  color: #ff5e62;
}
.question-and-answers .small-font-client {
  font-size: 11px;
  color: #ff9966;
}
.question-and-answers .line-space {
  white-space: pre-line;
}

.containerSpinnerLoad {
  position: fixed;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100% - 330px);
  height: 60vh;
}
.containerSpinnerLoad .spinner-border {
  width: 2.5rem;
  height: 2.5rem;
  border-width: 0.35rem;
}
@media screen and (max-width: 991px) {
  .containerSpinnerLoad {
    width: calc(100% - 3.5rem);
  }
  .containerSpinnerLoad .spinner-border {
    width: 2rem;
    height: 2rem;
    border-width: 0.25rem;
  }
}

.spinner-datatable {
  height: 70vh !important;
}
.spinner-datatable.no-header {
  margin-top: -5rem;
}

.spinner-offers {
  margin-top: -5rem;
}

.bg-opacity {
  background-color: rgb(255, 255, 255);
  opacity: 0.5;
}

.modal-content {
  border-radius: 12px;
  border: none;
  padding: 15px 10px;
  box-shadow: 0px 4px 19px rgba(0, 0, 0, 0.25);
}
.modal-content .modal-body {
  padding: 0;
}
.modal-content .modal-header {
  border-bottom: none;
  padding: 0 1rem;
}
.modal-content .modal-header .close {
  opacity: 1;
}
.modal-content .modal-header .close span {
  color: #000000 !important;
}
.modal-content .modal-footer {
  border-top: none;
  justify-content: center;
  flex-wrap: nowrap;
}
.modal-content .modal-footer button {
  width: 100%;
}

@keyframes opacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.opacity-animation {
  animation: opacity 0.2s ease-in-out;
}

@keyframes top {
  0% {
    opacity: 0;
    transform: translateY(-500px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.top-animation {
  animation: top 0.4s ease;
}

@keyframes bottom {
  0% {
    opacity: 0;
    transform: translateY(500px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.bottom-animation {
  animation: bottom 0.4s ease;
}

@keyframes left {
  0% {
    opacity: 0;
    transform: translateX(-500px);
  }
  40% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
.left-animation {
  animation: left 0.4s ease;
}

@keyframes right {
  0% {
    opacity: 0;
    transform: translateX(500px);
  }
  40% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
.right-animation {
  animation: right 0.4s ease;
}

.notification-button {
  cursor: pointer;
}
.notification-button.badge-position {
  right: 20px;
}
.notification-button--badge-style {
  color: #fff;
  position: absolute;
  font-size: 0.9rem;
  font-weight: 900;
  width: 1.2rem;
  height: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  top: 10px;
  transform: translateX(25px);
  pointer-events: none;
  cursor: pointer;
}
.notification-button .badge-primary {
  background-color: #ff5e62;
}
.notification-button .badge-secondary {
  background-color: #ffd88f;
  color: #000;
}

.popup-menu {
  overflow-y: auto;
  color: #000;
  backdrop-filter: blur(13px);
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.216);
  position: fixed;
  top: 3.8rem;
  right: 20px;
  width: 22rem;
  border-radius: 15px;
  padding: 0.5rem 0 0 0;
  overflow: hidden;
  z-index: 99999;
  transition: all 0.3s;
  opacity: 0;
  visibility: hidden;
}
.popup-menu.show {
  opacity: 1;
  visibility: visible;
}
.popup-menu .empty-msg {
  margin-top: 0.8rem;
}
@media screen and (max-width: 390px) {
  .popup-menu {
    width: 16rem;
    right: 15px;
  }
}

.container-notification-list {
  max-height: 330px;
  min-height: 65px;
  overflow-y: auto;
}
.container-notification-list::-webkit-scrollbar {
  width: 0.6rem;
  height: 0.4rem;
}
.container-notification-list::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: rgb(255, 255, 255);
  cursor: pointer;
}
.container-notification-list::-webkit-scrollbar-thumb {
  background-color: rgba(179, 177, 177, 0.45);
  border-radius: 5px;
}
.container-notification-list .notification-list--item {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  border-bottom: 1px solid rgba(204, 204, 204, 0.108);
  width: 100%;
  padding: 0 0.8rem;
  transition: all 0.3s;
}
.container-notification-list .notification-list--item:first-child {
  margin-top: 0.4rem;
}
.container-notification-list .notification-list--item:last-child {
  border-bottom: none;
  padding-bottom: 15px !important;
}
.container-notification-list .notification-list--item.unread {
  background-color: rgba(204, 204, 204, 0.371);
  color: #000;
}
.container-notification-list .notification-list--item.hover-driver:hover {
  background-color: #ff5e62;
  color: #fff;
}
.container-notification-list .notification-list--item.hover-client:hover {
  background-color: #ff9966;
  color: #000;
}
.container-notification-list .notification-list--item.cursor-pointer {
  cursor: pointer;
}
.container-notification-list .notification-list--item.default-cursor {
  cursor: default;
}

.spinner-notification {
  margin-bottom: 21px;
}
.spinner-notification.spinner-primary {
  border-color: #ff5e62;
  border-right-color: transparent;
}
.spinner-notification.spinner-secondary {
  border-color: #ff9966;
  border-right-color: transparent;
}

.opacity-background {
  position: fixed;
  top: 66px;
  background: rgba(255, 255, 255, 0.5);
  left: 0;
  height: 100vh;
  width: 100vw;
}

.header-responsive {
  display: flex;
}

.header-button {
  margin-left: 20px;
}

.header-filters {
  margin-right: 0px;
}

@media (max-width: 768px) {
  .header-responsive {
    display: flex;
    flex-direction: column;
    align-items: flex-end !important;
    width: 100%;
  }
  .header-button {
    width: 100%;
    margin-top: 30px;
  }
  .header-filters {
    margin-right: 0px;
  }
}
.image-whit-zoom-div {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 110%;
  min-height: 100%;
  background: black;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.image-whit-zoom-div:hover {
  min-width: 115%;
  min-height: 110%;
}

.image-whit-zoom {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100% !important;
  max-width: 110% !important;
  min-height: 100% !important;
  max-width: 110% !important;
  background: black;
  cursor: pointer;
  object-fit: cover;
}

.image-whit-zoom-search {
  display: hidden;
  position: absolute;
  color: #ff9966;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 40%;
  font-size: 10px !important;
  text-align: center;
  justify-content: center;
  color: transparent;
  transition: all 0.2s ease-in-out;
}

.image-whit-zoom-search:hover {
  color: #ff9966;
}

.image-modal {
  background: rgba(0, 0, 0, 0.9);
}
.image-modal .modal-content {
  background: transparent;
}
.image-modal .close span {
  color: red !important;
}
.image-modal .modal-content .modal-header .close span {
  color: white !important;
}
.image-modal .close {
  font-size: 40px;
}

.image-with-zoom-image-size {
  max-width: 70%;
}
@media (max-width: 768px) {
  .image-with-zoom-image-size {
    max-width: 100%;
  }
}

.Toastify__toast-container {
  width: auto !important;
  min-width: 200px;
}
@media (max-width: 991px) {
  .Toastify__toast-container {
    margin-right: -20px;
  }
}
.Toastify__toast-container .Toastify__toast-body {
  font-size: 15px;
}
.Toastify__toast-container .Toastify__toast--success {
  background-color: rgba(7, 188, 13, 0.7098039216) !important;
  backdrop-filter: blur(10px);
  border-radius: 10px !important;
}
.Toastify__toast-container .Toastify__toast--error {
  background-color: rgba(231, 60, 60, 0.7294117647) !important;
  backdrop-filter: blur(10px);
  border-radius: 10px !important;
}

.landing-page {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  width: 100vw;
  height: 100vh;
  background-color: white;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.landing-page a {
  text-decoration: none;
}
.landing-page .landing-page-btn {
  text-decoration: none;
  color: #000000 !important;
  border: 1px solid transparent;
}
.landing-page .top-logo {
  position: absolute;
  top: 0;
  left: 50%;
  padding: 100px;
  transform: translate(-50%, -50%);
  z-index: 12000;
  background: #182c33;
  width: 324px;
  height: 324px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 210px;
}
.landing-page .row {
  display: flex;
  align-items: center;
  justify-content: center;
}
.landing-page .col {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.landing-page .background-image {
  width: 50%;
  height: 100%;
  margin: 0;
  padding: 0;
  background-size: cover;
  background-color: #c4c4c4;
}
.landing-page .left-content {
  background-position: 50%;
  background-blend-mode: soft-light;
  transition: all 0.5s linear;
}
.landing-page .left-content:hover, .landing-page .left-content:focus {
  background-color: #ff5e62;
  font-size: 1.2rem;
  color: #ffffff;
  background-blend-mode: soft-light;
  transition: all 0.5s linear;
}
.landing-page .left-content:hover .landing-page-btn, .landing-page .left-content:focus .landing-page-btn {
  text-decoration: none;
  color: #ffffff !important;
  border: 1px solid #ffffff;
  padding: 5px 30px;
  border-radius: 30px;
  transition: all 0.3s linear;
}
.landing-page .right-content {
  background-position: 80%;
  background-blend-mode: soft-light;
  transition: all 0.5s linear;
}
.landing-page .right-content:hover, .landing-page .right-content:focus {
  background-color: #ff9966;
  font-size: 1.2rem;
  color: #ffffff;
  background-blend-mode: soft-light;
  transition: all 0.5s linear;
}
.landing-page .right-content:hover .landing-page-btn, .landing-page .right-content:focus .landing-page-btn {
  text-decoration: none;
  color: #ffffff !important;
  border: 1px solid #ffffff;
  padding: 5px 30px;
  border-radius: 30px;
  transition: all 0.3s linear;
}

@media (max-width: 600px) {
  .landing-page {
    flex-direction: column !important;
  }
  .landing-page .top-logo {
    top: 50%;
    padding: 100px;
    transform: translate(-50%, -50%);
    background: #182c33;
    width: 150px;
    height: 150px;
  }
  .background-image {
    width: 100% !important;
    height: 50% !important;
  }
}
@media screen and (max-width: 768px) {
  #navbar-landing .navbar {
    padding: 0px 1rem !important;
  }
}

.bg-menu-landing {
  background: rgba(255, 255, 255, 0.9) !important;
  backdrop-filter: blur(2px) !important;
}
@media screen and (max-width: 991px) {
  .bg-menu-landing {
    padding: 0px 1rem 0px 1rem;
  }
}

.navbar .navbar-brand-landing {
  height: auto !important;
  width: 130px !important;
  margin-left: -20px !important;
}
@media screen and (max-width: 991px) {
  .navbar .navbar-brand-landing {
    padding: 0px;
  }
}

.navbar-landing {
  height: 60px !important;
  width: auto !important;
  padding: 0px;
  margin-left: -20px !important;
}

@media screen and (max-width: 768px) {
  #navbar-landing .nav-link {
    padding-left: 5px !important;
  }
}

@media screen and (max-width: 768px) {
  .navbar-nav {
    margin-bottom: 20px;
  }
}

.home-landing {
  top: 0;
  left: 0;
  z-index: 10000;
  background-color: white;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.home-container {
  padding: 0px;
}

.home-header-container {
  background-color: #fff5f0;
  margin: 170px 20px 25px;
  clip-path: polygon(0 0, 100% 0%, 100% 55%, 0% 100%);
  border-radius: 40px;
  padding: 120px 0px;
}
@media screen and (max-width: 768px) {
  .home-header-container {
    margin: -210px 0px 11px;
    clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 109%);
    border-radius: 40px;
    padding: 190px 0px 45px;
  }
  .home-header-container h1 {
    font-size: calc(var(--project-font-size) * 2);
    line-height: calc(var(--project-font-size) * 2 * 1.35);
  }
}
@media screen and (min-width: 768px) and (max-width: 1200px) {
  .home-header-container {
    clip-path: polygon(0 0, 100% 0%, 100% 60%, 0% 106%);
    padding: 45px 0px 55px;
    margin: 120px 20px 25px;
    clip-path: polygon(0 0, 100% 0%, 100% 55%, 0% 100%);
    border-radius: 40px;
  }
}
@media screen and (min-width: 1200px) and (max-width: 1600px) {
  .home-header-container {
    margin: 170px 75px 25px;
  }
}
@media screen and (min-width: 1600px) {
  .home-header-container {
    clip-path: polygon(0 0, 100% 0%, 100% 60%, 0% 106%);
    margin: 170px 20% 25px;
  }
}

.home-header-container__getStarted {
  padding-left: 8%;
  margin-right: 55%;
  margin-top: -2%;
}
.home-header-container__getStarted h1 {
  color: #ff9966;
}
@media screen and (max-width: 768px) {
  .home-header-container__getStarted {
    text-align: center;
    padding-right: 5%;
    margin-right: 0%;
  }
}
@media screen and (min-width: 768px) and (max-width: 1200px) {
  .home-header-container__getStarted {
    padding-right: 50%;
    margin-right: 0%;
  }
}

.home-header-container__tittle {
  padding-left: 8%;
  margin-right: 55%;
  margin-top: -2%;
}
.home-header-container__tittle h1 {
  color: #ff9966;
}
@media screen and (max-width: 768px) {
  .home-header-container__tittle {
    padding-right: 5%;
    margin-right: 0%;
  }
}
@media screen and (min-width: 768px) and (max-width: 1200px) {
  .home-header-container__tittle {
    padding-right: 50%;
    margin-right: 0%;
  }
}

.home-header-container__img {
  margin-top: -45%;
  margin-left: 45%;
}
@media screen and (max-width: 768px) {
  .home-header-container__img {
    float: none;
    margin-top: 20%;
    margin-left: 0%;
    z-index: 2;
    position: relative;
  }
}
@media screen and (min-width: 768px) and (max-width: 1200px) {
  .home-header-container__img {
    float: right;
    margin-top: -45%;
    margin-left: 45%;
  }
}
@media screen and (min-width: 1200px) and (max-width: 1600px) {
  .home-header-container__img {
    margin-top: -42%;
    margin-left: 45%;
  }
}
@media screen and (min-width: 1600px) {
  .home-header-container__img {
    margin-top: -22%;
    margin-left: 50%;
    margin-right: 365px;
  }
}

@media screen and (min-width: 992px) {
  .content-body {
    margin-left: 250px;
    margin-right: 20px;
  }
}

.card {
  border-radius: 40px !important;
}

.card-body {
  padding: 1.25rem 0px px;
}

.howWorks__title {
  text-align: center;
  margin-bottom: 30px;
}
@media screen and (max-width: 768px) {
  .howWorks__title {
    font-size: calc(var(--project-font-size) * 1.7);
    margin-top: 60px;
    margin-bottom: 20px;
  }
}

.howWorks__card {
  width: 95%;
  border: 0px;
  margin: auto;
}
@media screen and (max-width: 768px) {
  .howWorks__card {
    width: 100%;
    margin-bottom: 30px;
  }
}

@media screen and (min-width: 1600px) {
  #cardDrive {
    padding-left: 35%;
  }
}

@media screen and (min-width: 1600px) {
  #cardClient {
    padding-right: 35%;
  }
}

.howWorks__cardBody {
  padding: 1rem;
  border-radius: 40px;
  box-shadow: -29px -24px 211px -1px rgba(0, 0, 0, 0.02), 35px 22px 85px rgba(0, 0, 0, 0.08);
}

.howWorks__cardTitle {
  margin-bottom: 0px;
  display: flex;
}
.howWorks__cardTitle img {
  width: 70px;
  height: 70px;
}
.howWorks__cardTitle h6 {
  align-self: center;
}

.howWorks__ListGroup__border {
  border: 0px;
  border-width: 0 0 0px !important;
}

.list-group-flush > .list-group-item {
  border: 0px;
  border-width: 0 0 0px !important;
}

.Faqs__row {
  margin-top: 10%;
  margin-bottom: 15%;
}

.Faqs__card {
  width: 95%;
  border: 0px;
  margin-bottom: 10%;
  margin: auto;
  padding: 1.5rem;
  border-radius: 40px;
  background: #fff5f0;
}
@media screen and (max-width: 768px) {
  .Faqs__card {
    width: 100%;
    padding: 0rem;
  }
}
@media screen and (min-width: 768px) and (max-width: 1200px) {
  .Faqs__card {
    padding: 1rem;
  }
}
@media screen and (min-width: 1600px) {
  .Faqs__card {
    width: 65%;
    padding: 1rem;
  }
}

.Faqs__cardTitle {
  margin-bottom: 0px;
}

.faqs__cards__item {
  border-radius: 15px !important;
  border: none;
}

.card-header {
  background: #ffe0d1;
  border-bottom: none;
}
.card-header:hover {
  background: #ff9966;
  cursor: pointer;
}

.appDriver-Container {
  padding-left: -15px !important;
  padding-right: -15px !important;
}

.appDriver-Container__row {
  background-color: #ffcfd0;
  padding: 8% 0%;
  margin-bottom: -10px;
}
@media screen and (min-width: 1600px) {
  .appDriver-Container__row {
    padding: 5% 0%;
  }
}
@media screen and (max-width: 768px) {
  .appDriver-Container__row {
    margin-top: 30%;
  }
}

.appDriver-Container__content {
  margin-left: 8%;
  margin-right: 50%;
  align-items: baseline;
}
@media screen and (max-width: 768px) {
  .appDriver-Container__content {
    margin-right: 2%;
    margin-left: 2%;
  }
}
@media screen and (min-width: 1600px) {
  .appDriver-Container__content {
    margin-left: 17%;
    margin-right: 45%;
  }
}
@media screen and (min-width: 768px) and (max-width: 1200px) {
  .appDriver-Container__content {
    margin-right: 45%;
    margin-left: 5%;
  }
}

.appDriver-Container__content__img {
  display: flex;
  margin-top: 5%;
}
.appDriver-Container__content__img img {
  width: 100%;
}

.appDriver-Container__img {
  float: right;
  margin-top: -35%;
  margin-left: 50%;
}
.appDriver-Container__img img {
  width: 100%;
}
@media screen and (max-width: 768px) {
  .appDriver-Container__img {
    margin-right: 0%;
    float: none;
    margin-top: -20%;
    margin-left: 0%;
  }
  .appDriver-Container__img img {
    width: 80%;
  }
}
@media screen and (min-width: 768px) and (max-width: 1200px) {
  .appDriver-Container__img {
    float: right;
    margin-top: -45%;
    margin-left: 50%;
  }
  .appDriver-Container__img img {
    width: 100%;
  }
}
@media screen and (min-width: 1600px) {
  .appDriver-Container__img {
    margin-top: -25%;
    margin-right: 10%;
  }
}

.pink-color {
  color: #ff5e62;
}

.orange-color {
  color: #ff9966;
}

@media screen and (min-width: 1600px) {
  .pt-lg-5 {
    padding-top: 3rem !important;
  }
}

.bg-client {
  position: absolute;
  height: 100vh;
  width: 100%;
  margin: 0;
  padding: 0 !important;
  object-fit: cover;
}

.recover-password-box {
  padding: 50px 40px !important;
}

.login_box {
  border-radius: 40px;
  background: #ffffff;
  padding: 10px 40px;
  margin-bottom: 20px;
  margin-top: 20px;
}
.login_box .container-icon {
  position: relative;
  margin-bottom: -25px;
}
.login_box .container-icon.client .icomoon {
  position: absolute;
  right: 13px;
  top: -35px;
  width: 28px !important;
  height: 28px !important;
  color: #000;
}
.login_box .container-icon.client:hover,
.login_box .container-icon.client :hover {
  color: #ff9966;
  cursor: pointer;
}
.login_box .container-icon.driver .icomoon {
  position: absolute;
  right: 13px;
  top: -35px;
  width: 28px !important;
  height: 28px !important;
  color: #000;
}
.login_box .container-icon.driver:hover,
.login_box .container-icon.driver :hover {
  color: #ff5e62;
  cursor: pointer;
}
.login_box .checkbox {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 16px;
}
.login_box .checkbox p {
  margin-left: 10px;
}
.login_box .checkbox p span {
  font-weight: bold;
}
.login_box .logo {
  position: relative;
  top: -50px;
  height: 98px;
  width: 98px;
  background-color: #ffd88f;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  margin-bottom: -25px;
}
.login_box h6 {
  color: #6b6a69;
}
.login_box input {
  border-radius: 15px;
  height: 44px;
}
.login_box .login-btn {
  border-radius: 15px;
  margin-top: 30px;
}
.login_box .driver-btn {
  background: #ff5e62;
  border: 1px solid #ff5e62;
  color: #fff;
}
.login_box .client-btn {
  background: #ff9966;
  border: 1px solid #ff9966;
  color: #fff;
}
.login_box .register {
  text-decoration: none;
  margin: auto !important;
  color: #000000;
  font-weight: 600;
}
.login_box .register .register-btn {
  border-radius: 15px;
  height: 38px;
}
.login_box .register .register-btn:hover {
  cursor: pointer;
}
.login_box .register-btn-client {
  border: 1px solid #ff9966;
}
.login_box .register-btn-client:hover, .login_box .register-btn-client:focus {
  background: #ffe0d1;
  border: 1px solid #ff9966;
}
.login_box .register-btn-driver {
  border: 1px solid #ff5e62;
}
.login_box .register-btn-driver:hover, .login_box .register-btn-driver:focus {
  background: #ffcfd0;
  border: 1px solid #ff5e62;
}
.login_box .reset-password-btn {
  border-radius: 15px;
  height: 38px;
}
.login_box .reset-password-btn a {
  text-decoration: none;
  margin: auto !important;
  color: #6b6a69;
  font-weight: bold;
}
.login_box .reset-password-btn:hover.driver, .login_box .reset-password-btn:focus.driver {
  color: #ff5e62;
}
.login_box .reset-password-btn:hover.driver a, .login_box .reset-password-btn:focus.driver a {
  color: #ff5e62;
}
.login_box .reset-password-btn:hover.client, .login_box .reset-password-btn:focus.client {
  color: #ff9966;
}
.login_box .reset-password-btn:hover.client a, .login_box .reset-password-btn:focus.client a {
  color: #ff9966;
}
.login_box .form-group {
  margin-bottom: 10px;
}

.btn-back {
  position: relative;
  z-index: 1;
  display: flex;
  background-color: none;
  max-width: 250px;
  background-color: transparent !important;
  border: none;
  margin-top: 30px;
  padding: 0 0 0 10px;
}
@media screen and (min-width: 992px) {
  .btn-back {
    padding: 0 0 0 40px;
  }
}
.btn-back:hover, .btn-back:focus, .btn-back:active {
  background-color: transparent !important;
  color: #212529 !important;
  border: none !important;
  box-shadow: none !important;
}

@media (max-width: 375px) {
  .login_box {
    margin-top: 30px;
    padding: 10px 20px;
  }
  .btn-back {
    margin-top: 10px;
    margin-left: -10px;
  }
}
.steps_register_background {
  position: absolute;
  width: 100%;
  min-height: 87vh;
  top: 13vh;
  left: 0;
  background: white;
  border-radius: 50px 50px 0 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}
.steps_register_background .success-register {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: linear-gradient(175deg, rgb(255, 94, 99) 37%, rgb(255, 153, 102) 100%);
}
.steps_register_background .success-register .confetti {
  max-width: 300px;
}
.steps_register_background .steps_register_form {
  max-width: 600px;
}
.steps_register_background ::-webkit-scrollbar {
  height: 7px !important;
  width: 7px !important;
  max-width: 200px !important;
  margin: 0 10px !important;
  scroll-margin: 10px !important;
}
.steps_register_background ::-webkit-scrollbar-track {
  background: white;
  width: 7px !important;
  border-radius: 10px;
  max-width: 200px !important;
}
.steps_register_background ::-webkit-scrollbar-track:hover {
  background: #ffefef;
}
.steps_register_background ::-webkit-scrollbar-thumb {
  background: #ff9966;
  width: 7px !important;
  border-radius: 5px;
}
.steps_register_background ::-webkit-scrollbar-thumb:hover {
  background: #ff5e62;
}
.steps_register_background .verticalScrollable {
  overflow-y: scroll;
  height: 75vh;
}
.steps_register_background .steps_register_status_num {
  background: #ff9966;
  height: 40px;
  width: 40px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.steps_register_background .active_num {
  background: #ff5e62;
  cursor: pointer;
}
.steps_register_background .active_num:hover {
  background: #ff5e62;
}
.steps_register_background .steps_register_status_line {
  width: 40px;
  height: 1px;
  margin: 0 5px;
}
.steps_register_background .solid_line {
  border-bottom: 3px solid #ff5e62;
}
.steps_register_background .dotted_line {
  border-bottom: 3px dotted #ff5e62;
}
.steps_register_background .steps_register {
  padding: 0 20px;
  margin-bottom: 20px;
  z-index: 100;
}
.steps_register_background .steps_register h6 {
  color: #6b6a69;
}
.steps_register_background .steps_register label {
  margin-bottom: 0.3rem;
}
.steps_register_background .steps_register input {
  border-radius: 15px;
  height: 44px;
}
.steps_register_background .steps_register .login-btn {
  border-radius: 15px;
  margin-top: 30px;
}
.steps_register_background .steps_register .driver-btn {
  background: #ff5e62;
  border: 1px solid #ff5e62;
  color: #fff;
}
.steps_register_background .steps_register .reset-password-btn {
  border-radius: 15px;
  height: 38px;
}
.steps_register_background .steps_register .reset-password-btn a {
  text-decoration: none;
  margin: auto !important;
  color: #6b6a69;
  font-weight: bold;
}
.steps_register_background .steps_register .reset-password-btn:hover.driver, .steps_register_background .steps_register .reset-password-btn:focus.driver {
  color: #ff5e62;
}
.steps_register_background .steps_register .reset-password-btn:hover.driver a, .steps_register_background .steps_register .reset-password-btn:focus.driver a {
  color: #ff5e62;
}
.steps_register_background .steps_register .reset-password-btn:hover.client, .steps_register_background .steps_register .reset-password-btn:focus.client {
  color: #ff9966;
}
.steps_register_background .steps_register .reset-password-btn:hover.client a, .steps_register_background .steps_register .reset-password-btn:focus.client a {
  color: #ff9966;
}

.payment-confirmation {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: linear-gradient(175deg, rgb(255, 94, 99) 37%, rgb(255, 153, 102) 100%);
}
.payment-confirmation .payment-card {
  width: 600px;
  background: white;
  border-radius: 20px;
  overflow: hidden;
}
.payment-confirmation .payment-header {
  height: 100px;
  display: flex;
  justify-content: space-between;
  background: #46759a;
  padding: 20px;
}

.expired-solicitude-header__container {
  background-color: #f0b3b4;
  display: flex;
  flex-wrap: nowrap;
  box-sizing: border-box;
  padding: 10px;
  border-radius: 15px;
  align-items: center;
}
.expired-solicitude-header__container .expired-solicitude-actions__buttons {
  margin-left: auto;
  display: flex;
}

.client-solicitude-show {
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}
.client-solicitude-show.expired {
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  border-radius: 15px;
  background-color: #dee2e6;
  padding: 12px;
}
.client-solicitude-show .total-value-box {
  margin-top: 20px;
}
.client-solicitude-show .total-value-box.paid {
  background: #bcf3cf;
}
.client-solicitude-show .items-container {
  max-height: 350px;
  overflow-y: auto;
  scrollbar-color: #ff9966 white;
  scrollbar-width: thin;
  scrollbar-gutter: revert-layer;
}
.client-solicitude-show .images-scrollable {
  overflow-x: auto;
  width: 80%;
  scrollbar-color: #ff9966 white;
  scrollbar-width: thin;
  scrollbar-gutter: revert-layer;
}
.client-solicitude-show .items-description .item-image {
  max-width: 25%;
}
.client-solicitude-show .items-description .img-container {
  width: 120px !important;
  min-width: 120px !important;
  height: 120px !important;
  border-radius: 5px;
  overflow: hidden;
  margin: 7px 5px;
}
.client-solicitude-show .items-description .card-img {
  min-width: 130%;
  min-height: 100%;
}
.client-solicitude-show .items-route {
  display: flex;
  align-items: center;
  justify-content: start;
}
.client-solicitude-show .items-route .icons-box {
  height: 120px;
  color: #ff9966;
}
.client-solicitude-show .items-route .dotted-line {
  position: absolute;
  height: 60px;
  border-left: 3px dotted #ff9966 !important;
}
.client-solicitude-show .license-description {
  min-height: 250px;
  display: flex;
  align-items: center;
  justify-content: start;
}
.client-solicitude-show .driver-description .score-box {
  height: 28px;
  background: #ffd88f;
  padding: 5px;
  margin-left: 10px;
  border-radius: 14px;
  font-weight: bold;
}
.client-solicitude-show ::-webkit-scrollbar {
  height: 7px !important;
  width: 7px !important;
  max-width: 200px !important;
  margin: 0 10px !important;
  scroll-margin: 10px !important;
}
.client-solicitude-show ::-webkit-scrollbar-track {
  background: white;
  width: 7px !important;
  border-radius: 10px;
  max-width: 200px !important;
}
.client-solicitude-show ::-webkit-scrollbar-track:hover {
  background: #ffefef;
}
.client-solicitude-show ::-webkit-scrollbar-thumb {
  background: #ff9966;
  width: 7px !important;
  border-radius: 5px;
}
.client-solicitude-show ::-webkit-scrollbar-thumb:hover {
  background: #ff5e62;
}

.total-value-box {
  padding: 10px 20px;
  border-radius: 20px;
}

.driver-solicitude-show {
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}
.driver-solicitude-show .driver-route-cell {
  width: 100%;
  margin: 0;
  padding: 0 10px 0 0;
  display: flex;
  flex-direction: column;
  flex-flow: column-reverse !important;
}
.driver-solicitude-show .driver-route-box {
  padding: 0 10px;
  border-radius: 24px;
  background: #ffe0d1;
}
.driver-solicitude-show .images-scrollable {
  overflow-x: scroll;
  width: 100%;
  scrollbar-color: #ff9966 white;
  scrollbar-width: thin;
  scrollbar-gutter: revert-layer;
}
.driver-solicitude-show .empty_img-container {
  background-color: red;
  width: 120px !important;
  min-width: 120px !important;
  height: 120px !important;
  border-radius: 5px;
  overflow: hidden;
  margin: 7px 5px;
}
.driver-solicitude-show .items-description {
  max-width: 400px;
  min-width: 350px;
  border-radius: 15px;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.2);
  border: 1px solid #ffefef;
}
.driver-solicitude-show .items-description .item-image {
  max-width: 25%;
}
.driver-solicitude-show .items-description .img-container {
  width: 120px !important;
  min-width: 120px !important;
  height: 120px !important;
  border-radius: 5px;
  overflow: hidden;
  margin: 7px 5px;
}
.driver-solicitude-show .items-description .card-img {
  min-width: 130%;
  min-height: 100%;
}
.driver-solicitude-show .status-box {
  border-radius: 20px;
}
.driver-solicitude-show .status-box .status-card .status-card--address_text {
  font-size: 16px;
  font-weight: 700;
}
.driver-solicitude-show .total-items-container {
  width: 100% !important;
  overflow-x: scroll;
}
.driver-solicitude-show ::-webkit-scrollbar {
  height: 7px !important;
  width: 7px !important;
  max-width: 200px !important;
  margin: 0 10px !important;
  scroll-margin: 10px !important;
}
.driver-solicitude-show ::-webkit-scrollbar-track {
  background: white;
  width: 7px !important;
  border-radius: 10px;
  max-width: 200px !important;
}
.driver-solicitude-show ::-webkit-scrollbar-track:hover {
  background: #ffefef;
}
.driver-solicitude-show ::-webkit-scrollbar-thumb {
  background: #ff9966;
  width: 7px !important;
  border-radius: 5px;
}
.driver-solicitude-show ::-webkit-scrollbar-thumb:hover {
  background: #ff5e62;
}
@media (max-width: 768px) {
  .driver-solicitude-show .status-box {
    min-width: 100%;
  }
}

.driver-solicitude-trip .driver-route-cell {
  width: 100%;
  margin: 0;
  padding: 0 10px 0 0;
  display: flex;
  flex-direction: column;
  flex-flow: column-reverse !important;
}
.driver-solicitude-trip .driver-route-box {
  padding: 0 10px;
  border-radius: 24px;
  background: #ffe0d1;
}
.driver-solicitude-trip .driver-item-cell {
  margin-bottom: 20px;
}
.driver-solicitude-trip .driver-item-cell .img-container {
  width: 60px !important;
  height: 60px !important;
  background: #ff9966;
  border-radius: 12px;
  overflow: hidden;
}
.driver-solicitude-trip .driver-item-cell .card-img {
  min-width: 100%;
  min-height: 100%;
}
.driver-solicitude-trip .driver-item-cell .name-driver-item-cell {
  flex-direction: row;
}
.driver-solicitude-trip .driver-item-cell .heart-box {
  border-radius: 30px;
  background: #ffd88f;
}
.driver-solicitude-trip .route-map {
  width: 100% !important;
  height: 360px;
}
.driver-solicitude-trip .route-card {
  border-radius: 20px;
  margin: 0 10px;
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  border: 2px solid transparent;
  transition: all 0.3s linear;
  cursor: pointer;
}
.driver-solicitude-trip .route-card .status-card {
  width: 100% !important;
}
@media (max-width: 768px) {
  .driver-solicitude-trip .route-card .status-box {
    min-width: 100%;
  }
  .driver-solicitude-trip .route-card .driver-item-cell {
    padding-bottom: 10px;
  }
}

.driver-solicitud-offer {
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}
.driver-solicitud-offer .offers-quotation-box {
  background: #ffe0d1;
  padding: 10px 20px;
  border-radius: 20px;
  margin-top: 40px;
}
.driver-solicitud-offer .items-container {
  max-height: 350px;
  overflow-y: scroll;
  scrollbar-color: #ff9966 white;
  scrollbar-width: thin;
  scrollbar-gutter: revert-layer;
}
.driver-solicitud-offer .images-scrollable {
  overflow-x: scroll;
  width: 80%;
  scrollbar-color: #ff9966 white;
  scrollbar-width: thin;
  scrollbar-gutter: revert-layer;
}
.driver-solicitud-offer .items-description .item-image {
  max-width: 25%;
}
.driver-solicitud-offer .items-description .img-container {
  background-size: cover;
  background-image: url(app/assets/images/image-no-available.svg);
  width: 120px !important;
  min-width: 120px !important;
  height: 120px !important;
  border-radius: 5px;
  overflow: hidden;
  margin: 7px 5px;
}
.driver-solicitud-offer .items-description .card-img {
  min-width: 130%;
  min-height: 100%;
}
.driver-solicitud-offer .items-route {
  display: flex;
  align-items: center;
  justify-content: start;
}
.driver-solicitud-offer .items-route .icons-box {
  height: 120px;
  color: #ff9966;
}
.driver-solicitud-offer .items-route .dotted-line {
  position: absolute;
  height: 60px;
  border-left: 3px dotted #ff9966 !important;
}
.driver-solicitud-offer .license-description {
  min-height: 250px;
  display: flex;
  align-items: center;
  justify-content: start;
}
.driver-solicitud-offer .driver-description .score-box {
  height: 28px;
  background: #ffd88f;
  padding: 5px;
  margin-left: 10px;
  border-radius: 14px;
  font-weight: bold;
}
.driver-solicitud-offer ::-webkit-scrollbar {
  height: 7px !important;
  width: 7px !important;
  max-width: 200px !important;
  margin: 0 10px !important;
  scroll-margin: 10px !important;
}
.driver-solicitud-offer ::-webkit-scrollbar-track {
  background: white;
  width: 7px !important;
  border-radius: 10px;
  max-width: 200px !important;
}
.driver-solicitud-offer ::-webkit-scrollbar-track:hover {
  background: #ffefef;
}
.driver-solicitud-offer ::-webkit-scrollbar-thumb {
  background: #ff9966;
  width: 7px !important;
  border-radius: 5px;
}
.driver-solicitud-offer ::-webkit-scrollbar-thumb:hover {
  background: #ff5e62;
}

.form-client-solicitude h6 {
  font-size: 1.05rem;
  letter-spacing: 0;
  color: #6b6a69;
}
.form-client-solicitude .mt-fields {
  margin-top: 2rem;
}
.form-client-solicitude .pr-fix {
  padding-right: 1.9rem;
}
.form-client-solicitude .container-fields-client-items {
  margin-bottom: 1.5rem;
}
@media screen and (min-width: 768px) {
  .form-client-solicitude .container-fields-client-items {
    margin-bottom: -0.8rem;
  }
}
.form-client-solicitude .container-fields-client-items hr {
  width: 108%;
}
@media screen and (min-width: 768px) {
  .form-client-solicitude .br-client {
    border-right: 1px dashed #ff9966;
  }
}
.form-client-solicitude .text-area-client {
  height: 100px;
}
.form-client-solicitude .card-warning {
  background-color: #fdebc2;
  border-radius: 20px;
  padding: 5px 5px 1px 15px;
}
.form-client-solicitude .card-warning .title-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.form-client-solicitude .card-warning .title-card h6 {
  color: #000000;
}
.form-client-solicitude .card-warning .body-card ul {
  margin-left: -1rem;
}
.form-client-solicitude .card-warning .body-card ul li {
  font-weight: 300;
  font-size: 14px;
}
.form-client-solicitude .card-warning .close-warning-card-icon:hover {
  cursor: pointer;
}

.mt-20 {
  margin-top: 5.5rem !important;
}

.btn-urgency:hover {
  background-color: #ff9966;
}

.card-profile {
  background: #ffffff;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.08);
  border-radius: 12px;
}
.card-profile img {
  height: 250px;
  object-fit: cover;
  width: 100%;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}
.card-profile .info {
  padding: 10px 20px 25px 20px;
}
.card-profile .info h5 {
  margin-top: 0.7rem;
  font-size: 1.5rem;
}
.card-profile .info .email {
  margin-top: 0.7rem;
  font-size: 1.1rem;
  font-weight: 600;
}
.card-profile .info .email span {
  font-weight: 400;
}
.card-profile .info .use-time {
  margin-top: 0.3rem;
  font-size: 0.9rem;
}
.card-profile .info .complete-profile {
  margin-top: 1rem;
}
.card-profile .info .complete-profile .bg-bar {
  background-color: #ffe0d1;
  margin-top: 3px;
}
.card-profile .info .complete-profile .bg-bar .bar {
  height: 5px;
  border-radius: 13px;
  width: 60%;
  background-color: #ff9966;
}

.box-star {
  display: inline-flex;
  align-items: center;
  height: 30px;
  background-color: #bcf3cf;
  border-radius: 10px;
  padding: 0 10px 0 0;
}
.box-star.driver {
  background-color: #bcf3cf;
}
.box-star span {
  margin-top: 4px;
  font-size: 1.2rem;
}
.box-star .icomoon {
  width: 18px !important;
}

.bg-description {
  margin-left: 20px;
  background-color: #fdebc2;
  padding: 30px;
  border-radius: 12px;
}
@media screen and (max-width: 767px) {
  .bg-description {
    margin-left: 0;
    margin-top: 2.8rem;
  }
}
@media screen and (max-width: 415px) {
  .bg-description {
    padding: 20px;
  }
}
.bg-description .box-description {
  background-color: #ffffff;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.08);
  padding: 20px;
  border-radius: 12px;
}
.bg-description .box-description .text-description {
  font-size: 1rem;
  margin-top: 0.5rem;
}

.box-shipping-sent {
  background: #ffffff;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.08);
  border-radius: 12px;
  margin-top: 2rem;
  padding: 20px;
}
.box-shipping-sent .shipping {
  display: flex;
  align-items: center;
}
.box-shipping-sent .shipping .bg-icon-car {
  border-radius: 50%;
  padding: 5px;
  background-color: #ff9966;
  margin-right: 10px;
}
.box-shipping-sent .shipping .bg-icon-car.driver {
  background-color: #ff5e62;
}
.box-shipping-sent .shipping h5 {
  font-size: 1.5rem;
}

.bg-session {
  margin-top: 3rem;
  padding: 0 20px;
}
.bg-session .box-session {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  color: #000000;
}
.bg-session .box-session:hover {
  text-decoration: none;
  color: #ff9966;
  cursor: pointer;
}
.bg-session .box-session.driver:hover {
  text-decoration: none;
  color: #ff5e62;
  cursor: pointer;
}
.bg-session .box-session p {
  font-size: 1.1rem;
  margin-top: 4px;
  font-weight: 600;
  margin-left: 0.5rem;
}

.text-area-profile {
  height: 100px !important;
}

.quotation-screen {
  position: fixed;
  z-index: 10000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.25) !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.quotation-screen .items-container {
  max-height: 350px;
  max-width: 370px;
  overflow-y: scroll;
  scrollbar-color: #ff9966 white;
  scrollbar-width: thin;
  scrollbar-gutter: revert-layer;
}
.quotation-screen .quotation-card {
  width: 450px;
  padding: 20px 30px;
  background: white;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}
.quotation-screen .quotation-card .quotation-screen-driver-description {
  width: 100%;
}

@media (max-width: 768px) {
  .quotation-screen {
    align-items: start;
    padding-top: 15%;
  }
  .quotation-screen .dotted-line {
    display: none;
  }
  .quotation-screen .items-container {
    max-height: 350px;
    max-width: 82vw;
    overflow-y: scroll;
    scrollbar-color: #ff9966 white;
    scrollbar-width: thin;
    scrollbar-gutter: revert-layer;
  }
  .quotation-screen .quotation-screen-driver-description {
    min-height: 350px;
    min-width: 100%;
  }
  .quotation-screen .quotation-card {
    width: 92%;
    height: 90vh;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  .quotation-screen .quotation-card .quotation-screen-driver-description {
    width: 98%;
  }
}
.form-extra--info {
  background: #fdebc2;
  border-radius: 15px;
  padding: 10px 5px 1px 15px;
}
.form-extra--info h6 {
  font-size: 1.05rem;
}
.form-extra--info ul {
  margin-left: -1rem;
}
.form-extra--info ul li {
  font-weight: 300;
  font-size: 14px;
}

.solicitude-preview--container {
  border-radius: 12px !important;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 8px;
  padding: 15px 10px;
  position: sticky;
  border: none;
  top: 5rem;
}
.solicitude-preview--container .images-scrollable {
  overflow-x: auto;
  width: 100%;
}
.solicitude-preview--container .images-scrollable::-webkit-scrollbar {
  width: 0.6rem;
  height: 0.4rem;
}
.solicitude-preview--container .images-scrollable::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: rgb(255, 255, 255);
  cursor: pointer;
}
.solicitude-preview--container .images-scrollable::-webkit-scrollbar-thumb {
  background-color: rgba(179, 177, 177, 0.31);
  border-radius: 5px;
}
.solicitude-preview--container .card-img {
  max-width: 100px;
  width: 80px;
  height: 60px;
}
.solicitude-preview--container .title-preview {
  font-size: 1.1rem;
}
.solicitude-preview--container .item-name {
  font-size: 1rem;
}
.solicitude-preview--container .min-w {
  min-width: 60px;
}

.quotation-card-modal .images-scrollable {
  overflow-x: auto;
  width: 100% !important;
  scrollbar-color: #ff9966 white;
  scrollbar-width: thin;
  scrollbar-gutter: revert-layer;
}
.quotation-card-modal .images-scrollable::-webkit-scrollbar {
  width: 0.6rem;
  height: 0.4rem;
}
.quotation-card-modal .images-scrollable::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: rgb(255, 255, 255);
  cursor: pointer;
}
.quotation-card-modal .images-scrollable::-webkit-scrollbar-thumb {
  background-color: rgba(179, 177, 177, 0.45);
  border-radius: 5px;
}
.quotation-card-modal .empty_img-container {
  background-size: contain;
  background-repeat: no-repeat;
  width: 90px;
  height: 90px !important;
  background-image: url(app/assets/images/image-no-available.svg);
  border-radius: 5px;
  margin-top: 10px;
  overflow: hidden;
}

.car-img-modal {
  width: 130px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin-left: 10px;
  border-radius: 5px;
}
.car-img-modal:first-child {
  margin-left: 0px;
}

.driver-solicitude-show {
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}
.driver-solicitude-show .driver-route-cell {
  width: 100%;
  margin: 0;
  padding: 0 10px 0 0;
  display: flex;
  flex-direction: column;
  flex-flow: column-reverse !important;
}
.driver-solicitude-show .driver-route-box {
  padding: 0 10px;
  border-radius: 24px;
  background: #ffe0d1;
}
.driver-solicitude-show .images-scrollable {
  overflow-x: scroll;
  width: 100%;
  scrollbar-color: #ff9966 white;
  scrollbar-width: thin;
  scrollbar-gutter: revert-layer;
}
.driver-solicitude-show .empty_img-container {
  background-color: red;
  width: 120px !important;
  min-width: 120px !important;
  height: 120px !important;
  border-radius: 5px;
  overflow: hidden;
  margin: 7px 5px;
}
.driver-solicitude-show .items-description {
  max-width: 400px;
  min-width: 350px;
  border-radius: 15px;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.2);
  border: 1px solid #ffefef;
}
.driver-solicitude-show .items-description .item-image {
  max-width: 25%;
}
.driver-solicitude-show .items-description .img-container {
  width: 120px !important;
  min-width: 120px !important;
  height: 120px !important;
  border-radius: 5px;
  overflow: hidden;
  margin: 7px 5px;
}
.driver-solicitude-show .items-description .card-img {
  min-width: 130%;
  min-height: 100%;
}
.driver-solicitude-show .status-box {
  border-radius: 20px;
}
.driver-solicitude-show .status-box .status-card .status-card--address_text {
  font-size: 16px;
  font-weight: 700;
}
.driver-solicitude-show .total-items-container {
  width: 100% !important;
  overflow-x: scroll;
}
.driver-solicitude-show ::-webkit-scrollbar {
  height: 7px !important;
  width: 7px !important;
  max-width: 200px !important;
  margin: 0 10px !important;
  scroll-margin: 10px !important;
}
.driver-solicitude-show ::-webkit-scrollbar-track {
  background: white;
  width: 7px !important;
  border-radius: 10px;
  max-width: 200px !important;
}
.driver-solicitude-show ::-webkit-scrollbar-track:hover {
  background: #ffefef;
}
.driver-solicitude-show ::-webkit-scrollbar-thumb {
  background: #ff9966;
  width: 7px !important;
  border-radius: 5px;
}
.driver-solicitude-show ::-webkit-scrollbar-thumb:hover {
  background: #ff5e62;
}
@media (max-width: 768px) {
  .driver-solicitude-show .status-box {
    min-width: 100%;
  }
}

.edit-driver-profile .bg-driver-bar {
  background-color: #ffcfd0;
  margin-top: 3px;
  transition: all 0.2 ease-in-out;
}
.edit-driver-profile .bg-driver-bar .bar {
  height: 5px;
  border-radius: 13px;
  transition: all 0.2 ease-in-out;
  width: 0;
  background-color: #ff5e62;
}
.edit-driver-profile .bg-description-driver ul {
  list-style: none;
  padding-left: 0.5rem;
}
@media screen and (max-width: 415px) {
  .edit-driver-profile .bg-description-driver ul {
    padding-left: 0.1rem;
  }
}
.edit-driver-profile .bg-description-driver ul li {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 3px;
}
.edit-driver-profile .bg-description-driver ul li p {
  font-weight: 700;
  font-size: 1rem;
}
.edit-driver-profile .bg-description-driver ul li p.min-width {
  min-width: 158px;
}
.edit-driver-profile .bg-description-driver ul li span {
  display: block;
  margin-left: 5px;
}
.edit-driver-profile .bg-description-driver ul li::before {
  content: "";
  background-color: #ff5e62;
  border-radius: 50%;
  display: inline-block;
  margin-right: 10px;
  height: 10px;
  min-width: 10px;
}
.edit-driver-profile .bg-description-driver .box-img-car {
  display: flex;
  gap: 10px;
  padding: 0px 10px 5px 0;
  overflow-x: auto;
  scrollbar-color: #ff9966 white;
  scrollbar-width: thin;
  scrollbar-gutter: revert-layer;
}
.edit-driver-profile .bg-description-driver .box-img-car::-webkit-scrollbar {
  width: 0.6rem;
  height: 0.4rem;
}
.edit-driver-profile .bg-description-driver .box-img-car::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: transparent;
  cursor: pointer;
}
.edit-driver-profile .bg-description-driver .box-img-car::-webkit-scrollbar-thumb {
  background-color: #dee2e6;
  border-radius: 5px;
}
.edit-driver-profile .bg-description-driver .img-car {
  min-width: 165px;
  max-width: 165px;
  height: 115px;
  border-radius: 10px;
  overflow: hidden;
}
.edit-driver-profile .bg-description-driver .content-flex {
  display: flex;
  justify-content: center;
  align-items: center;
}
.edit-driver-profile .bg-description-driver .content-license {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 115px;
}
.edit-driver-profile .bg-description-driver .content-license.empty {
  height: 60px !important;
}
@media screen and (max-width: 1199px) {
  .edit-driver-profile .bg-description-driver .content-license {
    height: 150px;
  }
  .edit-driver-profile .bg-description-driver .content-license.empty {
    height: 60px !important;
  }
}
.edit-driver-profile .bg-description-driver .box-img-license {
  display: flex;
  gap: 10px;
  padding: 0px 10px 5px 0;
  overflow-x: auto;
}
.edit-driver-profile .bg-description-driver .box-img-license::-webkit-scrollbar {
  width: 0.6rem;
  height: 0.4rem;
}
.edit-driver-profile .bg-description-driver .box-img-license::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: transparent;
  cursor: pointer;
}
.edit-driver-profile .bg-description-driver .box-img-license::-webkit-scrollbar-thumb {
  background-color: #dee2e6;
  border-radius: 5px;
}
.edit-driver-profile .bg-description-driver .box-img-license .img-license {
  min-width: 165px;
  max-width: 165px;
  height: 115px;
  object-fit: cover;
  border-radius: 10px;
}
.edit-driver-profile .container-fields-vehicle-items h6 {
  font-size: 1.1rem;
  letter-spacing: 0;
  color: #6c757d;
}
.edit-driver-profile .container-fields-vehicle-items hr {
  width: 108%;
}
.edit-driver-profile .load-btn {
  background-color: #ff9966;
  width: 40px;
  height: 40px;
}
.edit-driver-profile .loaded {
  background-color: #bcf3cf;
  width: 40px;
  height: 40px;
  cursor: pointer;
}
.edit-driver-profile .img-license-input-steps-register {
  width: 120px !important;
  height: 90px !important;
}
.edit-driver-profile .empty-img-input-steps-register {
  width: 120px !important;
  height: 90px !important;
}
.edit-driver-profile .empty-img-avatar-input-steps-register {
  height: 90px !important;
  width: 90px !important;
  border-radius: 45px !important;
}
.edit-driver-profile .img-avatar-input-steps-register {
  height: 90px !important;
  width: 90px !important;
  border-radius: 45px !important;
  border: 1px solid #c4c4c4;
}
.edit-driver-profile .certificate-text {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 230px;
}
@media screen and (max-width: 380px) {
  .edit-driver-profile .certificate-text {
    max-width: 150px;
  }
}
@media screen and (min-width: 1200px) and (max-width: 1280px) {
  .edit-driver-profile .certificate-text {
    margin-left: -30px;
  }
}

.img-license-input {
  height: 130px !important;
}

.empty-img-input {
  height: 130px !important;
}

.circle-image {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ff5e62;
  border-radius: 50%;
  width: 25px !important;
  height: 25px !important;
}

html {
  height: 100vh;
  scroll-behavior: smooth;
  scroll-padding-top: 130px;
}

body {
  margin-bottom: 60px !important;
  font-size: var(--project-font-size);
  font-family: var(--project-font-family);
}

@media screen and (min-width: 992px) {
  .content-body {
    margin-left: 250px;
    margin-right: 20px;
  }
}

a {
  color: black !important;
}

.link-primary {
  color: #ff5e62 !important;
}

.full-height {
  height: calc(100vh - 150px);
}

.contact-height {
  min-height: 88.7vh;
}

.padding-layout {
  padding: 5.5rem 1.8rem 5.5rem 0;
}
@media screen and (max-width: 991px) {
  .padding-layout {
    padding: 5.5rem 1.5rem;
  }
}

.static-page {
  justify-content: center;
  min-height: 79.5vh;
  margin-top: 70px;
  padding-bottom: 20px;
}

.custom-data-table-placeholder {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff5f0;
  height: 95px;
  width: 100%;
  margin-left: 10px !important;
  border-radius: 15px;
}

.bg-image {
  min-height: 100vh;
  min-width: 1024px;
  width: 100%;
  height: auto;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -100;
}
.bg-image.color-driver {
  background-color: #ff5e62;
}
.bg-image.color-client {
  background-color: #ff9966;
}

.project-font {
  font-weight: bold;
}

.pointer {
  cursor: pointer;
}

.shadow-black {
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.1);
}

.border-rounded {
  border-radius: 15px;
}

@media (max-width: 768px) {
  .hide-on-medium-screen {
    display: none;
  }
}
@media (max-width: 768px) {
  .hide-on-small {
    display: none;
    color: rgba(255, 255, 255, 0.7);
  }
}
@media (min-width: 768px) {
  .hide-on-lg-screen {
    display: none;
  }
}
.subtitle {
  font-size: 1.1rem;
  font-weight: 700;
}

.info-extra {
  font-size: 1.1rem;
  font-weight: 300;
}

.commission-message--container {
  background: #fdebc2;
  border-radius: 15px;
}

.cursor-pointer {
  cursor: pointer;
}

.color-primary {
  color: #ff5e62;
}

.images-scrollable::-webkit-scrollbar {
  width: 0.6rem;
  height: 0.4rem;
}
.images-scrollable::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: rgb(255, 255, 255);
  cursor: pointer;
}
.images-scrollable::-webkit-scrollbar-thumb {
  background-color: rgba(179, 177, 177, 0.45);
  border-radius: 5px;
}

.avatar_profile_img {
  height: 40px;
  width: 40px;
  min-width: 40px;
  min-height: 40px;
  border-radius: 20px;
  overflow: hidden;
  border: 1px solid #c4c4c4;
  margin-right: 10px;
  margin-left: 5px;
}
.avatar_profile_img img {
  min-width: 120%;
  min-height: 100%;
}

#basic-nav-dropdown {
  display: flex;
  align-items: center;
}

.nokerning-text {
  letter-spacing: -0.5px !important;
  font-weight: 500;
  font-size: larger;
}

.icon-dashboard {
  padding: 12px !important;
}

.font-18 {
  font-size: 18px !important;
}

.font-30 {
  font-size: 30px !important;
}

p {
  letter-spacing: 0.03px !important;
}

.hover-wsp:hover {
  background: #20d55e !important;
}

.text-btn {
  border-radius: 15px;
  cursor: pointer;
  padding: 5px 10px;
  width: 400px;
  color: #ff5e62;
}
@media (max-width: 768px) {
  .text-btn {
    width: 100% !important;
  }
}
.text-btn:hover, .text-btn:focus {
  color: #ff5e62 !important;
  background: #ffefef;
}
.text-btn:hover a, .text-btn:focus a {
  color: #ff5e62 !important;
}

.make-offer-btn {
  width: 400px;
}
@media (max-width: 768px) {
  .make-offer-btn {
    width: 100%;
  }
}

.state-box {
  background: #ffcfd0;
  width: 100%;
  padding: 10px 20px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
}

.car-logo {
  height: 80px !important;
  width: 80px;
  border-radius: 40px;
  background: #ffcfd0;
}

.img-avatar-modal {
  width: 45px !important;
  height: 45px !important;
  overflow: hidden;
  margin-bottom: 15px;
  margin-left: 15px;
  margin-right: 6px;
  border-radius: 50%;
}

.terms-check-box :first-child {
  justify-content: flex-start !important;
  align-items: flex-start !important;
}
.terms-check-box a {
  margin-left: 25px;
}

.pac-container {
  z-index: 1000000;
}

.without-style-link {
  color: black;
  text-decoration: none;
}
.without-style-link:hover {
  color: black;
  text-decoration: none;
}

.new-trip-form .input-group-text {
  display: flex;
  align-items: center;
  padding: 0 !important;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: none !important;
  border: none !important;
  border-radius: 0.25rem;
  width: 20px !important;
  height: 20px !important;
}

.gray-text {
  color: #6b6a69;
}

.icon-heart {
  height: 30px;
  width: 30px;
  padding: 5px;
  border-radius: 15px;
  background: #ffd88f;
}

.text-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: auto;
  max-width: 80%;
}

.tooltip-index .tooltip-inner {
  text-align: left !important;
  padding-left: 12px;
}

.driver-car-box {
  border-radius: 16px;
  height: 32px !important;
  width: 32px !important;
  background: #ff5e62;
  min-width: 32px !important;
}

.driver-car-box-question-and-answers {
  border-radius: 20px;
  height: 40px !important;
  width: 40px !important;
  background: #ff5e62;
  min-width: 40px !important;
}

.location-car-box {
  height: 40px;
  width: 40px;
  border-radius: 20px;
  background: #ff9966;
  cursor: pointer;
}

.location-car-box:hover {
  background: #ff5e62;
}

.tab-filter-container {
  overflow: auto;
  white-space: nowrap;
  padding: 4px;
  margin-right: -35px;
}
.tab-filter-container::-webkit-scrollbar {
  width: 0.6rem;
  height: 0.4rem;
}
.tab-filter-container::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: rgb(255, 255, 255);
  cursor: pointer;
}
.tab-filter-container::-webkit-scrollbar-thumb {
  background-color: #ececec;
  border-radius: 5px;
}

.tab-filter-button {
  height: 40px;
  min-width: 120px;
  border-radius: 20px;
  margin-bottom: 10px;
  margin-right: 10px;
  cursor: pointer;
  display: inline-block;
}

.text-confirmed {
  font-size: 17px;
}