.google-map {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  border-radius: 20px;
  overflow: hidden;

  .map-controls {
    position: absolute;
    width: 300px;
    bottom: 50px;
    padding: 5px;
    border-radius: 10px;
    z-index: 1;
    background-color: white;

    input {
      border: 1px solid $secondary;
    }

    .btn {
      height: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.view-map-button {
  background: $neutral-gray-two;
  min-height: 40px;
  min-width: 40px;
  margin-top: 25px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 767px) {
    margin-top: -5px;
    margin-bottom: 10px;
  }
}

.view-map-button-active {
  background-color: $primary;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
