.bg-client {
  position: absolute;
  height: calc(100vh);
  width: 100%;
  margin: 0;
  padding: 0 !important;
  object-fit: cover;
}

.recover-password-box {
  padding: 50px 40px !important;
}

.login_box {
  border-radius: 40px;
  background: $neutral-white;
  padding: 10px 40px;
  margin-bottom: 20px;
  margin-top: 20px;

  .container-icon {
    position: relative;
    margin-bottom: -25px;

    @mixin icomoon {
      .icomoon {
        position: absolute;
        right: 13px;
        top: -35px;
        width: 28px !important;
        height: 28px !important;
        color: $black;
      }
    }
    &.client {
      @include icomoon();

      &:hover,
      :hover {
        color: $orange-two;
        cursor: pointer;
      }
    }
    &.driver {
      @include icomoon();

      &:hover,
      :hover {
        color: $orange-one;
        cursor: pointer;
      }
    }
  }

  .checkbox {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 16px;

    p {
      margin-left: 10px;

      span {
        font-weight: bold;
      }
    }
  }

  .logo {
    position: relative;
    top: -50px;
    height: 98px;
    width: 98px;
    background-color: $orange-three;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    margin-bottom: -25px;
  }

  h6 {
    color: $neutral-gray-one;
  }

  input {
    border-radius: 15px;
    height: 44px;
  }

  .login-btn {
    border-radius: 15px;
    margin-top: 30px;
  }

  .driver-btn {
    background: $orange-one;
    border: 1px solid $orange-one;
    color: $white;
  }

  .client-btn {
    background: $orange-two;
    border: 1px solid $orange-two;
    color: $white;
  }

  .register {
    text-decoration: none;
    margin: auto !important;
    color: $neutral-black;
    font-weight: 600;

    .register-btn {
      border-radius: 15px;
      height: 38px;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .register-btn-client {
    border: 1px solid $orange-two;
    &:hover,
    &:focus {
      background: $secondary-two;
      border: 1px solid $orange-two;
    }
  }

  .register-btn-driver {
    border: 1px solid $orange-one;
    &:hover,
    &:focus {
      background: $secondary-one;
      border: 1px solid $orange-one;
    }
  }

  .reset-password-btn {
    border-radius: 15px;
    height: 38px;

    a {
      text-decoration: none;
      margin: auto !important;
      color: $neutral-gray-one;
      font-weight: bold;
    }

    &:hover,
    &:focus {
      &.driver {
        color: $orange-one;

        a {
          color: $orange-one;
        }
      }
      &.client {
        color: $orange-two;

        a {
          color: $orange-two;
        }
      }
    }
  }

  .form-group {
    margin-bottom: 10px;
  }
}

.btn-back {
  position: relative;
  z-index: 1;
  display: flex;
  background-color: none;
  max-width: 250px;
  background-color: transparent !important;
  border: none;
  margin-top: 30px;
  padding: 0 0 0 10px;
  @include desktop {
    padding: 0 0 0 40px;
  }
  @include btn-transparent($gray-900);
}

@media (max-width: 375px) {
  .login_box {
    margin-top: 30px;
    padding: 10px 20px;
  }
  .btn-back {
    margin-top: 10px;
    margin-left: -10px;
  }
}
