.driver-solicitud-offer {
  .offers-quotation-box {
    background: $secondary-two;
    padding: 10px 20px;
    border-radius: 20px;
    margin-top: 40px;
  }

  .items-container {
    max-height: 350px;
    overflow-y: scroll;
    @include firefox-scrollbar-styles($secondary, white);
  }

  .images-scrollable {
    overflow-x: scroll;
    width: 80%;
    @include firefox-scrollbar-styles($secondary, white);
  }

  .items-description {
    .item-image {
      max-width: 25%;
    }

    .img-container {
      background-size: cover;
      background-image: url(app/assets/images/image-no-available.svg);
      width: 120px !important;
      min-width: 120px !important;
      height: 120px !important;
      border-radius: 5px;
      overflow: hidden;
      margin: 7px 5px;
    }

    .card-img {
      min-width: 130%;
      min-height: 100%;
    }
  }

  .items-route {
    display: flex;
    align-items: center;
    justify-content: start;

    .icons-box {
      height: 120px;
      color: $orange-two;
    }

    .dotted-line {
      position: absolute;
      height: 60px;
      border-left: 3px dotted $orange-two !important;
    }
  }

  .license-description {
    min-height: 250px;
    display: flex;
    align-items: center;
    justify-content: start;
  }

  .driver-description {
    .score-box {
      height: 28px;
      background: $orange-three;
      padding: 5px;
      margin-left: 10px;
      border-radius: 14px;
      font-weight: bold;
    }
  }

  @include scrollbar-styles(white);
}
