// Imports
@import './settings/index';
@import './components/index';
@import './screens/index';

html {
  height: 100vh;
  scroll-behavior: smooth;
  scroll-padding-top: 130px;
}

body {
  margin-bottom: 60px !important;
  font-size: var(--project-font-size);
  font-family: var(--project-font-family);
}

.content-body {
  @include desktop {
    margin-left: 250px;
    margin-right: 20px;
  }
}

a {
  color: black !important;
}

.link-primary {
  color: $primary !important;
}

.full-height {
  height: calc(100vh - 150px);
}

.contact-height {
  min-height: 88.7vh;
}

.padding-layout {
  padding: 5.5rem 1.8rem 5.5rem 0;

  @include media-query-max(991px) {
    padding: 5.5rem 1.5rem;
  }
}

.static-page {
  justify-content: center;
  min-height: 79.5vh;
  margin-top: 70px;
  padding-bottom: 20px;
}

.custom-data-table-placeholder {
  display: flex;
  justify-content: center;
  align-items: center;
  background: $hover-two;
  height: 95px;
  width: 100%;
  margin-left: 10px !important;
  border-radius: 15px;
}

.bg-image {
  min-height: 100vh;
  min-width: 1024px;
  width: 100%;
  height: auto;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -100;

  &.color-driver {
    background-color: $orange-one;
  }

  &.color-client {
    background-color: $orange-two;
  }
}

.project-font {
  font-weight: bold;
}

.pointer {
  cursor: pointer;
}

.shadow-black {
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.1);
}

.border-rounded {
  border-radius: 15px;
}

@media (max-width: $md) {
  .hide-on-medium-screen {
    display: none;
  }
}

@media (max-width: $md) {
  .hide-on-small {
    display: none;
    color: rgba(255, 255, 255, 0.7);
  }
}

@media (min-width: $md) {
  .hide-on-lg-screen {
    display: none;
  }
}

.subtitle {
  font-size: 1.1rem;
  font-weight: 700;
}

.info-extra {
  font-size: 1.1rem;
  font-weight: 300;
}

.commission-message--container {
  background: $state-variation-yellow;
  border-radius: 15px;
}

.cursor-pointer {
  cursor: pointer;
}

.color-primary {
  color: $primary;
}

.images-scrollable {
  @include scrollbar(rgba(179, 177, 177, 0.45), rgb(255, 255, 255));
}

.avatar_profile_img {
  height: 40px;
  width: 40px;
  min-width: 40px;
  min-height: 40px;
  border-radius: 20px;
  overflow: hidden;
  border: 1px solid $neutral-gray-two;
  margin-right: 10px;
  margin-left: 5px;

  img {
    min-width: 120%;
    min-height: 100%;
  }
}

#basic-nav-dropdown {
  display: flex;
  align-items: center;
}

.nokerning-text {
  letter-spacing: -0.5px !important;
  font-weight: 500;
  font-size: larger;
}

.icon-dashboard {
  padding: 12px !important;
}

.font-18 {
  font-size: 18px !important;
}

.font-30 {
  font-size: 30px !important;
}

p {
  letter-spacing: 0.03px !important;
}

.hover-wsp {
  &:hover {
    background: $success !important;
  }
}

.text-btn {
  border-radius: 15px;
  cursor: pointer;
  padding: 5px 10px;
  width: 400px;
  color: $primary;

  @media (max-width: $md) {
    width: 100% !important;
  }

  &:hover,
  &:focus {
    color: $primary !important;
    background: $hover-one;

    a {
      color: $primary !important;
    }
  }
}

.make-offer-btn {
  width: 400px;

  @media (max-width: $md) {
    width: 100%;
  }
}

.state-box {
  background: $secondary-one;
  width: 100%;
  padding: 10px 20px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
}

.car-logo {
  height: 80px !important;
  width: 80px;
  border-radius: 40px;
  background: $secondary-one;
}

.img-avatar-modal {
  width: 45px !important;
  height: 45px !important;
  overflow: hidden;
  margin-bottom: 15px;
  margin-left: 15px;
  margin-right: 6px;
  border-radius: 50%;
}

.terms-check-box {
  :first-child {
    justify-content: flex-start !important;
    align-items: flex-start !important;
  }

  a {
    margin-left: 25px;
  }
}

.pac-container {
  z-index: 1000000;
}

.without-style-link {
  color: black;
  text-decoration: none;

  &:hover {
    color: black;
    text-decoration: none;
  }
}

.new-trip-form {
  .input-group-text {
    display: flex;
    align-items: center;
    padding: 0 !important;
    margin-bottom: 0;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    text-align: center;
    white-space: nowrap;
    background-color: none !important;
    border: none !important;
    border-radius: 0.25rem;
    width: 20px !important;
    height: 20px !important;
  }
}

.gray-text {
  color: $gray-1;
}

.icon-heart {
  height: 30px;
  width: 30px;
  padding: 5px;
  border-radius: 15px;
  background: $orange-three;
}

.text-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: auto;
  max-width: 80%;
}

.tooltip-index {
  .tooltip-inner {
    text-align: left !important;
    padding-left: 12px;
  }
}

.driver-car-box {
  border-radius: 16px;
  height: 32px !important;
  width: 32px !important;
  background: $orange-one;
  min-width: 32px !important;
}

.driver-car-box-question-and-answers {
  border-radius: 20px;
  height: 40px !important;
  width: 40px !important;
  background: $orange-one;
  min-width: 40px !important;
}

.location-car-box {
  height: 40px;
  width: 40px;
  border-radius: 20px;
  background: $orange-two;
  cursor: pointer;
}

.location-car-box:hover {
  background: $primary;
}

.tab-filter-container {
  overflow: auto;
  white-space: nowrap;
  @include scrollbar($gray-3, rgb(255, 255, 255));
  padding: 4px;
  margin-right: -35px;
}

.tab-filter-button {
  height: 40px;
  min-width: 120px;
  border-radius: 20px;
  margin-bottom: 10px;
  margin-right: 10px;
  cursor: pointer;
  display: inline-block;
}

.text-confirmed {
  font-size: 17px;
}
