@keyframes opacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.opacity-animation {
  animation: opacity 0.2s ease-in-out;
}

@keyframes top {
  0% {
    opacity: 0;
    transform: translateY(-500px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.top-animation {
  animation: top 0.4s ease;
}

@keyframes bottom {
  0% {
    opacity: 0;
    transform: translateY(500px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.bottom-animation {
  animation: bottom 0.4s ease;
}

@keyframes left {
  0% {
    opacity: 0;
    transform: translateX(-500px);
  }
  40% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.left-animation {
  animation: left 0.4s ease;
}

@keyframes right {
  0% {
    opacity: 0;
    transform: translateX(500px);
  }
  40% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.right-animation {
  animation: right 0.4s ease;
}
