.footer {
  position: absolute !important;
  //bottom: 0;
  width: 100%;
  height: auto;
  z-index: 1018;
  background: linear-gradient(
    175deg,
    rgba(255, 94, 99, 1) 37%,
    rgba(255, 153, 102, 1) 100%
  ) !important;

  a {
    color: black !important;
  }

  @include desktop {
    z-index: 1030;
  }
}
.icon-footer {
  :hover {
    -webkit-transform: translateY(-2px);
    transform: translateY(-2px);
    transition: all 0.4s ease-out 0.2s;
  }
}

.footer-content {
  display: block;
}

.footer-content-info {
  display: flex;
  flex-basis: auto;

  @include mobile {
    display: block;
  }
}

.footer-content-info-description {
  display: flex;
  align-items: center;
}

.rrss-content {
  align-items: center;

  @include mobile {
    display: block;
    padding-left: 5px !important;
  }
}

.rrss-content-text {
  @include mobile {
    display: contents;
  }
}

.sign {
  text-align: end;
  @include mobile {
    text-align: left;
  }
}
