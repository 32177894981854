.dt-driver-offers {
  .driver-item-cell {
    margin-bottom: 0 !important;
  }
  .rdt_TableCell:nth-child(2) {
    min-width: 400px;
  }
  .rdt_TableCell:nth-child(5) {
    // IsToday Cell
    position: absolute;
    right: 20px;
  }
  .view-offer {
    display: none;
    .btn {
      color: black;
      width: 150px;
    }
  }
  .rdt_TableRow:hover {
    .view-offer {
      background: linear-gradient(-90deg, white, white, transparent);
      position: absolute;
      top: 0;
      right: 0;
      width: 550px !important;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-right: 15px;
      height: 100%;
      @media (max-width: $md) {
        display: none;
      }
    }
  }

  @media (max-width: $md) {
    .rdt_TableCell:nth-child(2) {
      min-width: 300px;
    }
    .rdt_TableCell:nth-child(2) {
      margin: 10px 15px;
    }
    .driver-item-cell {
      margin-bottom: 20px !important;
    }
    .rdt_TableCell:nth-child(5) {
      // IsToday Cell
      height: 35px;
      width: 70px;
      right: 20px;
      top: 20px;
    }
  }
}
