.container-input-img {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;

  &.multiple {
    align-items: flex-start;
  }

  .box-required {
    border: 1px solid #dc3545 !important;
  }

  &.multiple {
    flex-direction: column-reverse;

    .name-container {
      margin-top: 25px;
    }
  }

  .custom-single-file-img {
    position: relative;
    img {
      object-fit: cover;
      width: 200px;
      height: 200px;
      border-radius: 10px;
    }
  }

  .name-container {
    .name-img {
      &:hover {
        cursor: pointer;
        font-weight: 500;
      }
      &.btn-img {
        background-color: transparent;
        border: none;
      }
    }
    .icomoon {
      &:hover {
        cursor: pointer;
        color: #ff5e62;
      }
    }
  }

  .custom-file-input {
    display: none;
    visibility: none;
  }

  .img-box {
    display: flex;
    gap: 20px;

    &.multiple {
      flex-wrap: wrap;
      justify-content: left;
    }

    .box {
      border-radius: 10px;
      width: 150px;
      height: 120px;
      border: 2px dashed #ced4da;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      &:hover,
      &.dragging {
        border: 2px dashed #ff5e62 !important;
        .icomoon {
          color: #ff5e62;
        }
      }

      &.box-single {
        width: 200px;
        height: 200px;
      }
      .icomoon {
        color: #ced4da;
        &:hover {
          color: #ff5e62;
        }
      }
    }
  }

  .container-multiple-img {
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
    gap: 20px;

    .box-multiple-img {
      display: flex;
      justify-content: center;
      flex-direction: column;

      img {
        width: 150px;
        height: 120px;
        border-radius: 10px;
        object-fit: cover;
      }

      .multiple-name-container {
        width: 150px;
        display: flex;
        align-items: center;
        justify-content: center;

        .icomoon {
          color: #aeb2b7;
          &:hover {
            color: #ff5e62;
            cursor: pointer;
          }
        }
      }
    }
  }
}

.content-upload-file {
  .h-140px {
    height: 140px !important;

    @media (max-width: 767px) {
      height: 100px !important;
    }
  }
  .input-group {
    @media (max-width: 768px) {
      max-width: 320px !important;
    }
  }
  .custom-file-input {
    cursor: pointer;
    width: 1px;
  }
  .custom-file-label::after {
    content: 'Buscar' !important;
    background-color: #ff5e62;
    color: white;
  }
  .upload-file {
    border-radius: 15px !important;

    &.required {
      border: 1px solid #dc3545 !important;
    }
    cursor: pointer;
  }
}
