.expired-solicitude-header__container {
  background-color: $state-variation-red;
  display: flex;
  flex-wrap: nowrap;
  box-sizing: border-box;
  padding: 10px;
  border-radius: 15px;
  align-items: center;

  .expired-solicitude-actions__buttons {
    margin-left: auto;
    display: flex;
  }
}

.client-solicitude-show {
  &.expired {
    -webkit-filter: grayscale(1);
    filter: grayscale(1);
    border-radius: 15px;
    background-color: $gray-300;
    padding: 12px;
  }

  .total-value-box {
    margin-top: 20px;

    &.paid {
      background: $state-variation-green;
    }
  }

  .items-container {
    max-height: 350px;
    overflow-y: auto;
    @include firefox-scrollbar-styles($secondary, white);
  }

  .images-scrollable {
    overflow-x: auto;
    width: 80%;
    @include firefox-scrollbar-styles($secondary, white);
  }

  .items-description {
    .item-image {
      max-width: 25%;
    }

    .img-container {
      width: 120px !important;
      min-width: 120px !important;
      height: 120px !important;
      border-radius: 5px;
      overflow: hidden;
      margin: 7px 5px;
    }

    .card-img {
      min-width: 130%;
      min-height: 100%;
    }
  }

  .items-route {
    display: flex;
    align-items: center;
    justify-content: start;

    .icons-box {
      height: 120px;
      color: $orange-two;
    }

    .dotted-line {
      position: absolute;
      height: 60px;
      border-left: 3px dotted $orange-two !important;
    }
  }

  .license-description {
    min-height: 250px;
    display: flex;
    align-items: center;
    justify-content: start;
  }

  .driver-description {
    .score-box {
      height: 28px;
      background: $orange-three;
      padding: 5px;
      margin-left: 10px;
      border-radius: 14px;
      font-weight: bold;
    }
  }

  @include scrollbar-styles(white);
}

.total-value-box {
  padding: 10px 20px;
  border-radius: 20px;
}
