// STYLES COMMON TO ALL DATA TABLES
.data-table {
  .text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 170px;
  }

  .is-today {
    position: absolute !important;
    padding: 5px 5px;
    background: $state-yellow;
    border-radius: 20px;
    font-weight: bold;
    width: 70px;
  }

  .rdt_Table {
    padding: 10px 10px 20px 10px;
  }

  &.index-view {
    @include media-query-max(768px) {
      .rdt_Table {
        padding-top: 0 !important;
      }
    }
  }

  .rdt_TableRow {
    margin: 10px 0;
    min-height: 95px;
    min-width: 900px;
    overflow: hidden;
    border-radius: 15px;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
    justify-content: flex-start;
    border: 0.1px solid rgba(0, 0, 0, 0) !important;
    transition: all 0.2s linear;
    cursor: pointer;

    .img-container {
      width: 60px !important;
      height: 60px !important;
      background-image: url(app/assets/images/image-no-available.svg);
      background-size: contain;
      border-radius: 12px;
      overflow: hidden;
    }

    .card-img {
      min-width: 130%;
      min-height: 100%;
    }

    .heart-box {
      border-radius: 30px;
      background: $orange-three;
    }
  }

  .rdt_TableCell {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0;
    padding: 0;
    min-width: 75px;

    @include media-query-max(768px) {
      width: 100%;
    }
  }

  .rdt_TableHeadRow {
    display: none;
  }

  .default-styles-dt {
    .eAmuWF:not(:last-of-type) {
      border-bottom-width: 0;
    }
  }

  .score-box {
    height: 28px;
    background: $state-variation-green;
    padding: 5px 5px 7px 1px;
    min-width: 55px;
    border-radius: 14px;
    font-weight: bold;
  }

  .info-card {
    min-height: 100px;
    border: 1px solid $secondary-two;
    border-radius: 20px;
    transition: all 0.5s;

    &.driver {
      border: 1px solid $state-variation-pink;
    }

    .icon-background-circle {
      padding: 5px;
      border-radius: 50%;
      background: $secondary-two;
      margin: auto 20px;

      &.driver {
        background: $state-variation-pink;
      }
    }
  }

  @media (max-width: $md) {
    .text-truncate {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 300px;
    }
    .icon-box {
      width: 100% !important;
      height: 50px !important;
    }
    .client-item-cell {
      flex-direction: row;
    }
    .rdt_TableRow {
      display: flex;
      min-width: 300px;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0;

      .driver-route-cell {
        border-bottom: 1.5px solid $gray-300;
        width: 100%;
        margin: 0 !important;
        padding: 10px 15px;
      }

      .heart-box {
        margin: 0 !important;
      }

      .name-driver-item-cell {
        flex-direction: row;
      }

      .rdt_TableHeadRow {
        display: none;
      }

      .rdt_TableCell:first-child {
        margin: 0;
        justify-content: flex-start;
      }
    }
    .rdt_TableCell:first-child {
      width: 100%;
      justify-content: center;
    }
    .rdt_Table {
      overflow: hidden;
    }
  }
}

.bg-special-offer {
  background: $bg-special-offer !important;
}

// paginación
// filas x pagina
.jmVaaR {
  margin-right: 20px;
  color: black;
  font-weight: 500;
}
// contador de paginas
.iuhpgH {
  margin-left: auto !important;
  transform: translateX(107px);
  color: black !important;
  font-weight: 600 !important;
}
@media (max-width: 599px) {
  // contador de paginas
  .jygXwu {
    margin: 0px;
    color: black;
  }
}
@media (min-width: 599px) {
  // filas x pagina
  .jygXwu {
    // margin: -10px;
    color: black;
  }
  .rdt_Pagination {
    margin-top: 5px;
  }
  // box-botones
  .fzXxGw {
  }
  // botones
  #pagination-previous-page,
  #pagination-first-page {
    right: 80px;
    margin: 0 5px;
  }
  #pagination-next-page,
  #pagination-last-page {
    margin: 0 5px;
  }
}
