.solicitude-quotation-table {
  .driver-comment {
    display: block;
  }
  .select-offer {
    position: absolute;
    display: none;
  }
  .dotted-line {
    position: absolute;
    margin-top: 2px;
    height: 20px;
    border-left: 3px dotted $state-green !important;
  }
  .score-box {
    height: 28px;
    background: $orange-three !important;
    padding: 5px;
    margin-left: 10px;
    border-radius: 14px;
    font-weight: bold;
  }
  .rdt_TableRow {
    padding: 0 10px;
    min-height: 95px;
    min-width: 900px;
    height: 60px !important;
    flex-direction: row;
  }
  .rdt_TableRow:hover {
    border: 2px solid $state-green !important;
    background: linear-gradient(-90deg, $state-variation-green, white, white);
    cursor: pointer;
    .icon-box {
      background: $state-green !important;
    }
    .select-offer {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 1600px;
      height: 100%;
    }
    .driver-comment {
      display: none;
      transition: all 0.3s linear !important;
    }
  }
  .icon-primary {
    color: $state-green;
  }
  .rdt_TableCell:nth-child(1) .score-box {
    margin-left: auto;
  }
  .rdt_TableCell:nth-child(2) {
    justify-content: center;
  }
  .rdt_TableCell:nth-child(3) {
    display: flex;
    justify-content: flex-end;
  }
  .rdt_TableCell:nth-child(4) {
    display: flex;
    justify-content: center;
  }
  .rdt_TableCell:nth-child(5) {
    display: flex;
    justify-content: flex-end;
  }
}

@media (max-width: $md) {
  .quotation-table {
    .rdt_TableRow {
      display: flex;
      flex-direction: row;
    }
    .rdt_TableCell:nth-child(4) {
      position: absolute;
      left: 90%;
      top: 20px;
    }
  }
}
