.edit-driver-profile {
  .bg-driver-bar {
    background-color: $secondary-one;
    margin-top: 3px;
    transition: all 0.2 ease-in-out;
    .bar {
      height: 5px;
      border-radius: 13px;
      transition: all 0.2 ease-in-out;
      width: 0;
      background-color: $primary;
    }
  }

  .bg-description-driver {
    ul {
      list-style: none;
      padding-left: 0.5rem;

      @include media-query-max(415px) {
        padding-left: 0.1rem;
      }

      li {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-bottom: 3px;

        p {
          font-weight: 700;
          font-size: 1rem;

          &.min-width {
            min-width: 158px;
          }
        }

        span {
          display: block;
          margin-left: 5px;
        }
      }

      li::before {
        content: '';
        background-color: $primary;
        border-radius: 50%;
        display: inline-block;
        margin-right: 10px;
        height: 10px;
        min-width: 10px;
      }
    }

    .box-img-car {
      display: flex;
      gap: 10px;
      padding: 0px 10px 5px 0;
      overflow-x: auto;
      @include scrollbar($gray-300, transparent);
      @include firefox-scrollbar-styles($secondary, white);
    }

    .img-car {
      min-width: 165px;
      max-width: 165px;
      height: 115px;
      border-radius: 10px;
      overflow: hidden;
    }

    .content-flex {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .content-license {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 115px;

      &.empty {
        height: 60px !important;
      }

      @include media-query-max(1199px) {
        height: 150px;

        &.empty {
          height: 60px !important;
        }
      }
    }

    .box-img-license {
      display: flex;
      gap: 10px;
      padding: 0px 10px 5px 0;
      overflow-x: auto;
      @include scrollbar($gray-300, transparent);

      .img-license {
        min-width: 165px;
        max-width: 165px;
        height: 115px;
        object-fit: cover;
        border-radius: 10px;
      }
    }
  }

  .container-fields-vehicle-items {
    h6 {
      font-size: 1.1rem;
      letter-spacing: 0;
      color: $gray-600;
    }
    hr {
      width: 108%;
    }
  }

  .load-btn {
    background-color: $secondary;
    width: 40px;
    height: 40px;
  }

  .loaded {
    background-color: $state-variation-green;
    width: 40px;
    height: 40px;
    cursor: pointer;
  }

  .img-license-input-steps-register {
    width: 120px !important;
    height: 90px !important;
  }

  .empty-img-input-steps-register {
    width: 120px !important;
    height: 90px !important;
  }
  .empty-img-avatar-input-steps-register {
    height: 90px !important;
    width: 90px !important;
    border-radius: 45px !important;
  }

  .img-avatar-input-steps-register {
    height: 90px !important;
    width: 90px !important;
    border-radius: 45px !important;
    border: 1px solid $neutral-gray-two;
  }

  .certificate-text {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 230px;
    @include media-query-max(380px) {
      max-width: 150px;
    }
    @include custom-media-query(1200px, 1280px) {
      margin-left: -30px;
    }
  }
}

.img-license-input {
  height: 130px !important;
}

.empty-img-input {
  height: 130px !important;
}

.circle-image {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $primary;
  border-radius: 50%;
  width: 25px !important;
  height: 25px !important;
}
