.notification-button {
  cursor: pointer;

  &.badge-position {
    right: 20px;
  }

  &--badge-style {
    color: $white;
    position: absolute;
    font-size: 0.9rem;
    font-weight: 900;
    width: 1.2rem;
    height: 1.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    top: 10px;
    transform: translateX(25px);
    pointer-events: none;
    cursor: pointer;
  }

  .badge-primary {
    background-color: $primary;
  }
  .badge-secondary {
    background-color: $warning;
    color: $black;
  }
}

.popup-menu {
  overflow-y: auto;
  color: $black;
  backdrop-filter: blur(13px);
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.216);
  position: fixed;
  top: 3.8rem;
  right: 20px;
  width: 22rem;
  border-radius: 15px;
  padding: 0.5rem 0 0 0;
  overflow: hidden;
  z-index: 99999;
  transition: all 0.3s;
  opacity: 0;
  visibility: hidden;

  &.show {
    opacity: 1;
    visibility: visible;
  }

  .empty-msg {
    margin-top: 0.8rem;
  }

  @include media-query-max(390px) {
    width: 16rem;
    right: 15px;
  }
}

.container-notification-list {
  max-height: 330px;
  min-height: 65px;
  overflow-y: auto;
  @include scrollbar(rgba(179, 177, 177, 0.45), rgb(255, 255, 255));

  .notification-list {
    &--item {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      border-bottom: 1px solid rgba(204, 204, 204, 0.108);
      width: 100%;
      padding: 0 0.8rem;
      transition: all 0.3s;

      &:first-child {
        margin-top: 0.4rem;
      }

      &:last-child {
        border-bottom: none;
        padding-bottom: 15px !important;
      }

      &.unread {
        background-color: rgba(204, 204, 204, 0.371);
        color: $black;
      }

      &.hover-driver {
        &:hover {
          background-color: $primary;
          color: $white;
        }
      }
      &.hover-client {
        &:hover {
          background-color: $secondary;
          color: $black;
        }
      }
      &.cursor-pointer {
        cursor: pointer;
      }
      &.default-cursor {
        cursor: default;
      }
    }
  }
}

.spinner-notification {
  margin-bottom: 21px;

  &.spinner-primary {
    border-color: $primary;
    border-right-color: transparent;
  }
  &.spinner-secondary {
    border-color: $secondary;
    border-right-color: transparent;
  }
}

.opacity-background {
  position: fixed;
  top: 66px;
  background: rgba(255, 255, 255, 0.5);
  left: 0;
  height: 100vh;
  width: 100vw;
}
