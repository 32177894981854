.Toastify__toast-container {
  width: auto !important;
  min-width: 200px;
  @media (max-width: 991px) {
    margin-right: -20px;
  }
  .Toastify__toast-body {
    font-size: 15px;
  }
  .Toastify__toast--success {
    background-color: #07bc0db5 !important;
    backdrop-filter: blur(10px);
    border-radius: 10px !important;
  }
  .Toastify__toast--error {
    background-color: #e73c3cba !important;
    backdrop-filter: blur(10px);
    border-radius: 10px !important;
  }
}
