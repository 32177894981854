.image-whit-zoom-div {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 110%;
  min-height: 100%;
  background: black;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
.image-whit-zoom-div:hover {
  min-width: 115%;
  min-height: 110%;
}
.image-whit-zoom {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100% !important;
  max-width: 110% !important;
  min-height: 100% !important;
  max-width: 110% !important;
  background: black;
  cursor: pointer;
  object-fit: cover;
}

.image-whit-zoom-search {
  display: hidden;
  position: absolute;
  color: $secondary;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 40%;
  font-size: 10px !important;
  text-align: center;
  justify-content: center;
  color: transparent;
  transition: all 0.2s ease-in-out;
}

.image-whit-zoom-search:hover {
  color: $secondary;
}

.image-modal {
  background: rgba(0, 0, 0, 0.9);
  .modal-content {
    background: transparent;
  }
  .close {
    span {
      color: red !important;
    }
  }
  .modal-content .modal-header .close span {
    color: white !important;
  }
  .close {
    font-size: 40px;
  }
}

.image-with-zoom-image-size {
  max-width: 70%;
  @media (max-width: $md) {
    max-width: 100%;
  }
}
