input {
  font-size: 16px;
  border-radius: 15px !important;

  &:focus {
    border: 1px solid $gray-900 !important;
    box-shadow: none !important;
  }
}

.form-label {
  font-weight: 600 !important;
  font-size: 14px !important;
}

input:invalid,
.is-invalid {
  animation: shake 300ms;

  &:focus {
    border-color: #dc354658 !important;
    box-shadow: 0px 0px 3px 2px #dc354658 !important;
  }

  // for remote select
  .css-12xdsrl-control,
  .css-52rfuw-control {
    border-color: #dc3545;

    &:hover {
      border-color: #dc3545 !important;
    }
  }
  .css-8fm3v1-control {
    box-shadow: 0px 0px 3px 2px #dc354658 !important;
    border-color: #dc354658 !important;
  }
}

// for remote select
.css-8fm3v1-control {
  box-shadow: none !important;
  border: 1px solid $gray-900 !important;
}
.css-1g6gooi,
.css-b8ldur-Input {
  input {
    box-shadow: none !important;
    border-radius: 0 !important;
    border: none !important;
  }
}

@keyframes shake {
  25% {
    transform: translateX(4px);
  }
  50% {
    transform: translateX(-4px);
  }
  75% {
    transform: translateX(4px);
  }
}

textarea {
  border-radius: 15px !important;
  height: 100px !important;

  &:focus {
    border: 1px solid $gray-900 !important;
    box-shadow: none !important;
  }
}

.form-checkbox {
  a,
  label {
    color: $gray-900;
    font-size: 16px !important;
  }
}
