.containerSpinnerLoad {
  position: fixed;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100% - 330px);
  height: 60vh;

  .spinner-border {
    width: 2.5rem;
    height: 2.5rem;
    border-width: 0.35rem;
  }

  @include media-query-max(991px) {
    width: calc(100% - 3.5rem);

    .spinner-border {
      width: 2rem;
      height: 2rem;
      border-width: 0.25rem;
    }
  }
}

.spinner-datatable {
  height: 70vh !important;

  &.no-header {
    margin-top: -5rem;
  }
}

.spinner-offers {
  margin-top: -5rem;
}

.bg-opacity {
  background-color: rgb(255, 255, 255);
  opacity: 0.5;
}
