.sidebar {
  background-color: $neutral-white;
  padding-top: 60px;
  height: 100vh;
  width: 230px;
  position: fixed;
  transform: translateX(0px);
  transition: 0.4s transform ease-in-out;
  z-index: 1020;
  &.hidden-sidebar {
    transform: translateX(-410px);
    transition: 0.4s transform ease-in-out;
    @include desktop {
      transform: translateX(0px);
      padding-left: 8px;
    }
  }
  .container-links {
    margin: 20px 0 0 5px;
    font-weight: 600;

    .nav-link {
      color: $neutral-black;
      display: flex;
      font-size: 1.1rem;
      margin-bottom: 10px;

      &.driver {
        &.active,
        &:hover {
          color: $orange-one !important;
        }
      }
      &.client {
        &.active,
        &:hover {
          color: $orange-two !important;
        }
      }

      .icomoon {
        width: 20px !important;
        height: 20px !important;
        margin-right: -5px;
      }
    }
  }
}
.hamburger-menu {
  border-color: $orange-one;
  background-color: transparent;
  @include desktop {
    display: none !important;
  }
  .icomoon {
    color: $white;
  }
  &:hover,
  :hover {
    border-color: $orange-one !important;
    background-color: $orange-one;
    color: white;
    transition: 0.1s all;
  }
  &:focus {
    box-shadow: 0px 0px 10px -2px $white;
  }
  :active {
    background-color: $orange-one;
    color: $white !important;
  }
  &:active {
    box-shadow: 0px 0px 10px 2px $white !important;
    background-color: $orange-one !important;
    border-color: transparent !important;
  }
}
.overlay {
  position: fixed;
  top: 56px;
  left: 0;
  z-index: 1019;
  background: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 100%;
  opacity: 1;
  transition: all 0.4s;
  @include desktop {
    display: none;
  }
}
.overlay-hidden {
  opacity: 0;
}

.sidebar-mobile {
  font-weight: 600;
  .nav-link {
    color: $neutral-black;
    display: flex;
    font-size: 1.1rem;
    margin-left: 5px;
    margin-bottom: 5px;

    &.driver {
      &.active,
      &:hover {
        color: $orange-one !important;
      }
    }
    &.client {
      &.active,
      &:hover {
        color: $orange-two !important;
      }
    }
    .icomoon {
      width: 20px !important;
      height: 20px !important;
      margin-right: -5px;
    }
  }
}
