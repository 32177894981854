.driver-solicitude-trip {
  .driver-route-cell {
    width: 100%;
    margin: 0;
    padding: 0 10px 0 0;
    display: flex;
    flex-direction: column;
    flex-flow: column-reverse !important;
  }

  .driver-route-box {
    padding: 0 10px;
    border-radius: 24px;
    background: $secondary-two;
  }

  .driver-item-cell {
    margin-bottom: 20px;

    .img-container {
      width: 60px !important;
      height: 60px !important;
      background: $secondary;
      border-radius: 12px;
      overflow: hidden;
    }

    .card-img {
      min-width: 100%;
      min-height: 100%;
    }

    .name-driver-item-cell {
      flex-direction: row;
    }

    .heart-box {
      border-radius: 30px;
      background: $orange-three;
    }
  }

  .route-map {
    // background: red;
    width: 100% !important;
    height: 360px;
  }

  .route-card {
    border-radius: 20px;
    margin: 0 10px;
    display: flex !important;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    border: 2px solid transparent;
    transition: all 0.3s linear;
    cursor: pointer;

    .status-card {
      width: 100% !important;
    }

    @media (max-width: $md) {
      .status-box {
        min-width: 100%;
      }
      .driver-item-cell {
        padding-bottom: 10px;
      }
    }
  }
}
