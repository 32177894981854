.carousel {
  position: relative;
}
.carousel .carousel-inner {
  position: absolute;
  width: 100%;
  height: 100%;
}
.carousel .carousel-inner .carousel-item {
  width: 100%;
  height: 100%;
}
.carousel .carousel-inner .carousel-item img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.carousel:after {
  content: "";
  padding-bottom: 40%;
  display: block;
}