.landing-page {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  width: 100vw;
  height: 100vh;
  background-color: white;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  a {
    text-decoration: none;
  }

  .landing-page-btn {
    text-decoration: none;
    color: $neutral-black !important;
    border: 1px solid transparent;
  }

  .top-logo {
    position: absolute;
    top: 0;
    left: 50%;
    padding: 100px;
    transform: translate(-50%, -50%);
    z-index: 12000;
    background: $dark-blue;
    width: 324px;
    height: 324px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 210px;
  }

  .row {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .col {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .background-image {
    width: 50%;
    height: 100%;
    margin: 0;
    padding: 0;
    background-size: cover;
    background-color: #c4c4c4;
  }

  .left-content {
    background-position: 50%;
    background-blend-mode: soft-light;
    transition: all 0.5s linear;

    &:hover,
    &:focus {
      background-color: $orange-one;
      font-size: 1.2rem;
      color: $neutral-white;
      background-blend-mode: soft-light;
      transition: all 0.5s linear;

      .landing-page-btn {
        text-decoration: none;
        color: $neutral-white !important;
        border: 1px solid $neutral-white;
        padding: 5px 30px;
        border-radius: 30px;
        transition: all 0.3s linear;
      }
    }
  }

  .right-content {
    background-position: 80%;
    background-blend-mode: soft-light;
    transition: all 0.5s linear;

    &:hover,
    &:focus {
      background-color: $orange-two;
      font-size: 1.2rem;
      color: $neutral-white;
      background-blend-mode: soft-light;
      transition: all 0.5s linear;

      .landing-page-btn {
        text-decoration: none;
        color: $neutral-white !important;
        border: 1px solid $neutral-white;
        padding: 5px 30px;
        border-radius: 30px;
        transition: all 0.3s linear;
      }
    }
  }
}

@media (max-width: 600px) {
  .landing-page {
    flex-direction: column !important;

    .top-logo {
      top: 50%;
      padding: 100px;
      transform: translate(-50%, -50%);
      background: $dark-blue;
      width: 150px;
      height: 150px;
    }
  }

  .background-image {
    width: 100% !important;
    height: 50% !important;
  }
}

//MENU HOME//

#navbar-landing .navbar {
  @include mobile {
    padding: 0px 1rem !important;
  }
}

.bg-menu-landing {
  background: rgba(255, 255, 255, 0.9) !important;
  backdrop-filter: blur(2px) !important;
  @include media-query-max(991px) {
    padding: 0px 1rem 0px 1rem;
  }
}

.navbar .navbar-brand-landing {
  height: auto !important;
  width: 130px !important;
  margin-left: -20px !important;
  @include media-query-max(991px) {
    padding: 0px;
  }
}

.navbar-landing {
  height: 60px !important;
  width: auto !important;
  padding: 0px;
  margin-left: -20px !important;
}

#navbar-landing .nav-link {
  @include mobile {
    padding-left: 5px !important;
  }
}

.navbar-nav {
  @include mobile {
    margin-bottom: 20px;
  }
}

//HOME//
.home-landing {
  top: 0;
  left: 0;
  z-index: 10000;
  background-color: white;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.home-container {
  padding: 0px;
}

.home-header-container {
  background-color: $hover-two;
  margin: 170px 20px 25px;
  clip-path: polygon(0 0, 100% 0%, 100% 55%, 0% 100%);
  border-radius: 40px;
  padding: 120px 0px;

  @include mobile {
    margin: -210px 0px 11px;
    clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 109%);
    border-radius: 40px;
    padding: 190px 0px 45px;
    h1 {
      font-size: calc(var(--project-font-size) * 2);
      line-height: calc(var(--project-font-size) * 2 * 1.35);
    }
  }

  @include mid-screens {
    clip-path: polygon(0 0, 100% 0%, 100% 60%, 0% 106%);
    padding: 45px 0px 55px;
    margin: 120px 20px 25px;
    clip-path: polygon(0 0, 100% 0%, 100% 55%, 0% 100%);
    border-radius: 40px;
  }

  @include large-screens {
    margin: 170px 75px 25px;
  }

  @include xl-screens {
    clip-path: polygon(0 0, 100% 0%, 100% 60%, 0% 106%);
    margin: 170px 20% 25px;
  }
}

.home-header-container__getStarted {
  padding-left: 8%;
  margin-right: 55%;
  margin-top: -2%;
  h1 {
    color: $orange-two;
  }
  @include mobile {
    text-align: center;
    padding-right: 5%;
    margin-right: 0%;
  }

  @include mid-screens {
    padding-right: 50%;
    margin-right: 0%;
  }
}

.home-header-container__tittle {
  padding-left: 8%;
  margin-right: 55%;
  margin-top: -2%;
  h1 {
    color: $orange-two;
  }
  @include mobile {
    padding-right: 5%;
    margin-right: 0%;
  }

  @include mid-screens {
    padding-right: 50%;
    margin-right: 0%;
  }
}

.home-header-container__img {
  margin-top: -45%;
  margin-left: 45%;

  @include mobile {
    float: none;
    margin-top: 20%;
    margin-left: 0%;
    z-index: 2;
    position: relative;
  }
  @include mid-screens {
    float: right;
    margin-top: -45%;
    margin-left: 45%;
  }

  @include large-screens {
    margin-top: -42%;
    margin-left: 45%;
  }
  @include xl-screens {
    margin-top: -22%;
    margin-left: 50%;
    margin-right: 365px;
  }
}

.content-body {
  @include desktop {
    margin-left: 250px;
    margin-right: 20px;
  }
}

//HOME - HOW WORKS//

.card {
  border-radius: 40px !important;
}

.card-body {
  padding: 1.25rem 0px px;
}

.howWorks__title {
  text-align: center;
  margin-bottom: 30px;
  @include mobile {
    font-size: calc(var(--project-font-size) * 1.7);
    margin-top: 60px;
    margin-bottom: 20px;
  }
}

.howWorks__card {
  width: 95%;
  border: 0px;
  margin: auto;
  @include mobile {
    width: 100%;
    margin-bottom: 30px;
  }
}

#cardDrive {
  @include xl-screens {
    padding-left: 35%;
  }
}

#cardClient {
  @include xl-screens {
    padding-right: 35%;
  }
}

.howWorks__cardBody {
  padding: 1rem;
  border-radius: 40px;
  box-shadow: -29px -24px 211px -1px rgba(0, 0, 0, 0.02),
    35px 22px 85px rgba(0, 0, 0, 0.08);
}

.howWorks__cardTitle {
  margin-bottom: 0px;
  display: flex;
  img {
    width: 70px;
    height: 70px;
  }
  h6 {
    align-self: center;
  }
}

.howWorks__ListGroup__border {
  border: 0px;
  border-width: 0 0 0px !important;
}

.list-group-flush > .list-group-item {
  border: 0px;
  border-width: 0 0 0px !important;
}

//HOME - WHY BRIIING!//
.Faqs__row {
  margin-top: 10%;
  margin-bottom: 15%;
}

.Faqs__card {
  width: 95%;
  border: 0px;
  margin-bottom: 10%;
  margin: auto;
  padding: 1.5rem;
  border-radius: 40px;
  background: $hover-two;
  // box-shadow: -29px -24px 211px -1px rgba(0, 0, 0, 0.02),
  //   35px 22px 85px rgba(0, 0, 0, 0.08);

  @include mobile {
    width: 100%;
    padding: 0rem;
  }

  @include mid-screens {
    padding: 1rem;
  }

  @include xl-screens {
    width: 65%;
    padding: 1rem;
  }
}

.Faqs__cardTitle {
  margin-bottom: 0px;
}

.faqs__cards__item {
  border-radius: 15px !important;
  border: none;
  // box-shadow: -29px -24px 211px -1px rgba(0, 0, 0, 0.02),35px 22px 85px rgba(0, 0, 0, 0.08);
}

.card-header {
  background: $secondary-two;
  border-bottom: none;

  &:hover {
    background: $orange-two;
    cursor: pointer;
  }
}

//HOME - APP DRIVER//

.appDriver-Container {
  padding-left: -15px !important;
  padding-right: -15px !important;
}

.appDriver-Container__row {
  background-color: $secondary-one;
  padding: 8% 0%;
  margin-bottom: -10px;

  @include xl-screens {
    padding: 5% 0%;
  }
  @include mobile {
    margin-top: 30%;
  }
}

.appDriver-Container__content {
  margin-left: 8%;
  margin-right: 50%;
  align-items: baseline;

  @include mobile {
    margin-right: 2%;
    margin-left: 2%;
  }

  @include xl-screens {
    margin-left: 17%;
    margin-right: 45%;
  }
  @include mid-screens {
    margin-right: 45%;
    margin-left: 5%;
  }
}

.appDriver-Container__content__img {
  display: flex;
  margin-top: 5%;
  img {
    width: 100%;
  }
}

.appDriver-Container__img {
  float: right;
  margin-top: -35%;
  margin-left: 50%;

  img {
    width: 100%;
  }

  @include mobile {
    margin-right: 0%;
    float: none;
    margin-top: -20%;
    margin-left: 0%;

    img {
      width: 80%;
    }
  }

  @include mid-screens {
    float: right;
    margin-top: -45%;
    margin-left: 50%;

    img {
      width: 100%;
    }
  }

  @include xl-screens {
    margin-top: -25%;
    margin-right: 10%;
  }
}

.pink-color {
  color: $orange-one;
}

.orange-color {
  color: $orange-two;
}

.pt-lg-5 {
  @include xl-screens {
    padding-top: 3rem !important;
  }
}
