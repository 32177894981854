.custom-breadcrumb {
  .breadcrumb {
    background-color: transparent !important;
    padding: 0;
  }
  .breadcrumb-item {
    font-weight: 600;
    margin-bottom: 10px !important;
    a {
      color: $gray-600 !important;
    }
    &.active {
      color: $neutral-black !important;
    }
    &::before {
      color: $neutral-black !important;
    }
  }
}
