.form-client-solicitude {
  h6 {
    font-size: 1.05rem;
    letter-spacing: 0;
    color: $gray-1;
  }

  .mt-fields {
    margin-top: 2rem;
  }

  .pr-fix {
    padding-right: 1.9rem;
  }

  .container-fields-client-items {
    @include media-query-min(768px) {
      margin-bottom: -0.8rem;
    }
    margin-bottom: 1.5rem;

    hr {
      width: 108%;
    }
  }

  .br-client {
    @include media-query-min(768px) {
      border-right: 1px dashed $orange-two;
    }
  }

  .text-area-client {
    height: 100px;
  }

  .card-warning {
    background-color: $state-variation-yellow;
    border-radius: 20px;
    padding: 5px 5px 1px 15px;

    .title-card {
      display: flex;
      justify-content: space-between;
      align-items: center;

      h6 {
        color: $neutral-black;
      }
    }
    .body-card {
      ul {
        margin-left: -1rem;
        li {
          font-weight: 300;
          font-size: 14px;
        }
      }
    }
    .close-warning-card-icon {
      &:hover {
        cursor: pointer;
      }
    }
  }
}

.mt-20 {
  margin-top: 5.5rem !important;
}

.btn-urgency {
  &:hover {
    background-color: $orange-two;
  }
}
