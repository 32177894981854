.steps_register_background {
  position: absolute;
  width: 100%;
  min-height: 87vh;
  top: 13vh;
  left: 0;
  background: white;
  border-radius: 50px 50px 0 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  .success-register {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background: linear-gradient(
      175deg,
      rgba(255, 94, 99, 1) 37%,
      rgba(255, 153, 102, 1) 100%
    );

    .confetti {
      max-width: 300px;
    }
  }

  .steps_register_form {
    max-width: 600px;
  }

  @include scrollbar-styles(white);

  .verticalScrollable {
    overflow-y: scroll;
    height: 75vh;
  }

  .steps_register_status_num {
    background: $secondary;
    height: 40px;
    width: 40px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .active_num {
    background: $primary;
    cursor: pointer;
    &:hover {
      background: $primary;
    }
  }

  .steps_register_status_line {
    width: 40px;
    height: 1px;
    margin: 0 5px;
  }

  .solid_line {
    border-bottom: 3px solid $primary;
  }
  .dotted_line {
    border-bottom: 3px dotted $primary;
  }

  .steps_register {
    padding: 0 20px;
    margin-bottom: 20px;
    z-index: 100;

    h6 {
      color: $neutral-gray-one;
    }

    label {
      margin-bottom: 0.3rem;
    }

    input {
      border-radius: 15px;
      height: 44px;
    }

    .login-btn {
      border-radius: 15px;
      margin-top: 30px;
    }

    .driver-btn {
      background: $orange-one;
      border: 1px solid $orange-one;
      color: $white;
    }

    .reset-password-btn {
      border-radius: 15px;
      height: 38px;

      a {
        text-decoration: none;
        margin: auto !important;
        color: $neutral-gray-one;
        font-weight: bold;
      }

      &:hover,
      &:focus {
        &.driver {
          color: $orange-one;

          a {
            color: $orange-one;
          }
        }
        &.client {
          color: $orange-two;

          a {
            color: $orange-two;
          }
        }
      }
    }
  }
}
