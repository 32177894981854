.rating {
  .bg-rating-modal {
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.35);
    width: 100vw;
    height: 100vh;
    z-index: 100000;
  }
  .rating-detail {
    background: white;
    padding: 25px;
    border-radius: 15px;
    min-width: 350px;
  }
  .rating-detail-info {
    padding: 10px;
    margin: 15px 0 15px 0;
    border: 1px solid $secondary;
    border-radius: 15px;
  }
  .icon-rounded {
    height: 60px;
    width: 60px;
    border-radius: 30px;
  }
  .bg-orange {
    background: $secondary;
  }
  .bg-green {
    background: $state-variation-green;
    &:hover {
      background: $secondary;
    }
  }
  .bg-gray {
    background: $neutral-gray-two;
    &:hover {
      background: $secondary;
    }
  }

  .selectable-smile {
    padding: 5px 10px;
    border: 1px solid $secondary;
    border-radius: 10px;
  }
  .selectable-smile:hover {
    background: $secondary;
  }

  .rating-read-only {
    padding: 5px 10px;
    border-radius: 10px;
    height: 80px;
    width: 170px;
    transition: all 0.2s linear;

    p {
      font-size: 10px;
    }

    .show-on-hover {
      display: none !important;
      height: 80px;
      width: 170px;
      padding: 5px 10px;
      overflow: auto;
      transition: all 0.2s linear;
    }

    .rating-btn {
      display: none !important;
      height: 40px;
      width: 100%;
      transition: all 0.2s linear;
    }

    &:hover {
      transition: all 0.2s linear;

      .hide-on-hover {
        display: none !important;
        transition: all 0.2s linear;
      }
      .show-on-hover {
        display: block !important;
        transition: all 0.2s linear;
      }
      .rating-btn {
        display: block !important;
        transition: all 0.2s linear;
      }
    }
  }

  .rating-bg-hover:hover {
    background: $hover-two;
  }

  @include scrollbar-styles(white);
}
