.driver-solicitude-show {
  .driver-route-cell {
    width: 100%;
    margin: 0;
    padding: 0 10px 0 0;
    display: flex;
    flex-direction: column;
    flex-flow: column-reverse !important;
  }

  .driver-route-box {
    padding: 0 10px;
    border-radius: 24px;
    background: $secondary-two;
  }

  .images-scrollable {
    overflow-x: scroll;
    width: 100%;
    @include firefox-scrollbar-styles($secondary, white);
  }

  .empty_img-container {
    background-color: red;
    width: 120px !important;
    min-width: 120px !important;
    height: 120px !important;
    border-radius: 5px;
    overflow: hidden;
    margin: 7px 5px;
  }

  .items-description {
    max-width: 400px;
    min-width: 350px;
    // overflow-x: scroll;
    // min-height: 250px;
    border-radius: 15px;
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.2);
    border: 1px solid $hover-one;

    .item-image {
      max-width: 25%;
    }

    .img-container {
      width: 120px !important;
      min-width: 120px !important;
      height: 120px !important;
      border-radius: 5px;
      overflow: hidden;
      margin: 7px 5px;
    }

    .card-img {
      min-width: 130%;
      min-height: 100%;
    }
  }

  .status-box {
    border-radius: 20px;

    .status-card {
      .status-card--address_text {
        font-size: 16px;
        font-weight: 700;
      }
    }
  }

  .total-items-container {
    width: 100% !important;
    overflow-x: scroll;
  }

  @include scrollbar-styles(white);

  @media (max-width: $md) {
    .status-box {
      min-width: 100%;
    }
  }
}
