.carousel-home {
  margin: 0 -15px;
  width: auto;
}
.carousel-show {
  position: relative;
  img {
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  &:after {
    content: '';
    padding-bottom: 40%;
    display: block;
  }
}
