// DRIVER DASHBOARD
.driver-dashboard {
  .icon-box {
    width: 68px !important;
  }

  .dotted-line {
    position: absolute;
    margin-top: 2px;
    height: 20px;
    border-left: 3px dotted $orange-two !important;
  }

  .rdt_TableRow:hover {
    border: 2px solid $orange-two !important;
    background: $hover-two;
    transition: all 0.3s linear;
  }

  .icon-primary {
    color: $orange-two;
  }

  .driver-route-box {
    padding: 0 10px;
    border-radius: 24px;
    background: $secondary-two;
    min-width: 215px;
  }

  .driver-route-cell {
    display: flex !important;
    align-items: flex-start;
    padding-left: 15px;
  }

  @media (max-width: $md) {
    .rdt_TableCell:nth-child(5) {
      position: absolute;
      left: 120px !important;
      top: 10px;
    }
  }
}

// CLIENT DASHBOARD
.dt-client {
  .rdt_TableCell {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0;
    padding: 0;
    min-width: 68px;
  }

  @media (max-width: $md) {
    .rdt_TableCell:first-child {
      margin-bottom: -10px !important;
    }
    .rdt_TableCell:nth-child(2) {
      padding-top: 10px;
      padding-left: 0px !important;
      margin-bottom: -5px !important;
    }
    .rdt_TableCell:nth-child(3) {
      padding-left: 15px;
      padding-bottom: 0px;
    }
    .rdt_TableCell:nth-child(4) {
      padding-bottom: 10px;
    }
  }

  .car-box {
    width: 69px;
    background: $state-variation-green;

    p {
      color: black;
    }
  }

  .icon-box {
    width: 70px !important;

    &.bg-green {
      background: $state-variation-green !important;
    }

    &.bg-orange {
      background: $secondary-two !important;
    }

    &.bg-yellow {
      background: $state-variation-yellow !important;
    }

    &.bg-disabled {
      background: $gray-2 !important;
    }

    &.bg-expired {
      background: $state-variation-red !important;
    }
  }

  .quotations-box {
    border-radius: 15px;

    &.bg-disabled {
      background: $gray-2 !important;
    }

    &.bg-orange {
      background: $secondary-two !important;
    }

    &.bg-green {
      background: $state-variation-green !important;
    }

    &.bg-yellow {
      background: $state-variation-yellow !important;
    }

    &.bg-expired {
      background: $state-variation-red !important;
    }
  }
}

.days-used {
  font-size: 1.1rem;

  @include media-query-max(600px) {
    font-size: 1rem;
  }
}

.title-name {
  font-size: 2.1rem;

  @include media-query-max(600px) {
    font-size: 1.8rem;
  }
}

.content-info-boxs {
  display: flex;
  gap: 20px;
}

.dotted-line {
  position: absolute;
  margin-top: 2px;
  height: 20px;
  border-left: 3px dotted $orange-two !important;

  &.color-primary {
    border-color: $primary;
  }
}

.table-card-tittle {
  font-weight: 700 !important;
  font-size: 18px;
  margin-bottom: 2px !important;
}

.opportunity-tag {
  font-size: 0.55rem;
  text-align: center;
  letter-spacing: 0.1px;
  line-height: 1.2;

  @media (max-width: $md) {
    font-size: 1.3rem;
    margin-left: 10px;
  }
}

.hover-box {
  border-radius: 20px;
  &:hover {
    background-color: $state-variation-pink;
    transition: all 0.5s;
    cursor: pointer;
  }
}
